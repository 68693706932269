import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Button from "./button";

export default function PageNotFound() {
  return (
    <>
      <main className="w-full min-h-full place-items-cente px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <ExclamationTriangleIcon className="w-24 h-24 text-yellow-500 mx-auto" />
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-shark-50 sm:text-5xl">
            No Permission
          </h1>
          <p className="mt-6 text-base leading-7 text-shark-200">
            Sorry, you don't have permission to access this page.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button
              variant="outline"
              onClick={() => {
                window.history.back();
              }}
            >
              Go back
            </Button>
          </div>
        </div>
      </main>
    </>
  );
}
