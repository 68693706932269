import { useEffect, useState } from "react";
import { GrowerSanctionScreening } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";

export default function useSanctionDeclaration(kybId?: number) {
  const [sanctionDeclaration, setSanctionDeclaration] =
    useState<GrowerSanctionScreening>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function getSanctionDeclaration() {
      setLoading(true);
      if (!kybId) {
        setLoading(false);
        return;
      }
      const response = await api.getSanctionDeclaration(+kybId);
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setSanctionDeclaration(response.growerSanctionScreening);
      setLoading(false);
    }

    getSanctionDeclaration(); // remove the return statement
  }, [kybId]);

  return { sanctionDeclaration, loading, error };
}
