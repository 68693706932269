import { useEffect, useState } from "react";
import { KYB } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";

export default function useGroweKYBs(userId?: string) {
  const [kybs, setKybs] = useState<KYB[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  function append(kyb: KYB) {
    return setKybs((kybs) => [...kybs, kyb]);
  }

  function mutate(kyb: KYB) {
    return setKybs((kybs) =>
      kybs.map((k) => {
        if (k.id === kyb.id) {
          return kyb;
        }
        return k;
      })
    );
  }

  useEffect(() => {
    async function getProject() {
      setLoading(true);
      if (!userId) {
        setLoading(false);
        return;
      }
      const response = await api.getGrowerKYBs(+userId);
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setKybs(response.kybs);
      setLoading(false);
    }

    getProject(); // remove the return statement
  }, [userId]);

  return { kybs, loading, error, append, mutate };
}
