import { useState } from "react";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import RenderAverageEmission from "../../../components/render-average-emission";
import { formatMonthYear } from "../../../helpers/format-month-year";
import {
  FarmInfo,
  IrrigationEnergy,
  SummaryEmissionData,
} from "../../../types";
import BaselineEmissionAttachments from "./emission-attachment";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import {
  BaselineEmissionAttachmentType,
  EmissionVariant,
} from "../../../constants";
import { useParams } from "react-router-dom";
import Divider from "../../../components/divider";

interface IrrigationEnergyProps {
  irrigationData?: IrrigationEnergy[];
  emissionData: SummaryEmissionData;
  farmInfo: FarmInfo;
  variant?: EmissionVariant;
  period?: string;
}
export default function IrrigationEnergyView({
  irrigationData,
  emissionData,
  farmInfo,
  variant,
  period,
}: IrrigationEnergyProps) {
  const { projectId, emissionActivityId } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const { projectAttachment, loading: attachmentLoading } =
    useProjectAttachment(
      projectId,
      emissionActivityId,
      BaselineEmissionAttachmentType.IrrigationEnergy
    );
  return (
    <>
      <p className="text-base font-semibold text-apple-400 mb-4">
        Irrigation Energy Emissions
      </p>
      <nav className="flex" aria-label="Tabs">
        {[
          variant === EmissionVariant.OnGoing
            ? "Period Data"
            : "All Years Data",
          "Summary",
        ].map((tab, i) => (
          <button
            key={i}
            onClick={() => setSelectedTab(i)}
            className={`${
              selectedTab === i
                ? "bg-shark-900 text-apple-400 font-semibold border-x border-t border-shark-600"
                : "text-shark-200 hover:text-shark-50"
            } px-4 py-2 font-medium text-sm rounded-t-md focus:outline-none`}
          >
            {tab}
          </button>
        ))}
      </nav>

      <div
        className={`bg-shark-900 ${
          selectedTab === 0 ? "rounded-tr-md rounded-b-md" : "rounded-md"
        } p-4 border border-shark-600`}
      >
        {selectedTab === 0 ? (
          <>
            {irrigationData && irrigationData.length ? (
              <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full ">
                      <thead>
                        <tr className="divide-x divide-shark-600">
                          <th
                            scope="col"
                            className="py-3.5 text-center px-5 text-xs font-semibold text-shark-200"
                          ></th>

                          <th
                            scope="col"
                            className="px-4 py-3.5  text-sm font-semibold text-shark-200 text-center"
                          >
                            Quantity of fuel used to irrigate project area
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                          >
                            Quantity of electricity used to irrigate the project
                            area
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-shark-600 ">
                        <tr className="divide-x divide-shark-600">
                          <th
                            scope="col"
                            className="py-0.5 text-center px-5 text-xs font-normal text-shark-200 "
                          ></th>
                          <th
                            scope="col"
                            className="px-4 py-0.5  text-xxs font-normal text-shark-50 text-center bg-apple-500/30"
                          >
                            Kilolitres (kL)
                          </th>
                          <th
                            scope="col"
                            className="py-0.5 pl-4 pr-4  text-xxs font-normal text-shark-50 sm:pr-0 text-center bg-apple-500/30"
                          >
                            Kilowatt Hour (kWh)
                          </th>
                        </tr>
                        {irrigationData
                          .sort((a, b) => b.activityYear - a.activityYear)
                          .map((yearData, i) => (
                            <tr key={i} className="divide-x divide-shark-600">
                              <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                {variant === EmissionVariant.OnGoing
                                  ? period
                                  : formatMonthYear(
                                      farmInfo.carbonRegMonth ?? 1,
                                      yearData.activityYear
                                    )}
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                {yearData.fuelQuantity ?? ""}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 sm:pr-0 font-semibold text-center">
                                {yearData.electrictyQuantity ?? ""}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <EmptyPlaceholder>
                Irrigation Emissions is not available because the grower has not
                submitted their Project Emissions Data.
              </EmptyPlaceholder>
            )}
            {irrigationData && irrigationData.length ? (
              <>
                <Divider />
                {irrigationData
                  .sort((a, b) => b.activityYear - a.activityYear)
                  .map((yearData, i) => (
                    <div key={i}>
                      <BaselineEmissionAttachments
                        loading={attachmentLoading}
                        year={yearData.activityYear}
                        title="Irrigation Energy"
                        attachments={projectAttachment}
                        variant={variant}
                      />
                      <div className="mt-4 mb-2 border-t border-shark-600" />
                    </div>
                  ))}
              </>
            ) : null}
          </>
        ) : (
          <>
            {emissionData.irrigationEmission ? (
              <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-shark-600">
                      <thead>
                        <tr className="divide-x divide-shark-600">
                          <th
                            rowSpan={2}
                            className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                          >
                            Years
                          </th>

                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                          >
                            Irrrigation Emissions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-shark-600 ">
                        {emissionData.irrigationEmission
                          .sort((a, b) => b.year - a.year)
                          .map((data, i) => (
                            <tr key={i} className="divide-x divide-shark-600">
                              <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center px-8 ">
                                {variant === EmissionVariant.OnGoing
                                  ? period
                                  : formatMonthYear(
                                      farmInfo.carbonRegMonth ?? 1,
                                      data.year
                                    )}
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-semibold text-shark-50 sm:pr-0 text-center">
                                {Number(
                                  (data.electricityEmission || 0) +
                                    (data.fuelEmission || 0)
                                ).toFixed(3)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {RenderAverageEmission(
                        emissionData.irrigationEmission.reduce(
                          (acc, data) =>
                            acc +
                            (data.fuelEmission || 0) +
                            (data.electricityEmission || 0),
                          0
                        ) / (variant === EmissionVariant.OnGoing ? 1 : 5),
                        variant
                      )}
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <EmptyPlaceholder>
                Irrigation Emissions is not available because the grower has not
                submitted their Project Emissions Data.
              </EmptyPlaceholder>
            )}
          </>
        )}
      </div>
    </>
  );
}
