import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

Sentry.init({
  dsn: "https://9d5dc5911d70504928058bdc43d65ca9@o4506862555365376.ingest.us.sentry.io/4506913440595968",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"], // TODO(tom): add api domain
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
