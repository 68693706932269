import { useMemo, useState } from "react";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import RenderAverageEmission from "../../../components/render-average-emission";
import { formatMonthYear } from "../../../helpers/format-month-year";
import {
  FarmInfo,
  SummaryEmissionData,
  SyntheticFerterlizer,
} from "../../../types";
import DescriptionContainer from "../../../components/description-container";
import DescriptionItem from "../../../components/description-item";
import Badge from "../../../components/badge";
import { useParams } from "react-router-dom";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import {
  BaselineEmissionAttachmentType,
  EmissionVariant,
} from "../../../constants";
import BaselineEmissionAttachments from "./emission-attachment";

interface SyntheticFertilizerProps {
  syntheticFertilizerData?: SyntheticFerterlizer[];
  emissionData: SummaryEmissionData;
  farmInfo: FarmInfo;
  variant?: EmissionVariant;
  period?: string;
}

export default function SyntheticFerterlizerView({
  syntheticFertilizerData,
  emissionData,
  farmInfo,
  variant,
  period,
}: SyntheticFertilizerProps) {
  const { projectId, emissionActivityId } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const { projectAttachment, loading: attachmentLoading } =
    useProjectAttachment(
      projectId,
      emissionActivityId,
      BaselineEmissionAttachmentType.SyntheticFerterlizer
    );

  const RenderComponent = useMemo(() => {
    return (
      <>
        {syntheticFertilizerData && syntheticFertilizerData.length ? (
          selectedTab !== syntheticFertilizerData.length ? (
            <>
              <div className="mb-4 p-2 bg-shark-600 rounded-md border border-shark-700">
                <DescriptionContainer>
                  <DescriptionItem title="Quantity of urea applied to the project area as a synthetic fertilizer">
                    {syntheticFertilizerData[selectedTab].ureaQuantity} tonnes
                  </DescriptionItem>
                  <DescriptionItem
                    title="Locations Synthetic Fertilizers was applied to"
                    variant="alternate"
                  >
                    {syntheticFertilizerData[
                      selectedTab
                    ].ferterlizerLocationData.map((data, i) => (
                      <Badge key={i}>{data.location}</Badge>
                    ))}
                  </DescriptionItem>
                </DescriptionContainer>
              </div>
              <div className="mt-4 mb-2 border-t border-shark-700" />
              {syntheticFertilizerData
                .sort((a, b) => b.activityYear - a.activityYear)
                [selectedTab].ferterlizerLocationData.map((data, i) => (
                  <div key={i}>
                    <div className="flow-root px-4">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full ">
                            <thead>
                              <tr className="divide-x divide-shark-600">
                                <th
                                  scope="col"
                                  className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                                ></th>

                                <th
                                  colSpan={3}
                                  className="py-3.5 pl-4 pr-4  text-base font-semibold text-apple-400 sm:pr-0 text-center "
                                >
                                  <div className="flex flex-col items-center justify-center">
                                    {data.location}
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-shark-600 ">
                              <tr className="divide-x divide-shark-600">
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="px-4 py-0.5  text-xs font-normal text-shark-50 text-center bg-apple-500/30"
                                >
                                  Fertilizer 1
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-0.5  text-xs font-normal text-shark-50 text-center bg-apple-500/30"
                                >
                                  Fertilizer 2
                                </th>
                                <th
                                  scope="col"
                                  className="py-0.5 pl-4 pr-4  text-xs font-normal text-shark-50 sm:pr-0 text-center bg-apple-500/30"
                                >
                                  Fertilizer 3
                                </th>
                              </tr>

                              <tr className="divide-x divide-shark-600">
                                <td className="py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                  Proportion of nitrogen content of fertilizer
                                  (%)
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                  {
                                    data.locationData?.fertiliser1
                                      ?.nitrogenContent
                                  }
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                  {
                                    data.locationData?.fertiliser2
                                      ?.nitrogenContent
                                  }
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center sm:pr-0">
                                  {
                                    data.locationData?.fertiliser3
                                      ?.nitrogenContent
                                  }
                                </td>
                              </tr>
                              <tr className="divide-x divide-shark-600">
                                <td className="py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                  Quantity applied to project area (tonnes)
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                  {
                                    data.locationData?.fertiliser1
                                      ?.quantityApplied
                                  }
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                  {
                                    data.locationData?.fertiliser2
                                      ?.quantityApplied
                                  }
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center sm:pr-0">
                                  {
                                    data.locationData?.fertiliser3
                                      ?.quantityApplied
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mb-2 border-t border-shark-700" />
                  </div>
                ))}

              <BaselineEmissionAttachments
                year={syntheticFertilizerData[selectedTab].activityYear}
                title="Synthetic Fertilizer"
                attachments={projectAttachment}
                variant={variant}
                loading={attachmentLoading}
              />
            </>
          ) : (
            <>
              {emissionData.syntheticFertilizerEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-shark-600">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              rowSpan={2}
                              className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                            >
                              Years
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                            >
                              Synthetic Fertilizer Emissions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600 ">
                          {emissionData.syntheticFertilizerEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-shark-600">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center px-8 ">
                                  {variant === EmissionVariant.OnGoing
                                    ? period
                                    : formatMonthYear(
                                        farmInfo.carbonRegMonth ?? 1,
                                        data.year
                                      )}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-semibold text-shark-50 sm:pr-0 text-center">
                                  {Number(data.emission || 0).toFixed(3)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          emissionData.syntheticFertilizerEmission.reduce(
                            (acc, data) => acc + (data.emission || 0),
                            0
                          ) / (variant === EmissionVariant.OnGoing ? 1 : 5),
                          variant
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Synthetic Fertilizer Emissions not available because the
                  grower has not submitted their Baseline Emissions Data.
                </EmptyPlaceholder>
              )}
            </>
          )
        ) : (
          <EmptyPlaceholder>
            Synthetic Fertilizer Emissions not available because the grower has
            not submitted their Project Emissions Data.
          </EmptyPlaceholder>
        )}
      </>
    );
  }, [
    syntheticFertilizerData,
    selectedTab,
    projectAttachment,
    variant,
    attachmentLoading,
    emissionData.syntheticFertilizerEmission,
    period,
    farmInfo.carbonRegMonth,
  ]);
  return (
    <>
      <p className="text-base font-semibold text-apple-400 mb-4">
        Synthetic Fertilizer Emissions
      </p>

      <nav className="flex" aria-label="Tabs">
        {[
          variant === EmissionVariant.OnGoing
            ? "Period Data"
            : syntheticFertilizerData
                ?.sort((a, b) => b.activityYear - a.activityYear)
                .map((yearData) => `${yearData.activityYear} Data`),
          "Summary",
        ]
          .flat()
          .map((tab, i) => (
            <button
              key={i}
              onClick={() => setSelectedTab(i)}
              className={`${
                selectedTab === i
                  ? "bg-shark-900 text-apple-400 font-semibold border-x border-t border-shark-600"
                  : "text-shark-200 hover:text-shark-50"
              } px-4 py-2 font-medium text-sm rounded-t-md focus:outline-none`}
            >
              {tab}
            </button>
          ))}
      </nav>
      <div
        className={`bg-shark-900 ${
          selectedTab === 0 ? "rounded-tr-md rounded-b-md" : "rounded-md"
        } p-4 border border-shark-600`}
      >
        {RenderComponent}
      </div>
    </>
  );
}
