import { useEffect, useState } from "react";
import { User } from "../types";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";

export default function useAllAdminUsers() {
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  function mutate(user: User) {
    setAllUsers((prev) => [...prev, user]);
  }

  useEffect(() => {
    async function getGrowers() {
      setLoading(true);
      const response = await api.getAllAdminUsers();
      if (response instanceof FetchError) {
        setLoading(false);
        return;
      }
      setAllUsers(response.users);
      setLoading(false);
    }

    getGrowers(); // remove the return statement
  }, []);

  return { allUsers, loading, mutate };
}
