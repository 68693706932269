import Loading from "../components/loading";
import { KYB } from "../types";
import Badge from "../components/badge";
import Button from "../components/button";
import { useState } from "react";
import useGroweKYBs from "../hooks/useGrowerKybs";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";
import Modal from "../components/modal";
import TextInput from "../components/text-input";
import { Link } from "react-router-dom";
import OverlayLoading from "../components/overlay-loading";
import { KYB_STATUS } from "../constants";
import * as Sentry from "@sentry/browser";

interface KYBComponentProps {
  growerId: string;
}

export default function KYBComponent({ growerId }: KYBComponentProps) {
  const [kyb, setKyb] = useState<KYB>();
  const [showCreateKYBModal, setShowCreateKYBModal] = useState(false);
  const [showSendKYBEmailModal, setShowSendKYBEmailModal] = useState(false);
  const [selectedKYB, setSelectedKYB] = useState<number | undefined>(undefined);
  const {
    kybs,
    loading: kybsLoading,
    append: appendKyb,
    mutate: mutateKybs,
  } = useGroweKYBs(growerId);
  const [loading, setLoading] = useState(false);

  async function addKYB() {
    if (!growerId || !kyb) {
      return;
    }
    setLoading(true);
    const response = await api.postGrowerKYB(+growerId, kyb);
    if (response instanceof FetchError) {
      Sentry.captureException(response.error);
      alert(response);
      setLoading(false);
      return;
    }

    setKyb({});
    setShowCreateKYBModal(false);
    appendKyb(response.kyb);
    setLoading(false);
  }

  async function sendKYBInvite() {
    if (!selectedKYB || !kybs) {
      return;
    }
    setLoading(true);
    const kyb = kybs.find((kyb) => kyb.id === selectedKYB);
    if (!kyb) {
      return;
    }
    const response = await api.sendKYBInvite(selectedKYB);
    if (response instanceof FetchError) {
      Sentry.captureException(response.error);
      alert(response);
      setLoading(false);
      return;
    }
    // Need to show an alert here
    mutateKybs({
      ...kyb,
      emailSent: true,
    });
    setShowSendKYBEmailModal(false);
    setLoading(false);
  }

  function CreateKYBModal() {
    return (
      <Modal
        open={showCreateKYBModal}
        setOpen={setShowCreateKYBModal}
        title="Create KYB"
        onPressAdd={addKYB}
        onPressCancel={() => {
          setShowCreateKYBModal(false);
          setKyb({});
        }}
      >
        <div className="flex flex-1 flex-col w-full">
          <TextInput
            label="KYB Year"
            type="number"
            value={kyb?.kybYear ?? ""}
            onChange={(event) =>
              setKyb({ ...kyb, kybYear: +event.target.value })
            }
          />
        </div>
      </Modal>
    );
  }

  function SendKYBEmailModal() {
    return (
      <Modal
        open={showSendKYBEmailModal}
        setOpen={setShowSendKYBEmailModal}
        title="Please Confirm"
        onPressAdd={sendKYBInvite}
        onPressCancel={() => {
          setShowSendKYBEmailModal(false);
          setKyb({});
        }}
        actionButtonName="Send Email Invite"
      >
        <div className="flex flex-1 flex-col w-full">
          <p className="text-shark-100 text-lg">
            Are you sure you want to send the KYB email invite?
          </p>
        </div>
      </Modal>
    );
  }

  const reOpenKYB = async (id: number) => {
    setLoading(true);
    const response = await api.reOpenKYB(id);
    if (response instanceof FetchError) {
      Sentry.captureException(response.error);
      alert(response);
    }
    setLoading(false);
  };

  return (
    <div className={`flex flex-col w-full`}>
      <OverlayLoading open={loading} />
      {CreateKYBModal()}
      {SendKYBEmailModal()}

      {kybsLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-1 flex-col w-full ">
          <header className="flex flex-row justify-between items-center">
            <h1 className="text-lg font-semibold text-shark-100">KYBs</h1>
            <div className="flex flex-row space-x-2">
              {/* <Link to={"/projects/new"}> */}
              <Button onClick={() => setShowCreateKYBModal(true)}>
                Create KYB
              </Button>
              {/* </Link> */}
            </div>
          </header>

          <main className="flex flex-col flex-1 w-full">
            <div className="mx-auto mt-8 grid max-w-2xl auto-rows-fr grid-cols-1 gap-4 lg:gap-8 lg:mx-0 lg:max-w-none sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {kybs.length > 0 ? (
                kybs
                  .sort(
                    (a, b) =>
                      (b.kybStatus ?? "").localeCompare(a.kybStatus ?? "") || 0
                  )
                  .map((kyb, i) => (
                    <article
                      className="relative isolate flex flex-col justify-end overflow-hidden rounded-xl bg-shark-800 border border-shark-600 px-8 py-8 shadow-lg hover:bg-shark-500 hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11"
                      key={i}
                    >
                      <div className="flex flex-col items-start gap-y-1 ">
                        <p className="text-3xl text-shark-100 font-semibold leading-6">
                          {kyb.kybYear}
                        </p>
                        <Badge
                          variant={
                            kyb.kybStatus?.toUpperCase() ===
                            KYB_STATUS.Open?.toUpperCase()
                              ? "danger"
                              : "warning"
                          }
                        >
                          {kyb.kybStatus?.toUpperCase() ===
                          KYB_STATUS.UnderReview?.toUpperCase()
                            ? "TO BE REVIEWED"
                            : kyb.kybStatus?.toUpperCase()}
                        </Badge>

                        <div className="flex flex-row mt-4 space-x-2 gap-x-2">
                          <Link to={`/growers/${growerId}/kyb/${kyb.id}`}>
                            <Button>View</Button>
                          </Link>
                          {/* {kyb.kybStatus?.toUpperCase() ===
                            KYB_STATUS.Open.toUpperCase() && (
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                setSelectedKYB(kyb.id);
                                setShowSendKYBEmailModal(true);
                              }}
                            >
                              {kyb.emailSent
                                ? "Resend Email Invite"
                                : "Send Email Invite"}
                            </Button>
                          )} */}
                          {kyb.kybStatus?.toUpperCase() ===
                            KYB_STATUS.UnderReview?.toUpperCase() && (
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const confirmed = window.confirm(
                                  "Are you sure you want to re-open KYB for this grower?"
                                );
                                if (confirmed) {
                                  reOpenKYB(kyb.id!);
                                }
                              }}
                            >
                              Re-open
                            </Button>
                          )}
                        </div>
                      </div>
                    </article>
                  ))
              ) : (
                <p>No KYB</p>
              )}
            </div>
          </main>
        </div>
      )}
    </div>
  );
}
