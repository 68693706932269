import { useMemo, useState } from "react";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import RenderAverageEmission from "../../../components/render-average-emission";
import {
  AnimalType,
  BaselineEmissionAttachmentType,
  BeefCattleType,
  DairyCattleType,
  EmissionVariant,
  SheepType,
} from "../../../constants";
import { formatMonthYear } from "../../../helpers/format-month-year";
import { GetObjectValue } from "../../../helpers/getObjectValue";
import {
  BeefCattleData,
  DairyCattleData,
  FarmInfo,
  Livestock,
  LivestockTypeData,
  SheepData,
  SummaryEmissionData,
} from "../../../types";
import { useParams } from "react-router-dom";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import BaselineEmissionAttachments from "./emission-attachment";

interface LivestockProps {
  livestockData?: Livestock[];
  emissionData: SummaryEmissionData;
  farmInfo: FarmInfo;
  variant?: EmissionVariant;
  period?: string;
}

export default function LivestockView({
  livestockData,
  emissionData,
  farmInfo,
  variant,
  period,
}: LivestockProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const { projectId, emissionActivityId } = useParams();
  const { projectAttachment, loading: attachmentLoading } =
    useProjectAttachment(
      projectId,
      emissionActivityId,
      BaselineEmissionAttachmentType.Livestock
    );

  const yearLivestockData =
    variant === EmissionVariant.OnGoing
      ? livestockData?.[0]
      : livestockData?.sort((a, b) => b.activityYear - a.activityYear)[
          selectedTab
        ];

  const genericLivestocks = yearLivestockData?.livestockData?.filter(
    (data) =>
      data.animalType !== ("BeefCattle" as AnimalType) &&
      data.animalType !== ("Sheep" as AnimalType) &&
      data.animalType !== ("DairyCattle" as AnimalType)
  );

  const RenderComponent = useMemo(() => {
    function ViewCreator(
      animal: string,
      data:
        | BeefCattleData[]
        | SheepData[]
        | DairyCattleData[]
        | LivestockTypeData
        | undefined,
      key: number
    ) {
      switch (animal) {
        case "BeefCattle": {
          const beefCattleData = data as BeefCattleData[];

          return (
            <div key={key}>
              <h1 className="text-base font-semibold text-apple-400">
                Beef Cattle
              </h1>
              {beefCattleData.length ? (
                <div className="flow-root px-2">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-1 align-middle sm:px-6 lg:px-2">
                      <table className=" min-w-full divide-y divide-shark-600">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              colSpan={2}
                              className="py-1 text-center pr-5 text-xxs font-semibold text-shark-200"
                            ></th>

                            <th
                              scope="col"
                              className="px-1 py-1 whitespace-nowrap text-xxs font-semibold text-shark-200 text-center"
                            >
                              Bulls younger <br />
                              than 1 year
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 whitespace-nowrap text-xxs font-semibold text-shark-200 text-center"
                            >
                              Bulls older <br />
                              than 1 year
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 whitespace-nowrap text-xxs font-semibold text-shark-200 text-center"
                            >
                              Steers <br /> younger <br />
                              than 1 year
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 whitespace-nowrap text-xxs font-semibold text-shark-200 text-center"
                            >
                              Steers older <br />
                              than 1 year
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 whitespace-nowrap text-xxs font-semibold text-shark-200 text-center"
                            >
                              Cows younger <br />
                              than 1 year
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 whitespace-nowrap text-xxs font-semibold text-shark-200 text-center"
                            >
                              Cows <br />1 to 2 year
                            </th>
                            <th
                              scope="col"
                              className="py-1 px-2 whitespace-nowrap  text-xxs font-semibold text-shark-200 text-center"
                            >
                              Cows older <br />
                              than 2 year
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600 ">
                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              Spring
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 text-xxs py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 w-full text-xxs text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <div className="flex flex-col justify-center items-center">
                                  <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                    {beefCattleData.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.spring?.numOfLivestock ??
                                      ""}
                                  </p>
                                  <div className="border-b border-shark-600 w-full text-center" />
                                  <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                    {beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.spring?.numOfDays ?? ""}
                                  </p>
                                </div>
                              </td>
                            ))}
                          </tr>
                          <tr className="divide-x divide-shark-600 bg-shark-800">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center ">
                              Summer
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full text-xxs px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 text-xxs w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {beefCattleData?.find(
                                    (data) => data.beefType === key
                                  )?.seasonsData?.summer?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {beefCattleData?.find(
                                    (data) => data.beefType === key
                                  )?.seasonsData?.summer?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              Autumn
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 text-xxs py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 text-xxs w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {beefCattleData?.find(
                                    (data) => data.beefType === key
                                  )?.seasonsData?.autumn?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {beefCattleData?.find(
                                    (data) => data.beefType === key
                                  )?.seasonsData?.autumn?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                          <tr className="divide-x divide-shark-600 bg-shark-800">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              Winter
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full text-xxs px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 text-xxs px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {beefCattleData?.find(
                                    (data) => data.beefType === key
                                  )?.seasonsData?.winter?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {beefCattleData?.find(
                                    (data) => data.beefType === key
                                  )?.seasonsData?.winter?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Beef Cattle Information is not available because the grower
                  has not submitted Emissions Data for this period.
                </EmptyPlaceholder>
              )}
            </div>
          );
        }

        case AnimalType.Sheep: {
          const sheepData = data as SheepData[];
          return (
            <div key={key}>
              <div className="mt-4 mb-2 border-t border-shark-600" />
              <h1 className="text-base font-semibold text-apple-400">Sheep</h1>
              {sheepData.length ? (
                <div className="mt-2 flow-root px-2">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-1 align-middle sm:px-3 lg:px-2">
                      <table className=" min-w-full divide-y divide-shark-600">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              colSpan={2}
                              className="py-1 text-center pr-5 text-xxs font-semibold text-shark-200"
                            ></th>

                            <th
                              scope="col"
                              className="px-1 py-1  text-xxs font-semibold text-shark-200 text-center"
                            >
                              Rams
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-xxs font-semibold text-shark-200 text-center"
                            >
                              Wethers
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-xxs font-semibold text-shark-200 text-center"
                            >
                              Maiden Ewes
                              <br />
                              (for breeding)
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-xxs font-semibold text-shark-200 text-center"
                            >
                              Breeding Ewes
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-xxs font-semibold text-shark-200 text-center"
                            >
                              Other Ewes
                            </th>
                            <th
                              scope="col"
                              className="py-1 pl-4 pr-4  text-xxs font-semibold text-shark-200 sm:pr-0 text-center"
                            >
                              Lambs & Hoggets
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600 ">
                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              Spring
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full text-xxs px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 text-xxs px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.spring?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData?.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.spring?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                          <tr className="divide-x divide-shark-600 bg-shark-800">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              Summer
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.summer?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData?.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.summer?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              Autumn
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.autumn?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData?.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.autumn?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                          <tr className="divide-x divide-shark-600 bg-shark-800">
                            <td className="whitespace-nowrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              Winter
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.winter?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {sheepData?.find(
                                    (data) => data.sheepType === key
                                  )?.seasonsData?.winter?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Sheep Information is not available because the grower has not
                  submitted Emissions Data for this period.
                </EmptyPlaceholder>
              )}
            </div>
          );
        }

        case "DairyCattle": {
          const dairyCattleData = data as DairyCattleData[];
          return (
            <div key={key}>
              <div className="mt-4 mb-2 border-t border-shark-600" />
              <h1 className="text-base font-semibold text-apple-400">
                Dairy Cattle
              </h1>
              {dairyCattleData.length ? (
                <div className="mt-2 flow-root px-2">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-1 align-middle sm:px-3 lg:px-2">
                      <table className=" min-w-full divide-y divide-shark-600">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              colSpan={2}
                              className="py-1 text-center pr-5 text-xxs font-semibold text-shark-200"
                            ></th>

                            <th
                              scope="col"
                              className="px-1 py-1  text-xxs font-semibold text-shark-200 text-center"
                            >
                              Milking Cows
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1  text-xxs font-semibold text-shark-200 text-center"
                            >
                              Heifers younger
                              <br />
                              than 1 Year
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-xxs font-semibold text-shark-200 text-center"
                            >
                              Heifers older
                              <br />
                              than 1 Year
                            </th>

                            <th
                              scope="col"
                              className="px-1 py-1  text-xxs font-semibold text-shark-200 text-center"
                            >
                              Dairy Bulls younger
                              <br />
                              than 1 Year
                            </th>
                            <th
                              scope="col"
                              className="py-1 pl-4 pr-4  text-xxs font-semibold text-shark-200 sm:pr-0 text-center"
                            >
                              Dairy Bulls older
                              <br />
                              than 1 Year
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600 ">
                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-wrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              {variant === EmissionVariant.OnGoing
                                ? period
                                : formatMonthYear(
                                    farmInfo?.carbonRegMonth ?? 1,
                                    yearLivestockData?.activityYear ?? 1
                                  )}
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(DairyCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap text-xxs text-shark-50"
                              >
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {dairyCattleData?.find(
                                    (data) => data.dairyCattleType === key
                                  )?.dairyCattleData?.numOfLivestock ?? ""}
                                </p>
                                <div className="border-b border-shark-600 w-full text-center" />
                                <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                  {dairyCattleData?.find(
                                    (data) => data.dairyCattleType === key
                                  )?.dairyCattleData?.numOfDays ?? ""}
                                </p>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Dairy Cattle Information is not available because the grower
                  has not submitted Emissions Data for this period.
                </EmptyPlaceholder>
              )}
            </div>
          );
        }

        case "GenericLivestock":
          return genericLivestocks && genericLivestocks.length ? (
            <div key={key}>
              <div className="mt-4 mb-2 border-t border-shark-600" />
              <h1 className="text-base font-semibold text-apple-400">
                Generic Livestock
              </h1>
              {genericLivestocks.find((data) =>
                typeof data.animalData === "object" &&
                !Array.isArray(data.animalData)
                  ? true
                  : false
              ) ? (
                <div className="mt-2 flow-root px-2">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-1 align-middle sm:px-3 lg:px-2">
                      <table className=" min-w-full divide-y divide-shark-600">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              colSpan={2}
                              className="py-1 text-center pr-5 text-xxs font-semibold text-shark-200"
                            ></th>
                            {genericLivestocks.map((data, i) => (
                              <th
                                key={i}
                                scope="col"
                                className={
                                  i < genericLivestocks.length - 1
                                    ? `px-1 py-1  text-xxs font-semibold text-shark-200 text-center`
                                    : `py-1 pl-6 pr-8  text-xxs font-semibold text-shark-200  text-center`
                                }
                              >
                                {
                                  Object.values(AnimalType)[
                                    Object.keys(AnimalType).indexOf(
                                      data.animalType ?? ""
                                    )
                                  ]
                                }
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600 ">
                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-wrap py-4  pr-4 text-xxs font-medium text-shark-200 text-center  ">
                              {formatMonthYear(
                                farmInfo?.carbonRegMonth ?? 1,
                                yearLivestockData?.activityYear ?? 1
                              )}
                            </td>
                            <td className="whitespace-nowrap text-xxs text-shark-50 bg-apple-500/30">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-1 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full border-shark-600 text-center" />
                                <div className="py-1 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {yearLivestockData &&
                            yearLivestockData.livestockData &&
                            genericLivestocks
                              ? genericLivestocks.map(
                                  (genericAnimalType, i) => (
                                    <td
                                      key={i}
                                      className="whitespace-nowrap font-semibold text-xxs text-shark-50"
                                    >
                                      <div className="flex flex-col justify-center items-center">
                                        <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                          {(
                                            (yearLivestockData.livestockData?.find(
                                              (data) =>
                                                data.animalType ===
                                                genericAnimalType.animalType
                                            )?.animalData ??
                                              {}) as LivestockTypeData
                                          ).numOfLivestock ?? ""}
                                        </p>
                                        <div className="border-b border-shark-600 w-full text-center" />
                                        <p className=" text-shark-50 w-full px-2 py-1 text-center">
                                          {(
                                            (yearLivestockData.livestockData?.find(
                                              (data) =>
                                                data.animalType ===
                                                genericAnimalType.animalType
                                            )?.animalData ??
                                              {}) as LivestockTypeData
                                          ).numOfDays ?? ""}
                                        </p>
                                      </div>
                                    </td>
                                  )
                                )
                              : null}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Generic Livestock Information is not available because the
                  grower has not submitted Emissions Data for this period.
                </EmptyPlaceholder>
              )}
            </div>
          ) : null;
        default:
          return null;
      }
    }
    if (livestockData && livestockData.length > 0) {
      if (selectedTab > livestockData.length - 1) {
        return (
          <>
            {emissionData.livestockEmission ? (
              <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto">
                  <div className="inline-block min-w-full py-1 align-middle sm:px-6 lg:px-1">
                    <table className="min-w-full divide-y divide-shark-600">
                      <thead>
                        <tr className="divide-x divide-shark-600">
                          <th
                            // scope="col"
                            rowSpan={100}
                            className="py-1 text-center px-5 text-xxs font-semibold text-shark-200 "
                          >
                            Years
                          </th>

                          <th
                            colSpan={
                              emissionData.livestockEmission[0].animalEmisions
                                ?.length
                            }
                            className="py-1 pl-4 pr-4  text-xxs font-semibold text-shark-200 sm:pr-0 text-center "
                          >
                            Livestock Emissions
                          </th>
                        </tr>
                        <tr className="divide-x divide-shark-600 bg-shark-700">
                          {emissionData.livestockEmission[0].animalEmisions?.map(
                            (data, i) => (
                              <th
                                key={i}
                                scope="col"
                                className={
                                  i <
                                  (emissionData.livestockEmission?.[0]
                                    ?.animalEmisions?.length ?? 0) -
                                    2
                                    ? `px-1 py-1  text-xxs font-semibold text-shark-200 text-center`
                                    : `py-1 pl-6 pr-8  text-xxs font-semibold text-shark-200  text-center`
                                }
                              >
                                {GetObjectValue(
                                  AnimalType,
                                  data.animal as AnimalType
                                )}
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-shark-600">
                        {emissionData.livestockEmission
                          .sort((a, b) => b.year - a.year)
                          .map((data, i) => (
                            <tr key={i} className="divide-x divide-shark-600">
                              <td className="whitespace-wrap py-1 pl-2 pr-2 text-xxs font-medium text-shark-200 text-center px-1 ">
                                {variant === EmissionVariant.OnGoing
                                  ? period
                                  : formatMonthYear(
                                      farmInfo.carbonRegMonth ?? 1,
                                      data.year
                                    )}
                              </td>

                              {data.animalEmisions?.map((data, i) => (
                                <td
                                  key={i}
                                  className="whitespace-nowrap py-4 px-2 text-xxs text-shark-50 text-center"
                                >
                                  {Number(data.emission).toFixed(3)}
                                </td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                      {RenderAverageEmission(
                        emissionData.livestockEmission.reduce(
                          (acc, data) =>
                            acc +
                            (data.animalEmisions?.reduce(
                              (acc, data) => acc + (data.emission ?? 0),
                              0
                            ) || 0),
                          0
                        ) / (variant === EmissionVariant.OnGoing ? 1 : 5),
                        variant
                      )}
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <EmptyPlaceholder>
                Livestock Emissions is not available because the grower has not
                submitted their Project Emissions Data.
              </EmptyPlaceholder>
            )}
          </>
        );
      } else {
        return (
          <>
            {yearLivestockData && yearLivestockData.livestockData ? (
              <>
                {yearLivestockData.livestockData.map(
                  (data, i) =>
                    ViewCreator(data.animalType ?? "", data.animalData, i) ??
                    null
                )}
                {ViewCreator(
                  "GenericLivestock",
                  undefined,
                  livestockData[selectedTab].activityYear
                )}
              </>
            ) : (
              <EmptyPlaceholder>
                Livestock Emissions is not available for this year because the
                grower has not submitted their Project Emissions Data.
              </EmptyPlaceholder>
            )}
          </>
        );
      }
    } else {
      return (
        <EmptyPlaceholder>
          Livestock Emissions is not available because the grower has not
          submitted their Livestock Data.
        </EmptyPlaceholder>
      );
    }
  }, [
    livestockData,
    genericLivestocks,
    farmInfo,
    yearLivestockData,
    selectedTab,
    emissionData,
    variant,
    period,
  ]);

  return (
    <>
      <p className="text-base font-semibold text-apple-400 mb-4">
        Livestock Emissions
      </p>
      <nav className="flex" aria-label="Tabs">
        {[
          variant === EmissionVariant.OnGoing
            ? "Period Data"
            : livestockData
                ?.sort((a, b) => b.activityYear - a.activityYear)
                .map((yearData) => `${yearData.activityYear} Data`),
          "Summary",
        ]
          .flat()
          .map((tab, i) => (
            <button
              key={i}
              onClick={() => setSelectedTab(i)}
              className={`${
                selectedTab === i
                  ? "bg-shark-900 text-apple-400 font-semibold border-x border-t border-shark-600"
                  : "text-shark-200 hover:text-shark-50"
              } px-4 py-2 font-medium text-sm rounded-t-md focus:outline-none`}
            >
              {tab}
            </button>
          ))}
      </nav>
      <div
        className={`bg-shark-900 ${
          selectedTab === 0 ? "rounded-tr-md rounded-b-md" : "rounded-md"
        } p-4 border border-shark-600 `}
      >
        {RenderComponent}
        {yearLivestockData && (
          <>
            <div className="mt-4 mb-2 border-t border-shark-700" />
            <BaselineEmissionAttachments
              attachments={projectAttachment}
              year={yearLivestockData?.activityYear ?? 0}
              title="Livestock Emission"
              variant={variant}
              loading={attachmentLoading}
            />
          </>
        )}
      </div>
    </>
  );
}
