import AddressView from "../../components/address-view";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import { EntityTypes } from "../../constants";
import { Address, BusinessInfo } from "../../types";

interface BusinessInformationProps {
  data?: BusinessInfo;
}

export default function BusinessInfoView({ data }: BusinessInformationProps) {
  return (
    <div className="w-full p-4">
      {!data ? (
        <p>No Business Information</p>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col">
            <DescriptionContainer>
              <DescriptionItem title="Full Legal Name" variant="alternate">
                {data.legalName ?? ""}
              </DescriptionItem>
              <DescriptionItem title="Other name/s (if any)">
                {data.otherName ?? ""}
              </DescriptionItem>
              <DescriptionItem title="ABN/ACN" variant="alternate">
                {data.abnAcnType ?? ""}: {data.abnAcnNumber ?? ""}
              </DescriptionItem>
              <DescriptionItem title="Place of Incorporation/Establishment">
                {data.placeOfIncorporation ?? ""}
              </DescriptionItem>
              <DescriptionItem
                title="Date of Incorporation/Establishment"
                variant="alternate"
              >
                {data.dateOfIncorporation ?? ""}
              </DescriptionItem>
              <DescriptionItem title="Nature of Business">
                {data.natureOfBusiness ?? ""}
              </DescriptionItem>
              <DescriptionItem
                title="Principal Office Address"
                variant="alternate"
              >
                <AddressView
                  data={data.principalOfficeAddress ?? ({} as Address)}
                />
              </DescriptionItem>
              <DescriptionItem title="Registered Office Address">
                <AddressView
                  data={data.registeredOfficeAddress ?? ({} as Address)}
                />
              </DescriptionItem>
              <DescriptionItem title="Other Address" variant="alternate">
                <AddressView data={data.otherAddresses ?? ({} as Address)} />
              </DescriptionItem>
              {data.entityType ? (
                <>
                  <DescriptionItem title="Business Entity">
                    {data.entityType ?? ""}
                  </DescriptionItem>
                  {data.entityType === EntityTypes.Other ? (
                    <DescriptionItem
                      title="Other Entity Type"
                      variant="alternate"
                    >
                      {data.otherEntityType ?? ""}
                    </DescriptionItem>
                  ) : null}
                </>
              ) : null}
            </DescriptionContainer>
          </div>
        </div>
      )}
    </div>
  );
}
