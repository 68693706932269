import { useEffect, useState } from "react";
import { KybNode } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";

export default function useKYBData(kybId?: number) {
  const [kybData, setKYBData] = useState<KybNode[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function getProject() {
      setLoading(true);
      if (!kybId) {
        setLoading(false);
        return;
      }
      const response = await api.getAllKYBNodeData(+kybId);
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setKYBData(response.kybNodes);
      setLoading(false);
    }

    getProject(); // remove the return statement
  }, [kybId]);

  return { kybData, loading, error };
}
