import { useEffect, useState } from "react";
import { Grower } from "../types";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";

export default function useAllGrowers() {
  const [allGrowers, setAllGrowers] = useState<Grower[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getGrowers() {
      setLoading(true);
      const response = await api.getAllGrowers();
      if (response instanceof FetchError) {
        setLoading(false);
        return;
      }
      setAllGrowers(response.growers);
      setLoading(false);
    }

    getGrowers(); // remove the return statement
  }, []);

  return { allGrowers, loading };
}
