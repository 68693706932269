import { useMemo } from "react";
import { Address, PartnershipData, Attachment } from "../../types";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import AddressView from "../../components/address-view";
import Badge from "../../components/badge";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import Button from "../../components/button";
import { config } from "../../config";
import DescriptionItemYesNo from "../../components/description-item-yesno";

interface Props {
  data?: PartnershipData;
  attachments?: Attachment[];
}
export default function PartnershipDetailsView({ data, attachments }: Props) {
  const partners = useMemo(() => {
    if (!data || !data.entityPeople) return [];
    return data.entityPeople.filter((p) => p.personType === "Partner");
  }, [data]);

  const beneficialOwners = useMemo(() => {
    if (!data || !data.entityPeople) return [];
    return data.entityPeople.filter((p) => p.personType === "Beneficiary");
  }, [data]);

  return (
    <div className="w-full p-4">
      {!data ? (
        <p>No Partnership Data</p>
      ) : (
        <div className="flex flex-col">
          <p className="text-base text-apple-400 font-semibold">
            Partners ({partners.length}){" "}
          </p>

          {partners.map((person, i) => (
            <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
              <DescriptionContainer key={i}>
                <DescriptionItem title="Legal Name" variant="alternate">
                  {person.legalName ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Email">
                  {person.email ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Address" variant="alternate">
                  <AddressView data={person.address ?? ({} as Address)} />
                </DescriptionItem>
                {person.toBeKYC ? (
                  <DescriptionItem title="To be KYC">
                    <div className="flex">
                      <Badge variant="warning">Yes</Badge>
                    </div>
                  </DescriptionItem>
                ) : null}
              </DescriptionContainer>
            </div>
          ))}
          <div className="border-b border-shark-700 my-4" />
          <p className="text-base text-apple-400 font-semibold">
            Beneficial Owners ({beneficialOwners.length}){" "}
          </p>
          {beneficialOwners.map((person, i) => (
            <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
              <DescriptionContainer key={i}>
                <DescriptionItem title="Legal Name" variant="alternate">
                  {person.legalName ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Email">
                  {person.email ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Entity Type" variant="alternate">
                  {person.entityType ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Ownership Percentage">
                  {person.ownershipPercentage ?? ""}
                  {person.ownershipPercentage ? "%" : ""}
                </DescriptionItem>
              </DescriptionContainer>
            </div>
          ))}

          <div className="border-b border-shark-700 my-4" />
          <p className="text-base text-apple-400 font-semibold pb-4">
            Attachments ({(attachments ?? []).length}){" "}
          </p>
          <DescriptionContainer>
            <DescriptionItemYesNo
              title="Do you have a Partnership Agreement?"
              variant="alternate"
            >
              {data.hasPartnershipAgreement}
            </DescriptionItemYesNo>
          </DescriptionContainer>
          {!attachments || !attachments.length ? null : (
            <div className="flex flex-col">
              {attachments.map((attachment, i) => (
                <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
                  <li className="flex items-center justify-between pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-shark-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {attachment.attachmentType}
                        </span>
                      </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        target="_blank"
                        href={`${config.attachmentUrlPrefix ?? ""}${
                          attachment.attachmentUrl
                        }`}
                        rel="noreferrer"
                      >
                        <Button variant="outline">View</Button>
                      </a>
                    </div>
                  </li>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
