import { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Grower as IGrower, User } from "../../types";
import { FetchError } from "../../lib/fetcher";
import { api } from "../../api";
import Loading from "../../components/loading";
import useAllUsers from "../../hooks/useAllUsers";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Button from "../../components/button";
import KYBComponent from "../kybs";
import GrowerDetails from "./grower-details";
import useAuth from "../../hooks/useAuth";
import { UserRole } from "../../constants";
import PageNotFound from "../../components/403-error";
// import Breadcrumbs from "../../components/breadcrumb";

export default function Grower() {
  const { growerId } = useParams();
  const { user } = useAuth();
  const [grower, setGrower] = useState<IGrower>();
  const [loading, setLoading] = useState(false);
  const [reloadGrowerUsers, setReloadGrowerUsers] = useState(false);
  const [growerUsersLoading, setGrowerUsersLoading] = useState(false);
  const [showAttachUserModal, setShowAttachUserModal] = useState(false);

  const [growerUsers, setGrowerUsers] = useState<User[]>([]);
  const [query, setQuery] = useState("");

  const { allUsers, loading: loadingUsers } = useAllUsers();
  const { search } = useLocation();

  const tabParam = useMemo(() => new URLSearchParams(search), [search]);

  const currentTab = tabParam.get("tab");

  const tabs = [
    { name: "Details", href: "?tab=1", current: currentTab === "1" },
    { name: "Know Your Business", href: "?tab=2", current: currentTab === "2" },
    { name: "Grower Users", href: "?tab=3", current: currentTab === "3" },
  ];

  useEffect(() => {
    async function getGrower() {
      if (!growerId) {
        return;
      }
      setLoading(true);
      const response = await api.getGrower(+growerId);
      if (response instanceof FetchError) {
        setLoading(false);
        return;
      }
      setGrower(response.grower);
      setLoading(false);
    }

    getGrower();
  }, [growerId]);

  useEffect(() => {
    async function getGrowerUsers() {
      if (!growerId) {
        return;
      }
      setGrowerUsersLoading(true);
      const response = await api.getGrowerUsers(+growerId);
      if (response instanceof FetchError) {
        setGrowerUsersLoading(false);
        return;
      }
      setGrowerUsers(response.users);
      setGrowerUsersLoading(false);
    }

    getGrowerUsers();

    if (reloadGrowerUsers) {
      setReloadGrowerUsers(false);
    }
  }, [growerId, reloadGrowerUsers]);

  function attachGrowerUser(user: User) {
    if (!growerId || !user) {
      return;
    }
    const response = api.postGrowerUser(+growerId, user);
    if (response instanceof FetchError) {
      return;
    }
    setReloadGrowerUsers(true);
    setShowAttachUserModal(false);
  }

  const unselectedUsers = useMemo(() => {
    if (!allUsers || !growerUsers) {
      return [];
    }
    return allUsers.filter((user) => {
      return !growerUsers.some((growerUser) => growerUser.id === user.id);
    });
  }, [allUsers, growerUsers]);

  function AttachUserView() {
    const filteredUsers =
      query === ""
        ? unselectedUsers.filter((user) => {
            return !growerUsers.some(
              (projectGrower) => projectGrower.id === user.id
            );
          })
        : unselectedUsers.filter((user) => {
            return (
              user.firstName.toLowerCase().includes(query.toLowerCase()) ||
              user.lastName.toLowerCase().includes(query.toLowerCase())
            );
          });

    return (
      <Transition.Root
        show={showAttachUserModal}
        as={Fragment}
        afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setShowAttachUserModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-shark-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-shark-300 overflow-hidden rounded-xl bg-shark-500 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-shark-200"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-shark-50 placeholder:text-shark-200 focus:ring-0 sm:text-md"
                      placeholder="Search..."
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>

                  {loadingUsers ? (
                    <Loading />
                  ) : (
                    filteredUsers.length > 0 && (
                      <Combobox.Options
                        static
                        className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-shark-200 "
                      >
                        {filteredUsers.map((user, i) => (
                          <Combobox.Option
                            key={user.id}
                            value={user}
                            onClick={() => {
                              attachGrowerUser(user);
                              setShowAttachUserModal(false);
                            }}
                            className={({ active }) =>
                              `cursor-pointer select-none px-4 py-2 ${
                                active && "bg-apple-400 text-white"
                              } ${
                                i < filteredUsers.length - 1
                                  ? "border-b border-shark-400"
                                  : ""
                              }`
                            }
                          >
                            {user.firstName} {user.lastName}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )
                  )}

                  {query !== "" && filteredUsers.length === 0 && (
                    <p className="p-4 text-sm text-shark-100">
                      No allGrowers found.
                    </p>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return (
    <div className="flex flex-col mt-4 w-full py-4 px-8">
      {/* <Breadcrumbs /> */}
      {loading || !growerId ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full">
          {AttachUserView()}
          <div className="border-b border-shark-400">
            <h3 className="text-xl lg:text-3xl font-semibold text-shark-200">
              {grower?.businessInfo.legalName}
            </h3>
            <p className="mt-2 max-w-4xl text-sm text-shark-300">
              {grower?.businessInfo.natureOfBusiness}
            </p>

            <div className="block mt-8">
              <nav className="-mb-px flex space-x-4 overflow-x-auto overflow-y-hidden">
                {tabs.map((tab) => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={`
                    ${
                      tab.current
                        ? "border-apple-400 text-apple-400 font-semibold"
                        : "border-transparent text-shark-200 hover:border-shark-400 hover:text-shark-400"
                    }
                     whitespace-nowrap border-b-2 px-1 pb-2 text-sm `}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          <div className="flex-1 w-full h-full mt-4">
            {currentTab === "1" && (
              <div className="flex flex-col px-2">
                <GrowerDetails data={grower?.businessInfo} />
              </div>
            )}
            {currentTab === "2" && (
              <div className="flex flex-col px-2">
                {user && user.role === UserRole.SUPER_ADMIN ? (
                  <KYBComponent growerId={growerId} />
                ) : (
                  <PageNotFound />
                )}
              </div>
            )}
            {currentTab === "3" && (
              <div className="flex flex-col px-2">
                <header className="flex flex-row justify-between items-center">
                  <h1 className="text-lg font-semibold text-shark-100">
                    Grower Users
                  </h1>
                  <div className="flex flex-row space-x-2">
                    {/* <Link to={"/projects/new"}> */}
                    <Button onClick={() => setShowAttachUserModal(true)}>
                      Attach User
                    </Button>
                    {/* </Link> */}
                  </div>
                </header>

                <main className="flex flex-col flex-1 px-8  bg-[#202125] h-full overflow-y-hidden">
                  <div className="flex flex-col mt-4 w-full">
                    {growerUsersLoading ? (
                      <Loading />
                    ) : (
                      <div className="flex flex-col ">
                        {growerUsers.length > 0 ? (
                          growerUsers.map((user, i) => (
                            // <Link
                            //   to={`/users/${user.id}`}
                            //   key={i}
                            //   className="border-b border-shark-600 py-3"
                            // >
                            <div
                              key={i}
                              className="flex flex-row justify-between items-center gap-x-4 "
                            >
                              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-apple-200">
                                <span className="text-xs font-medium leading-none text-apple-800">
                                  {user?.firstName.charAt(0).toUpperCase()}
                                </span>
                              </span>
                              <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-white">
                                  {user?.firstName} {user?.lastName}
                                </p>
                                {/* <p className="text-xs leading-6 text-white">
                                      {grower?.abnAcnNumber}
                                    </p> */}
                              </div>
                            </div>
                            // </Link>
                          ))
                        ) : (
                          <p>No Grower User</p>
                        )}
                      </div>
                    )}
                  </div>
                </main>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
