import { useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import ProjectGrowers from "./project-growers";
import EmissionActivity from "./all-emission";
import useProject from "../../hooks/useProject";

export default function Project() {
  const { projectId } = useParams();
  const { search } = useLocation();

  const tabParam = useMemo(() => new URLSearchParams(search), [search]);
  const currentTab = tabParam.get("tab");

  const { project, loading: projectLoading } = useProject(projectId);

  const tabs = [
    { name: "Emissions", href: "?tab=1", current: currentTab === "1" },
    { name: "Growers", href: "?tab=2", current: currentTab === "2" },
  ];

  return (
    <div className="flex flex-col mt-4 w-full py-4 px-4">
      {projectLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full">
          <div className="border-b border-shark-400">
            <h3 className="text-xl font-semibold leading-6 text-shark-200">
              {project?.projectInfo?.name}
            </h3>
            <p className="mt-2 max-w-4xl text-sm text-shark-300">
              {project?.projectInfo?.description}
            </p>

            <div className="block mt-8">
              <nav className="-mb-px flex space-x-4 overflow-x-auto overflow-y-hidden">
                {tabs.map((tab) => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={`
                    ${
                      tab.current
                        ? "border-apple-400 text-apple-400 font-semibold"
                        : "border-transparent text-shark-200 hover:border-shark-400 hover:text-shark-400"
                    }
                     whitespace-nowrap border-b-2 px-1 pb-2 text-sm `}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          <div className="flex-1 w-full h-full mt-4">
            {currentTab === "1" && <EmissionActivity />}
            {currentTab === "2" && <ProjectGrowers />}
          </div>
        </div>
      )}
    </div>
  );
}
