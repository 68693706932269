import { useState } from "react";
import Loading from "../../components/loading";
import { FetchError } from "../../lib/fetcher";
import { BusinessInfo } from "../../types";
import { api } from "../../api";

import SelectInput from "../../components/select-input";
import TextInput from "../../components/text-input";
import { Country } from "../../constants";

const COMPANY_ID_TYPE = {
  ABN: "ABN",
  ACN: "ACN"
}

export default function NewGrower() {
  const [growerLoading, setGrowerLoading] = useState(false);
  const [newGrower, setNewGrower] = useState<Partial<BusinessInfo>>();
  async function handleCreateGrower() {
    if (!newGrower) {
      return;
    }
    if (!newGrower.legalName) {
      return;
    }
    setGrowerLoading(true);
    const response = await api.postGrower({
      businessInfo: {
        legalName: newGrower.legalName,
        placeOfIncorporation: Country.Australia,
        abnAcnType: newGrower.abnAcnType,
        abnAcnNumber: newGrower.abnAcnNumber,
        placeOfIncorporationOther: newGrower.placeOfIncorporationOther,
      },
    });
    if (response instanceof FetchError) {
      setGrowerLoading(false);
      console.log("Error creating grower" + response.message);
      return;
    }

    setGrowerLoading(false);
    window.location.href = "/growers";
  }
  return (
    <div className="flex w-full flex-col px-4 sm:px-8">
      <header className="flex flex-row justify-between items-center p-4">
        <h1 className="text-2xl font-semibold">New Grower</h1>
      </header>
      <main className="flex flex-col flex-1 mb-8 px-2 sm:px-4">
        {growerLoading ? (
          <Loading />
        ) : (
          <>
          <form onSubmit={handleCreateGrower}>
            <div className="mt-1 grid grid-cols-1 gap-x-6 gap-y-0 sm:grid-cols-12 px-2 sm:px-4">
              <div className="sm:col-span-12">
                <TextInput
                  label="Name"
                  type="text"
                  required
                  value={newGrower?.legalName ?? ""}
                  onChange={(e) =>
                    setNewGrower({
                      ...newGrower,
                      legalName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-span-4 sm:col-span-4">
                <SelectInput
                  label="Does your business have an ABN or an ACN?"
                  value={newGrower?.abnAcnType ?? ""}
                  onChange={(e) => {
                    setNewGrower({
                      ...newGrower,
                      abnAcnType: e.target.value,
                      abnAcnNumber: "",
                    });
                  }}
                >
                  <option></option>
                  <option>{COMPANY_ID_TYPE.ABN}</option>
                  <option>{COMPANY_ID_TYPE.ACN}</option>
                </SelectInput>
              </div>
              <div className="sm:col-span-8">
                {newGrower?.abnAcnType === COMPANY_ID_TYPE.ACN && <TextInput
                  label={COMPANY_ID_TYPE.ACN}
                  type="text"
                  required={true}
                  pattern="[0-9]{9,9}"
                  title="ACN must be a 9 digit number"
                  value={newGrower?.abnAcnNumber}
                  onChange={(e) => {
                    setNewGrower({
                      ...newGrower,
                      abnAcnNumber: e.target.value,
                    });
                  }}
                />}
                {newGrower?.abnAcnType === COMPANY_ID_TYPE.ABN && <TextInput
                  label={COMPANY_ID_TYPE.ABN}
                  type="text"
                  required={true}
                  pattern="[0-9]{11,11}"
                  title="ABN must be a 11 digit number"
                  value={newGrower?.abnAcnNumber}
                  onChange={(e) => {
                    setNewGrower({
                      ...newGrower,
                      abnAcnNumber: e.target.value,
                    });
                  }}
                />}
              </div>
              <div className="sm:col-span-6">
                <TextInput
                  label="Place of Incorporation"
                  type="text"
                  disabled
                  value={Country.Australia}
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <input
                type="submit"
                className="inline-flex w-full justify-center rounded-xl bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                value="Create Grower"
                />
            </div>
            </form>
          </>
        )}
      </main>
    </div>
  );
}
