interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
}

export default function Checkbox({ label, ...props }: Props) {
  return (
    <div className="relative flex items-center justify-end">
      <div className="flex flex-row h-8 items-center">
        <input
          {...props}
          id={label}
          name={label}
          type="checkbox"
          className={`h-5 w-5 rounded-md ${
            props.error ? "border-shark-500" : "border-red-500"
          } cursor-pointer ${
            props.disabled ? "text-gray-300" : "text-apple-500"
          } focus:ring-apple-500 bg-transparent`}
        />
      </div>
      {label ? (
        <div className="ml-3 w-full text-sm sm:text-base leading-6 ">
          <label
            htmlFor={label}
            className="text-sm font-medium leading-6 text-shark-200 cursor-pointer hover:bg-apple-200 hover:text-apple-500 transition ease-in-out duration-150 py-1"
          >
            {label}
          </label>
        </div>
      ) : null}
    </div>
  );
}
