import TextInput from "../components/text-input";
import logo from "./../assets/logo.png";
import image from "./../assets/image1.jpg";
import { useState } from "react";
import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import firebaseApp from "../firebaseConfig";
import Loading from "../components/loading";
import Success from "../components/success";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  function handleSignIn() {
    if (!loading) {
      setLoading(true);
    }

    if (!email || !password) {
      return;
    }

    const auth = getAuth(firebaseApp);
    setPersistence(auth, browserSessionPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          if (!userCredential.user.email) {
            setLoading(false);
            setShowSuccess(false);
            setShowError(true);
            return;
          }
          const response = await api.login(userCredential.user.email);
          if (response instanceof FetchError) {
            setLoading(false);
            setShowSuccess(false);
            setShowError(true);
            return;
          }

          setLoading(false);
          setShowSuccess(true);
          navigate("/");
        })
        .catch(() => {
          setLoading(false);
          setShowSuccess(false);
          setShowError(true);
        });
    });
  }

  function ErrorView() {
    return (
      <div className="rounded-xl bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              There was an error signing you in.
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <p>Please check your credentials and try again.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center bg-gradient-to-b from-apple-100/20 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm items-center lg:w-96">
          {loading ? (
            <Loading />
          ) : showSuccess ? (
            <Success />
          ) : (
            <>
              <div className="flex flex-col lg:block w-full items-center">
                <img src={logo} alt="Logo" className="h-28 w-auto sm:h-32" />
                <h2 className="mt-8 text-2xl font-medium leading-9 tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
              </div>

              <div className="mt-10">
                {showError ? <ErrorView /> : null}
                <div>
                  <div className="space-y-6 w-full">
                    <TextInput
                      label="Email"
                      id="email"
                      type="email"
                      value={email ?? ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setShowError(false);
                      }}
                    />
                    <TextInput
                      label="Password"
                      id="password"
                      type="password"
                      value={password ?? ""}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setShowError(false);
                      }}
                    />
                    <div className="flex w-full pt-6">
                      <button
                        disabled={loading || !email || !password}
                        className={`flex w-full justify-center rounded-lg hover:bg-apple-400 bg-apple-500 py-2 px-5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600 ${
                          loading || !email || !password
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                        onClick={() => handleSignIn()}
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover "
          src={image}
          alt=""
        />
      </div>
    </div>
  );
}