import { Player } from "@lottiefiles/react-lottie-player";
import LoadingAnimation from "../assets/lottie/loading.json";

export default function Loading() {
  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center bg-transparent">
        <Player
          autoplay
          loop
          controls
          src={LoadingAnimation}
          style={{
            height: "300px",
            width: "300px",
          }}
        />
      </div>
    </div>
  );
}
