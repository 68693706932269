export const VersionNumber = "v 3.1.0";

export enum AustralianState {
  NewSouthWales = "New South Wales",
  Victoria = "Victoria",
  Queensland = "Queensland",
  WesternAustralia = "Western Australia",
  SouthAustralia = "South Australia",
  Tasmania = "Tasmania",
  AustralianCapitalTerritory = "Australian Capital Territory",
  NorthernTerritory = "Northern Territory",
}

export enum CanadaProvince {
  Alberta = "Alberta",
  BritishColumbia = "British Columbia",
  Manitoba = "Manitoba",
  NewBrunswick = "New Brunswick",
  NewfoundlandAndLabrador = "Newfoundland and Labrador",
  NorthwestTerritories = "Northwest Territories",
  NovaScotia = "Nova Scotia",
  Nunavut = "Nunavut",
  Ontario = "Ontario",
  PrinceEdwardIsland = "Prince Edward Island",
  Quebec = "Quebec",
  Saskatchewan = "Saskatchewan",
  Yukon = "Yukon",
}

export enum Country {
  Australia = "Australia",
  Canada = "Canada",
  UnitedStates = "United States",
  Other = "Other",
}

export enum AustraliaRegion {
  SouthWest = "South West",
  Pilbara = "Pilbara",
  Kimberley = "Kimberley",
}

export enum UnitedStatesState {
  Alabama = "Alabama",
  Alaska = "Alaska",
  Arizona = "Arizona",
  Arkansas = "Arkansas",
  California = "California",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  Delaware = "Delaware",
  DistrictOfColumbia = "District of Columbia",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Hawaii",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Iowa = "Iowa",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Maine = "Maine",
  Maryland = "Maryland",
  Massachusetts = "Massachusetts",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Mississippi = "Mississippi",
  Missouri = "Missouri",
  Montana = "Montana",
  Nebraska = "Nebraska",
  Nevada = "Nevada",
  NewHampshire = "New Hampshire",
  NewJersey = "New Jersey",
  NewMexico = "New Mexico",
  NewYork = "New York",
  NorthCarolina = "North Carolina",
  NorthDakota = "North Dakota",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pennsylvania",
  RhodeIsland = "Rhode Island",
  SouthCarolina = "South Carolina",
  SouthDakota = "South Dakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Vermont = "Vermont",
  Virginia = "Virginia",
  Washington = "Washington",
  WestVirginia = "West Virginia",
  Wisconsin = "Wisconsin",
  Wyoming = "Wyoming",
}

export enum EntityTypes {
  SoleTrader = "Sole Trader",
  Partnership = "Partnership",
  UnlistedCompany = "Unlisted Company",
  Trust = "Trust",
  Other = "Other",
}

export enum DataTypes {
  KYBData = "KYBData",
  ConsentData = "ConsentData",
  BusinessInfoData = "BusinessInfoData",
  UnlistedCompanyData = "UnlistedCompanyData",
}

export enum UnlistedCompanyPersonType {
  Director = "Director",
  BeneficialOwner = "BeneficialOwner",
  Controller = "Controller",
}

export enum PartnershipPersonType {
  Partner = "Partner",
  Beneficiary = "Beneficiary",
}

export enum TrustType {
  Discretionary = "Discretionary Trust",
  FixedUnit = "Fixed Unit Trust",
  Other = "Other",
}

export enum TrustPersonType {
  Beneficiary = "Beneficiary",
  Trustee = "Trustee",
  Settlor = "Settlor",
}

export enum TrusteeEntityType {
  Company = "Company Trustee",
  Individual = "Individual Trustee",
}

export enum UserRole {
  ADMIN = "admin",
  GROWER = "grower",
  SUPER_ADMIN = "super_admin",
}

export enum RegularUserRoles {
  ADMIN = "admin",
  GROWER = "grower",
}

export const Month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export enum Region {
  SouthWest = "South West",
  Pilbara = "Pilbara",
  Kimberley = "Kimberley",
}

export enum YesNo {
  Yes = "Yes",
  No = "No",
}

export enum AnimalType {
  BeefCattle = "Beef Cattle",
  Buffalo = "Buffalo",
  DairyCattle = "Dairy Cattle",
  DonkeysMules = "Donkeys/Mules",
  Sheep = "Sheep",
  EmusOsctriches = "Emus/Osctriches",
  Goats = "Goats",
  Alpacas = "Alpacas",
  Horses = "Horses",
  Camels = "Camels",
  Deer = "Deer",
}

export type GenericAnimals = Exclude<
  AnimalType,
  "Sheep" | "Beef Cattle" | "Dairy Cattle"
>;

export enum CropsPlanted {
  Wheat = "Wheat",
  Barley = "Barley",
  Maize = "Maize",
  Oats = "Oats",
  Rice = "Rice",
  Sorghum = "Sorghum",
  Triticale = "Triticale",
  OtherCereals = "Other Cereals",
  Pulses = "Pulses",
  TuberAndRoots = "Tuber and Roots",
  SugarCane = "Sugar Cane",
  Peanuts = "Peanuts",
  Cotton = "Cotton",
  Hops = "Hops",
  Oilseeds = "Oilseeds",
  ForageCrops = "Forage Crops",
}

export enum PastureCrops {
  AnnualGrass = "Annual Grass",
  GrassCloverMixture = "Grass Clover Mixture",
  Lucerne = "Lucerne",
  OtherLegumes = "Other Legumes",
  PerennialPasture = "Perennial Pasture",
  ForagedCrops = "Foraged Crops",
  CoverCrops = "Cover Crops",
}

export enum SyntheticFertilizerLocation {
  IrrigatedPasture = "Irrigated Pasture",
  IrrigatedCrop = "Irrigated Crop",
  NonIrrigatedPasture = "Non-Irrigated Pasture",
  NonIrrigatedCrop = "Non-Irrigated Crop",
  SugarCane = "Sugar Cane",
  Cotton = "Cotton",
  HorticultureVegetables = "Horticulture/Vegetables",
}
export enum Seasons {
  Spring = "Spring",
  Summer = "Summer",
  Autumn = "Autumn",
  Winter = "Winter",
}

export enum SheepType {
  Rams = "Rams",
  Wethers = "Wethers",
  MaidenEwes = "Maiden Ewes (for breeding)",
  BreedingEwes = "Breeding Ewes",
  OtherEwes = "Other Ewes",
  LambsHoggets = "Lambs & Hoggets",
}

export enum BeefCattleType {
  BullsYoungerThan1 = "Bulls younger than 1 Year",
  BullsOlderThan1 = "Bulls older than 1 Year",
  SteersYoungerThan1 = "Steers younger than 1 Year",
  SteersOlderThan1 = "Steers older than 1 Year",
  CowsYoungerThan1 = "Cows younger than 1 Year",
  Cows1To2 = "Cows 1 to 2 Year",
  CowsOlderThan2 = "Cows older than 2 Year",
}

export enum DairyCattleType {
  MilkingCows = "Milking Cows",
  HeifersYoungerThan1 = "Heifers younger than 1 Year",
  HeifersOlderThan1 = "Heifers older than 1 Year",
  DairyBullsYoungerThan1 = "Dairy Bulls younger than 1 Year",
  DairyBullsOlderThan1 = "Dairy Bulls older than 1 Year",
}

export enum BaselineEmissionAttachmentType {
  IrrigationEnergy = "irrigationEnergy",
  LimingEnergy = "limingEnergy",
  SyntheticFerterlizer = "syntheticFerterlizer",
  SoilLandscape = "soilLandscape",
  CropInformation = "cropInformation",
  CropTillage = "cropTillage",
  CoverCrop = "coverCrop",
  Pasture = "pasture",
  Livestock = "livestock",
  CarbonBuilder = "carbonBuilder",
}

export enum IrrigationEnergyAttachmentType {
  QuantityOfFuel = "Quantity of Fuel",
  QuantityOfElectricity = "Quantity of Electricity",
}

export enum LimingEnergyAttachmentType {
  QuantityOfLimestone = "Quantity of Limestone",
  QuantityOfDolomite = "Quantity of Dolomite",
}

export enum SoilLandscapeAttachmentType {
  QuantityOfDieselFuel = "Quantity of Diesel Fuel",
}

export enum EmissionVariant {
  Baseline = "Baseline Emission",
  OnGoing = "Ongoing Emission",
}

export enum EmissionActivityStatus {
  InProgress = "In Progress",
  Completed = "Completed",
}

export enum CarbonBuilderProduct {
  BarleyCarbonBuilder = "Barley CarbonBuilder",
  CanolaCarbonBuilder = "Canola CarbonBuilder",
}

export enum RestrictedActivities {
  ClearedWoodyVegetation = "Clear woody vegetation",
  ThinningOfTheLand = "Thin the land",
  AdditionOfSoil = "Add or redistribute using mechanical means (including through clay delving, clay spreading or water ponding)",
  AddingSoilWithBiochar = "Add soil amendments containing biochar to soil within a CEA",
  AddingSoilWithCoal = "Add soil amendments containing coal to soil within a CEA",
  AddingRestrictedNonSyntheticFertilizer = "Add restricted non-synthetic fertiliser to soil within a CEA at a rate higher than 100kg of carbon per hectare per calendar year if the carbon content of the restricted non-synthetic fertiliser is known",
  ApplyingIrrigationMoreThanTheBaseline = "Apply irrigation to CEAs within a project area resulting in the annual level of irrigation for the project area exceeding 20% of the highest annual level of irrigation in the baseline period (disregarding new irrigation)",
  IDidNotConductAnyRestrictedActivities = "I did not conduct any restricted activities indicated above",
}

export const RestrictedActivitiesLongShort = {
  ClearedWoodyVegetation: {
    short: "Cleared woody vegetation",
    long: "Cleared woody vegetation",
  },
  ThinningOfTheLand: {
    short: "Thinning of the land",
    long: "Thinning of the land",
  },
  AdditionOfSoil: {
    short: "Addition or redistribution of soil",
    long: "Addition or redistribution of soil using mechanical means (including through clay delving, clay spreading or water ponding)",
  },
  AddingSoilWithBiochar: {
    short: "Adding soil amendments with biochar",
    long: "Adding soil amendments containing biochar to soil within a CEA",
  },
  AddingSoilWithCoal: {
    short: "Adding soil amendments with coal",
    long: "Adding soil amendments containing coal to soil within a CEA",
  },
  AddingRestrictedNonSyntheticFertilizer: {
    short: "Adding restricted non-synthetic fertilizer",
    long: "Adding restricted non-synthetic fertiliser to soil within a CEA at a rate higher than 100kg of carbon per hectare per calendar year if the carbon content of the restricted non-synthetic fertiliser is known",
  },
  ApplyingIrrigationMoreThanTheBaseline: {
    short: "Applying excess irrigation to CEAs",
    long: "Applying irrigation to CEAs within a project area resulting in the annual level of irrigation for the project area exceeding 20% of the highest annual level of irrigation in the baseline period (disregarding new irrigation)",
  },
  IDidNotConductAnyRestrictedActivities: {
    short: "No conducted restricted activities",
    long: "I did not conduct any restricted activities indicated above",
  },
};

export enum KYB_STATUS {
  Incomplete = "Incomplete",
  Pending = "Pending",
  Open = "Open",
  UnderReview = "Under Review",
  Approved = "Approved",
  Rejected = "Rejected",
}
