import { useParams } from "react-router-dom";
import useProjectBaselineActivityInfo from "../../../hooks/emission/useProjectBaselineActivityInfo";
import Loading from "../../../components/loading";
import Badge from "../../../components/badge";
import {
  AustralianState,
  EmissionActivityStatus,
  EmissionVariant,
  Month,
  Region,
} from "../../../constants";
import { useMemo, useState } from "react";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";
import useProject from "../../../hooks/useProject";
import IrrigationEnergyView from "./irrigation-energy";
// import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import LimingEnergyView from "./liming-energy";
import SyntheticFerterlizerView from "./synthetic-fertilizer";
import ResidueLandscapeView from "./residue-landscape";
import LivestockView from "./livestock";
import useProjectEmissionData from "../../../hooks/emission/useProjectEmissionData";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import Divider from "../../../components/divider";
import useEmissionActivityInfo from "../../../hooks/emission/useEmissionActivityInfo";
import formatDate from "../../../helpers/format-date";
import DashboardData from "./dashboard-data";
import ActivityInfo from "./activity-info";
import CarbonBuilder from "./carbon-builder";
import RestrictedActivities from "./restricted-activities";
import WarningContainer from "../../../components/warning-container";

export default function Emission() {
  const [page, setPage] = useState(0);
  const { projectId, emissionActivityId } = useParams();
  const { emissionActivityInfo } = useEmissionActivityInfo(
    projectId,
    emissionActivityId
  );

  const { project, loading: projectLoading } = useProject(projectId);
  const { projectCalculatedEmissionData } = useProjectCalculatedEmissionData(
    projectId,
    emissionActivityId
  );
  const {
    emissionActivityInfo: farmActivityInfo,
    loading: activityInfoLoading,
  } = useProjectBaselineActivityInfo(projectId, emissionActivityId);
  const { projectEmissionData } = useProjectEmissionData(
    projectId,
    emissionActivityId
  );
  const tabs = [
    "Irrigation",
    "Liming",
    "Synthetic Fertilizer",
    "Residue, and Soil Modification",
    "Livestock",
    emissionActivityInfo?.activityType === EmissionVariant.OnGoing && [
      "Carbon Builder",
      "Restricted Activities",
    ],
  ]
    .filter((tab) => tab)
    .flat();

  const period = useMemo(() => {
    if (!emissionActivityInfo) return undefined;
    return (
      formatDate(emissionActivityInfo.activityStartDate) +
      " - " +
      formatDate(emissionActivityInfo.activityEndDate)
    );
  }, [emissionActivityInfo]);

  const year = useMemo(() => {
    if (!emissionActivityInfo) return undefined;
    return new Date(
      emissionActivityInfo.activityStartDate ?? new Date()
    ).getFullYear();
  }, [emissionActivityInfo]);

  const RenderComponent = useMemo(() => {
    if (!project || !project.projectInfo) return null;
    switch (page) {
      case 0:
        return (
          <>
            {!farmActivityInfo?.irrigation &&
              projectEmissionData?.irrigationEnergy?.length !== 0 && (
                <WarningContainer>
                  You are seeing this because the grower initially selected that
                  they irrigate, filled in some information, but then selected
                  that they do not irrigate. Please check the grower's activity
                  information.
                </WarningContainer>
              )}
            <IrrigationEnergyView
              irrigationData={projectEmissionData?.irrigationEnergy}
              emissionData={projectCalculatedEmissionData ?? {}}
              farmInfo={{
                state: project.projectInfo?.address
                  ?.stateProvince as AustralianState,
                region: project.projectInfo?.address
                  ?.australianRegion as Region,
                carbonRegMonth: project.projectInfo?.carbonRegMonth,
                carbonRegYear: project.projectInfo?.carbonRegYear,
              }}
              variant={emissionActivityInfo?.activityType}
              period={period}
            />
          </>
        );
      case 1:
        return (
          <>
            {!farmActivityInfo?.limeApplication &&
              projectEmissionData?.limingEnergy?.length !== 0 && (
                <WarningContainer>
                  You are seeing this because the grower initially selected that
                  they applied lime, filled in some information, but then
                  selected that they do not lime. Please check the grower's
                  activity information.
                </WarningContainer>
              )}
            <LimingEnergyView
              limingData={projectEmissionData?.limingEnergy}
              emissionData={projectCalculatedEmissionData ?? {}}
              farmInfo={{
                state: project.projectInfo?.address
                  ?.stateProvince as AustralianState,
                region: project.projectInfo?.address
                  ?.australianRegion as Region,
                carbonRegMonth: project.projectInfo.carbonRegMonth,
                carbonRegYear: project.projectInfo.carbonRegYear,
              }}
              variant={emissionActivityInfo?.activityType}
              period={period}
            />
          </>
        );
      case 2:
        return (
          <>
            {!farmActivityInfo?.syntheticFertilizer &&
              projectEmissionData?.syntheticFertilizer?.length !== 0 && (
                <WarningContainer>
                  You are seeing this because the grower initially selected that
                  they applied sythetic fertilizer, filled in some information,
                  but then selected that they did not apply sythetic fertilizer.
                  Please check the grower's activity information.
                </WarningContainer>
              )}
            <SyntheticFerterlizerView
              syntheticFertilizerData={projectEmissionData?.syntheticFertilizer}
              emissionData={projectCalculatedEmissionData ?? {}}
              farmInfo={{
                state: project.projectInfo?.address
                  ?.stateProvince as AustralianState,
                region: project.projectInfo?.address
                  ?.australianRegion as Region,
                carbonRegMonth: project.projectInfo.carbonRegMonth,
                carbonRegYear: project.projectInfo.carbonRegYear,
              }}
              variant={emissionActivityInfo?.activityType}
              period={period}
            />
          </>
        );
      case 3:
        return (
          <>
            {(!farmActivityInfo?.soilLandscape ||
              !farmActivityInfo?.coverCrop ||
              !farmActivityInfo?.tillCrop ||
              !farmActivityInfo?.tillPasture ||
              !farmActivityInfo?.growCrop) &&
              (projectEmissionData?.soilLandscape?.length !== 0 ||
                projectEmissionData?.cropInformation?.length !== 0 ||
                projectEmissionData?.cropTillage?.length !== 0 ||
                projectEmissionData?.coverCrop?.length !== 0 ||
                projectEmissionData?.pastureCrop?.length !== 0) && (
                <WarningContainer>
                  You are seeing this because the grower initially selected that
                  they did one of the landscape modification activities, filled
                  in some information, but then selected that they do not do any
                  of the landscape modification activity. Please check the
                  grower's activity information.
                </WarningContainer>
              )}
            <ResidueLandscapeView
              activityYears={farmActivityInfo?.activityYears}
              soilLandscapeData={projectEmissionData?.soilLandscape}
              cropInformationData={projectEmissionData?.cropInformation}
              cropTillageData={projectEmissionData?.cropTillage}
              coverCropData={projectEmissionData?.coverCrop}
              pastureData={projectEmissionData?.pastureCrop}
              emissionData={projectCalculatedEmissionData ?? {}}
              farmInfo={{
                state: project.projectInfo?.address
                  ?.stateProvince as AustralianState,
                region: project.projectInfo?.address
                  ?.australianRegion as Region,
                carbonRegMonth: project.projectInfo.carbonRegMonth,
                carbonRegYear: project.projectInfo.carbonRegYear,
              }}
              variant={emissionActivityInfo?.activityType}
              period={period}
            />
          </>
        );
      case 4:
        return (
          <>
            {!farmActivityInfo?.livestock &&
              projectEmissionData?.livestock?.length !== 0 && (
                <WarningContainer>
                  You are seeing this because the grower initially selected that
                  they have livestock, filled in some information, but then
                  selected that they do not have livestock. Please check the
                  grower's activity information.
                </WarningContainer>
              )}
            <LivestockView
              livestockData={projectEmissionData?.livestock}
              emissionData={projectCalculatedEmissionData ?? {}}
              farmInfo={{
                state: project.projectInfo?.address
                  ?.stateProvince as AustralianState,
                region: project.projectInfo?.address
                  ?.australianRegion as Region,
                carbonRegMonth: project.projectInfo.carbonRegMonth,
                carbonRegYear: project.projectInfo.carbonRegYear,
              }}
              variant={emissionActivityInfo?.activityType}
              period={period}
            />
          </>
        );
      case 5:
        return (
          <CarbonBuilder
            carbonBuilderApplicationData={
              projectEmissionData?.carbonBuilderApplication
            }
            year={year}
          />
        );
      case 6:
        return (
          <RestrictedActivities
            resrictedActivityData={projectEmissionData?.restrictedActivity}
          />
        );
      default:
        return <div>No Data</div>;
    }
  }, [
    page,
    emissionActivityInfo,
    period,
    project,
    projectEmissionData,
    projectCalculatedEmissionData,
    farmActivityInfo,
    year,
  ]);

  return (
    <div className="flex flex-col w-full py-4 px-4">
      {projectLoading && <Loading />}
      {project && emissionActivityInfo ? (
        <>
          <div className="flex flex-col items-start gap-y-1">
            <h3 className="text-xl font-semibold leading-6 text-shark-200">
              {project.projectInfo?.name} - {emissionActivityInfo.activityType}
            </h3>
            <Badge
              variant={
                emissionActivityInfo.activityStatus ===
                EmissionActivityStatus.InProgress
                  ? "warning"
                  : "success"
              }
            >
              {emissionActivityInfo.activityStatus}
            </Badge>
            {emissionActivityInfo.activityType === EmissionVariant.Baseline ? (
              <p className="text-sm text-shark-200">
                {Month[(project?.projectInfo?.carbonRegMonth ?? 0) - 1]}{" "}
                {project?.projectInfo?.carbonRegYear}
              </p>
            ) : (
              <p className="text-sm text-apple-500 font-semibold">
                {formatDate(emissionActivityInfo.activityStartDate)}
                <span className="text-shark-200"> - </span>
                {formatDate(emissionActivityInfo.activityEndDate)}
              </p>
            )}
          </div>
          <Divider />

          <DashboardData
            projectCalculatedEmissionData={projectCalculatedEmissionData}
            emissionActivityInfo={emissionActivityInfo}
          />

          <div className="flex flex-row flex-1 w-full gap-4 mt-4 overflow-hidden">
            <div className="w-full">
              <div className="mb-2 border-b border-shark-500">
                <nav className="-mb-px flex gap-x-4 overflow-x-auto overflow-y-hidden ">
                  {tabs.map((tab, i) => (
                    <li
                      key={i}
                      className={`flex flex-row flex-nowrap gap-x-4 items-center w-auto font-semibold ${
                        page === i
                          ? "text-apple-400  border-b-[1.5px] border-apple-500"
                          : "border-transparent text-shark-200 hover:border-shark-400 hover:text-shark-400"
                      } pb-1 cursor-pointer text-sm  items-center hover:text-shark-300 hover:border-b hover:border-shark-400`}
                      onClick={() => setPage(i)}
                    >
                      {/* <ChevronDoubleRightIcon className="w-4 h-4 mr-1 shrink-0" /> */}
                      <p className="">{tab}</p>
                    </li>
                  ))}
                </nav>
              </div>
              <div className="bg-shark-950 border border-shark-700 rounded-lg p-4">
                {RenderComponent}
              </div>
            </div>
            <div className="w-4/12">
              <ActivityInfo
                farmActivityInfo={farmActivityInfo}
                activityInfoLoading={activityInfoLoading}
                emissionActivityInfo={emissionActivityInfo}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <EmptyPlaceholder>
            Emission Information is not available.
          </EmptyPlaceholder>
        </>
      )}
    </div>
  );
}
