import { useEffect, useState } from "react";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";
import { KYB_STATUS } from "../constants";

export default function useProjectStats() {
  const [projects, setProjects] = useState(0);
  const [totalGrowers, setTotalGrowers] = useState(0);
  const [kybInProgress, setKybInProgress] = useState(0);
  const [kybAwaitingReview, setKybAwaitingReview] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();

  const getProjectData = async () => {
    const response = await api.getAllProjects();
    if (response instanceof FetchError) {
      setError(response.message);
    } else {
      setProjects(response.projects.length);
    }
    setLoading(false);
  };

  const getGrowerData = async () => {
    const response = await api.getAllGrowers();
    if (response instanceof FetchError) {
      setError(response.message);
    } else {
      setTotalGrowers(response.growers.length);
    }
    setLoading(false);
  };

  const getKybData = async () => {
    const response = await api.getAllKYBs();
    if (response instanceof FetchError) {
      setError(response.message);
    } else {
      setKybInProgress(
        response.kybs.filter(
          (k) =>
            k.kybStatus?.toUpperCase() === KYB_STATUS.Open.toUpperCase() ||
            k.kybStatus?.toUpperCase() === KYB_STATUS.Incomplete.toUpperCase()
        ).length
      );
      setKybAwaitingReview(
        response.kybs.filter(
          (k) =>
            k.kybStatus?.toUpperCase() ===
              KYB_STATUS.UnderReview.toUpperCase() ||
            k.kybStatus?.toUpperCase() === KYB_STATUS.Pending.toUpperCase()
        ).length
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjectData();
    getGrowerData();
    getKybData();
  }, []);

  return {
    data: [
      { name: "Projects", stat: projects },
      { name: "Growers", stat: totalGrowers },
      { name: "KYB in progress", stat: kybInProgress },
      { name: "KYB awaiting review", stat: kybAwaitingReview },
    ],
    loading,
    error,
  };
}
