import { useEffect, useMemo, useState } from "react";
import { User } from "../../types";
import { FetchError } from "../../lib/fetcher";
import { api } from "../../api";

import TextInput from "../../components/text-input";
import Loading from "../../components/loading";
import SelectInput from "../../components/select-input";
import { RegularUserRoles, UserRole } from "../../constants";
import useMe from "../../hooks/useMe";
import { useLocation } from "react-router-dom";

export default function NewUser() {
  const [newUser, setNewUser] =
    useState<Partial<User & { password: string }>>();
  const [loading, setLoading] = useState(false);
  const me = useMe();
  const { search } = useLocation();

  const from = useMemo(() => new URLSearchParams(search), [search]).get("from");

  useEffect(() => {
    if (!from) return;
    setNewUser({
      role:
        from === "users"
          ? RegularUserRoles.GROWER
          : from === "admin-users"
          ? RegularUserRoles.ADMIN
          : "",
    });
  }, [from]);

  async function handleCreateUser() {
    if (!newUser) {
      return;
    }

    if (
      !newUser.username ||
      !newUser.password ||
      !newUser.firstName ||
      !newUser.lastName ||
      !newUser.role
    ) {
      return;
    }
    setLoading(true);

    const response = await api.postUser(newUser);
    if (response instanceof FetchError) {
      setLoading(false);
      console.log("Error creating project" + response.message);
      return;
    }

    setLoading(false);
    window.location.href = from ? `/${from}` : "/users";
  }

  return (
    <div className="flex w-full flex-col px-4 sm:px-8">
      <header className="flex flex-row justify-between items-center p-4">
        <h1 className="text-2xl font-semibold">New User</h1>
      </header>
      <main className="flex flex-col flex-1 mb-8 px-2 sm:px-4">
        {loading || !me ? (
          <Loading />
        ) : (
          <>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
              <div className="sm:col-span-6">
                <TextInput
                  label="First Name"
                  type="text"
                  value={newUser?.firstName ?? ""}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      firstName: e.target.value,
                    })
                  }
                  error={!newUser?.firstName ? 1 : 0}
                />
              </div>
              <div className="sm:col-span-6">
                <TextInput
                  label="Last Name"
                  type="text"
                  value={newUser?.lastName ?? ""}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      lastName: e.target.value,
                    })
                  }
                  error={!newUser?.lastName ? 1 : 0}
                />
              </div>
              <div className="sm:col-span-6">
                <TextInput
                  label="Email"
                  type="email"
                  value={newUser?.username ?? ""}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      username: e.target.value,
                    })
                  }
                  error={!newUser?.username ? 1 : 0}
                />
              </div>

              <div className="sm:col-span-6">
                <TextInput
                  label="Password"
                  type="password"
                  value={newUser?.password ?? ""}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      password: e.target.value,
                    })
                  }
                  error={!newUser?.password ? 1 : 0}
                />
              </div>

              {me.role === "admin" ? (
                <div className="sm:col-span-6">
                  <SelectInput
                    label="Role"
                    value={newUser?.role ?? ""}
                    onChange={(e) => {
                      setNewUser({
                        ...newUser,
                        role: e.target.value as UserRole,
                      });
                    }}
                  >
                    <option></option>
                    {Object.entries(RegularUserRoles).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              ) : null}

              {me.role === "super_admin" ? (
                <div className="sm:col-span-6">
                  <SelectInput
                    label="Role"
                    value={newUser?.role ?? ""}
                    onChange={(e) => {
                      setNewUser({
                        ...newUser,
                        role: e.target.value as UserRole,
                      });
                    }}
                  >
                    <option></option>
                    {Object.entries(UserRole).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              ) : null}
            </div>

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-xl bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                onClick={handleCreateUser}
              >
                Create User
              </button>
            </div>
          </>
        )}
      </main>
    </div>
  );
}
