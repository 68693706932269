import { useParams } from "react-router-dom";
import Loading from "../../components/loading";
import useAllKybPeople from "../../hooks/useAllKybPeople";
import useKYBData from "../../hooks/useKYBData";
import useKYB from "../../hooks/useKyb";
import KYBDetailsView from "./kyb-details";
import Badge from "../../components/badge";

export default function KYBView() {
  const { kybId } = useParams();
  const { allKybPeople, loading: allKybPeopleLoading } = useAllKybPeople(
    +kybId!
  );
  const { kybData, loading: kybDataLoading } = useKYBData(+kybId!);
  const { kyb } = useKYB(+kybId!);

  return (
    <div className={`flex flex-1 m-6 w-full`}>
      {allKybPeopleLoading || !kyb || kybDataLoading || !kybId ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between items-center w-full">
              <div className="flex flex-col items-start">
                <p className="text-3xl font-semibold text-shark-100">
                  Know Your Business {kyb?.kybYear}
                </p>
                <div className="flex">
                  <Badge
                    variant={
                      kyb.kybStatus?.toLowerCase() === "open"
                        ? "danger"
                        : "warning"
                    }
                  >
                    {kyb.kybStatus?.toLowerCase() === "under review"
                      ? "TO BE REVIEWED"
                      : kyb.kybStatus?.toUpperCase()}
                  </Badge>
                </div>
              </div>
            </div>
            <div className="flex flex-1 w-full">
              <KYBDetailsView
                data={kybData}
                kybPeople={allKybPeople}
                loading={kybDataLoading}
                kybId={+kybId!}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
