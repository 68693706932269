interface DashboardDataViewProps {
  title: string;
  value?: number;
}
export default function DashboardDataView({
  title,
  value,
}: DashboardDataViewProps) {
  return (
    <div className="col-span-1 bg-shark-900 border border-shark-600 max-h-28 rounded-xl p-4">
      <p className="text-[9px] font-medium text-apple-400 overflow-clip overflow-y-clip">
        {title}
      </p>
      <p className="text-xl sm:text-3xl xl:text-4xl text-shark-100 ">
        {value?.toFixed(3) ?? "N/A"}
      </p>
    </div>
  );
}
