import AddressView from "../../components/address-view";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import EmptyPlaceholder from "../../components/empty-placeholder";
import { Country } from "../../constants";
import { Address, BusinessInfo } from "../../types";

interface GrowerDetailsProps {
  data?: BusinessInfo;
}

export default function GrowerDetails({ data }: GrowerDetailsProps) {
  return (
    <div className="flex flex-1 flex-col w-full">
      {!data ? (
        <EmptyPlaceholder>No Grower Details</EmptyPlaceholder>
      ) : (
        <DescriptionContainer>
          <DescriptionItem title="Full Legal Name" variant="alternate">
            {data.legalName ?? ""}
          </DescriptionItem>
          <DescriptionItem title="Other name/s (if any)">
            {data.otherName ?? ""}
          </DescriptionItem>
          <DescriptionItem
            title={data.abnAcnType ?? "ABN/ACN"}
            variant="alternate"
          >
            {data.abnAcnNumber ?? ""}
          </DescriptionItem>
          <DescriptionItem title="Place of Incorporation / Establishment">
            {data.placeOfIncorporation === Country.Other
              ? data.placeOfIncorporationOther
              : data.placeOfIncorporation}
          </DescriptionItem>
          <DescriptionItem
            title="Date of Incorporation / Establishment"
            variant="alternate"
          >
            {data.dateOfIncorporation ?? ""}
          </DescriptionItem>
          <DescriptionItem title="Nature of Business">
            {data.natureOfBusiness ?? ""}
          </DescriptionItem>
          <DescriptionItem title="Principal Office Address" variant="alternate">
            <AddressView
              data={data.principalOfficeAddress ?? ({} as Address)}
            />
          </DescriptionItem>
          <DescriptionItem title="Registered Office Address">
            <AddressView
              data={data.registeredOfficeAddress ?? ({} as Address)}
            />
          </DescriptionItem>
          <DescriptionItem title="Other Address" variant="alternate">
            <AddressView data={data.otherAddresses ?? ({} as Address)} />
          </DescriptionItem>
        </DescriptionContainer>
      )}
    </div>
  );
}
