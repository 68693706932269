import { useState } from "react";
import { Address, ProjectInfo } from "../../types";
import { FetchError } from "../../lib/fetcher";
import { api } from "../../api";
import AddressComponent from "../../components/address";
import SelectInput from "../../components/select-input";
import TextArea from "../../components/text-area";
import TextInput from "../../components/text-input";
import { Month } from "../../constants";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";

export default function NewProject() {
  const [newProject, setNewProject] = useState<Partial<ProjectInfo>>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleCreateProject() {
    if (!newProject) {
      return;
    }

    if (
      !newProject.name ||
      !newProject.carbonRegMonth ||
      !newProject.carbonRegYear ||
      !newProject.address ||
      !newProject.address.stateProvince
    ) {
      return;
    }
    setLoading(true);

    const response = await api.postProject({
      projectInfo: newProject,
    });
    if (response instanceof FetchError) {
      setLoading(false);
      console.log("Error creating project" + response.message);
      return;
    }

    setLoading(false);
    navigate("/projects");
  }

  return (
    <div className="flex w-full flex-col px-4 sm:px-8">
      <header className="flex flex-row justify-between items-center p-4">
        <h1 className="text-2xl font-semibold">New Project</h1>
      </header>
      <main className="flex flex-col flex-1 mb-8 px-2 sm:px-4">
        {loading ? (
          <Loading />
        ) : (
          <>
            {/* <h2 className="text-lg font-semibold">Project Information</h2> */}
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
              <div className="col-span-full">
                <TextInput
                  label="Project Name"
                  type="text"
                  value={newProject?.name ?? ""}
                  onChange={(e) =>
                    setNewProject({
                      ...newProject,
                      name: e.target.value,
                    })
                  }
                  error={!newProject?.name ? 1 : 0}
                />
              </div>

              <div className="w-full col-span-full">
                <label className="block text-sm font-medium leading-6 text-shark-50 sm:col-span-full">
                  Please select this carbon project registration date
                </label>
                <div className="w-full flex flex-row">
                  <div className="w-1/4 pr-2">
                    <SelectInput
                      label="Month"
                      value={newProject?.carbonRegMonth ?? ""}
                      onChange={(e) =>
                        setNewProject({
                          ...newProject,
                          carbonRegMonth: e.target.value.length
                            ? +e.target.value
                            : undefined,
                        })
                      }
                      error={!newProject?.carbonRegMonth ? 1 : undefined}
                    >
                      <option value={""}></option>
                      {Month.map((value, i) => (
                        <option key={i} value={i + 1}>
                          {value}
                        </option>
                      ))}
                    </SelectInput>
                  </div>
                  <div className="w-1/5 pl-2">
                    <SelectInput
                      label="Year"
                      value={newProject?.carbonRegYear ?? ""}
                      onChange={(e) =>
                        setNewProject({
                          ...newProject,
                          carbonRegYear: e.target.value.length
                            ? +e.target.value
                            : undefined,
                        })
                      }
                      error={!newProject?.carbonRegYear ? 1 : undefined}
                    >
                      <option value={""}></option>
                      {Array.from(
                        { length: 1 },
                        (_, i) => new Date().getFullYear() + i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </SelectInput>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-2 sm:px-4 ">
              <div className="pt-4 border-t border-shark-600" />
              <h2 className="text-lg font-semibold">Project Address</h2>
              <AddressComponent
                address={(newProject?.address ?? {}) as Address}
                setAddress={(address) =>
                  setNewProject({
                    ...newProject,
                    address,
                  })
                }
                required
              />
              <TextArea
                label="Description"
                noOfRows={3}
                value={newProject?.description ?? ""}
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-xl bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                onClick={handleCreateProject}
              >
                Create Project
              </button>
            </div>
          </>
        )}
      </main>
    </div>
  );
}
