import { EmissionVariant } from "../constants";

export default function RenderAverageEmission(
  emissionNumber: number,
  variant?: EmissionVariant
) {
  return (
    <tfoot className="divide-y divide-shark-600 bg-shark-700">
      <tr className="divide-x divide-shark-600">
        <td className="whitespace-wrap py-2 pl-2 pr-2 text-base font-bold text-shark-100 text-center px-8 ">
          {variant === EmissionVariant.OnGoing
            ? "Average Period Emission"
            : "Average Annual Emission"}
        </td>
        <td
          className="whitespace-nowrap py-4 pl-4 pr-4 sm:pr-0 text-center"
          colSpan={100}
        >
          <span className="text-base text-apple-400 font-extrabold">
            {emissionNumber.toFixed(3)}
          </span>
          <span className="text-sm text-shark-200">
            <span className="font-bold">
              {" "}
              tonnes of{" "}
              <span>
                CO<sub>2</sub>-e
              </span>{" "}
              per year.
            </span>
          </span>
        </td>
      </tr>
    </tfoot>
  );
}
