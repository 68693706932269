import { config } from "./config";
import Fetcher from "./lib/fetcher";
import {
  ActivityInfo,
  EmissionAttachment,
  CalculatedBaselineEmission,
  EmissionData,
  EmissionActivityInfo,
  EntityPerson,
  EntityPersonEmailChange,
  Grower,
  GrowerPEP,
  GrowerSanctionScreening,
  KYB,
  KYC,
  KybNode,
  Project,
  User,
} from "./types";

const API_URL = config.api ?? "";

export const api = new (class {
  private fetcher = new Fetcher({
    baseUrl: API_URL,
    getAccessToken: () => localStorage.getItem("token"),
  });

  public login(username: string) {
    const loginFetcher = new Fetcher({
      baseUrl: API_URL,
    });

    return loginFetcher.fetch<{ user: User }>("/login", {
      method: "POST",
      body: {
        username,
      },
    });
  }

  public postProject(project: Partial<Project>) {
    return this.fetcher.fetch<{ project: Project }>("/api/projects", {
      method: "POST",
      body: project,
    });
  }

  public updateUser(user: Partial<User>, userId: number) {
    return this.fetcher.fetch<{ user: User }>(`/api/users/${userId}`, {
      method: "PATCH",
      body: user,
    });
  }

  public getUser(userId: number) {
    return this.fetcher.fetch<{ user: User }>(`/api/users/${userId}`, {
      method: "GET",
    });
  }

  public postUser(user: Partial<User & { password: string }>) {
    return this.fetcher.fetch<{ user: User }>("/users", {
      method: "POST",
      body: user,
    });
  }

  public getAllProjects() {
    return this.fetcher.fetch<{ projects: Project[] }>("/api/all-projects", {
      method: "GET",
    });
  }

  public getProject(id: number) {
    return this.fetcher.fetch<{ project: Project }>(`/api/projects/${id}`, {
      method: "GET",
    });
  }

  public getAllGrowers() {
    return this.fetcher.fetch<{ growers: Grower[] }>("/api/all-growers", {
      method: "GET",
    });
  }

  public getGrower(id: number) {
    return this.fetcher.fetch<{ grower: Grower }>(`/api/growers/${id}`, {
      method: "GET",
    });
  }

  public postGrower(grower: Partial<Grower>) {
    return this.fetcher.fetch<{ grower: Grower }>("/api/growers", {
      method: "POST",
      body: grower,
    });
  }

  public postProjectGrower(projectId: number, grower: Grower) {
    return this.fetcher.fetch<{ projectGrower: any }>(
      `/api/projects/${projectId}/attach-grower`,
      {
        method: "POST",
        body: grower,
      }
    );
  }

  public getProjectGrowers(projectId: number) {
    return this.fetcher.fetch<{ growers: Grower[] }>(
      `/api/projects/${projectId}/growers`,
      {
        method: "GET",
      }
    );
  }

  public getAllUsers() {
    return this.fetcher.fetch<{ users: User[] }>("/api/all-users", {
      method: "GET",
    });
  }

  public getAllAdminUsers() {
    return this.fetcher.fetch<{ users: User[] }>("/api/all-admin-users", {
      method: "GET",
    });
  }

  public getGrowerUsers(growerId: number) {
    return this.fetcher.fetch<{ users: User[] }>(
      `/api/growers/${growerId}/users`,
      {
        method: "GET",
      }
    );
  }

  public postGrowerUser(growerId: number, user: User) {
    return this.fetcher.fetch<{ growerUser: any }>(
      `/api/growers/${growerId}/attach-user`,
      {
        method: "POST",
        body: user,
      }
    );
  }

  public getAllKYBs() {
    return this.fetcher.fetch<{ kybs: KYB[] }>(`/api/kyb/`, {
      method: "GET",
    });
  }

  public getGrowerKYBs(userId: number) {
    return this.fetcher.fetch<{ kybs: KYB[] }>(`/api/growers/${userId}/kybs`, {
      method: "GET",
    });
  }

  public postGrowerKYB(growerId: number, kyb: KYB) {
    return this.fetcher.fetch<{ kyb: KYB }>(`/api/growers/${growerId}/kybs`, {
      method: "POST",
      body: kyb,
    });
  }

  public getallKybPeopleByKYB(kybId: number) {
    return this.fetcher.fetch<{ entityPeople: EntityPerson[] }>(
      `/api/kyb/${kybId}/all-people`,
      {
        method: "GET",
      }
    );
  }

  public postKYCInvite(kybId: number, kycPeople: EntityPerson[]) {
    return this.fetcher.fetch<{ kycInvites: KYC[] }>(`/api/kyb/${kybId}/kyc`, {
      method: "POST",
      body: kycPeople,
    });
  }

  public sendKYBInvite(kybId: number) {
    return this.fetcher.fetch(`/api/kyb/${kybId}/invite-email`, {
      method: "POST",
    });
  }

  public resendKYCInvite(kycPerson: EntityPerson) {
    return this.fetcher.fetch(
      `/api/kyb/${kycPerson.kybId}/kyc/${kycPerson.kycId}/resend-invite-email`,
      {
        method: "POST",
        body: kycPerson,
      }
    );
  }

  public reOpenKYB(kybId: number) {
    return this.fetcher.fetch(`/api/kyb/${kybId}/reopen`, {
      method: "POST",
    });
  }

  public getAllKYBNodeData(kybId: number) {
    return this.fetcher.fetch<{ kybNodes: KybNode[] }>(
      `/api/kyb/${kybId}/nodes`,
      {
        method: "GET",
      }
    );
  }

  public getKYB(kybId: number) {
    return this.fetcher.fetch<{ kyb: KYB }>(`/api/kyb/${kybId}`, {
      method: "GET",
    });
  }

  public getSanctionDeclaration(kybId: number) {
    return this.fetcher.fetch<{
      growerSanctionScreening: GrowerSanctionScreening;
    }>(`/api/kyb/${kybId}/sanction-declaration`, {
      method: "GET",
    });
  }

  public getPEP(kybId: number) {
    return this.fetcher.fetch<{ growerPEP: GrowerPEP }>(
      `/api/kyb/${kybId}/grower-pep`,
      {
        method: "GET",
      }
    );
  }

  public getKYBConsent(kybId: number) {
    return this.fetcher.fetch<{ consent: any }>(`/api/kyb/${kybId}/consent`, {
      method: "GET",
    });
  }

  public getEmissionAttachment(
    projectId: number,
    emissionActivityId: number,
    attachmentKind: string
  ) {
    return this.fetcher.fetch<{ attachments: EmissionAttachment[] }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-attachment/${attachmentKind}`,
      {
        method: "GET",
      }
    );
  }

  public getActivityInfo(projectId: number, emissionActivityId: number) {
    return this.fetcher.fetch<{ emissionActivityInfo: ActivityInfo }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/activity-info`,
      {
        method: "GET",
      }
    );
  }

  public getProjectEmissionData(projectId: number, emissionActivityId: number) {
    return this.fetcher.fetch<{ projectEmissionData: EmissionData }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-data`,
      {
        method: "GET",
      }
    );
  }

  public getSummaryData(projectId: number, emissionActivityId: number) {
    return this.fetcher.fetch<{
      summaryEmissionData: CalculatedBaselineEmission;
    }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/summary-emission-data`,
      {
        method: "GET",
      }
    );
  }

  public postEmissionActivityInstance(
    projectId: number,
    emissionActivity: EmissionActivityInfo
  ) {
    return this.fetcher.fetch<{ emissionActivity: EmissionActivityInfo }>(
      `/api/projects/${projectId}/emission-activity`,
      {
        method: "POST",
        body: emissionActivity,
      }
    );
  }

  public getEmissionActivityInstances(projectId: number) {
    return this.fetcher.fetch<{ emissionActivity: EmissionActivityInfo[] }>(
      `/api/projects/${projectId}/emission-activity`,
      {
        method: "GET",
      }
    );
  }

  public getEmissionActivityInfo(
    projectId: number,
    emissionActivityId: number
  ) {
    return this.fetcher.fetch<{ emissionActivity: EmissionActivityInfo }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}`,
      {
        method: "GET",
      }
    );
  }

  public patchEntityPersonEmail(
    entityPersonId: number,
    emailChangeData: EntityPersonEmailChange
  ) {
    return this.fetcher.fetch<{ changeResponse: EntityPersonEmailChange }>(
      `/api/entity-person/${entityPersonId}/email-change`,
      {
        method: "PATCH",
        body: emailChangeData,
      }
    );
  }

  public getAllEmailChanges(entityPersonId: number) {
    return this.fetcher.fetch<{ emailChanges: EntityPersonEmailChange[] }>(
      `/api/entity-person/${entityPersonId}/all-email-changes`,
      {
        method: "GET",
      }
    );
  }

  public getEmissionAttachmentUrlById(
    projectId: number,
    emissionActivityId: number,
    attachmentId: number
  ) {
    return this.fetcher.fetch<{ url: string }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-attachment-url/${attachmentId}`,
      {
        method: "GET",
      }
    );
  }
})();
