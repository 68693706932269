import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string | React.ReactNode;
  error?: number;
  noOfRows?: number;
}

export default function TextArea({ label, noOfRows, ...props }: Props) {
  return (
    <div className="my-2">
      <label className="block text-sm font-medium leading-6 text-shark-200">
        {label}
      </label>
      <div className="mt-1">
        <div className="relative rounded-lg shadow-sm bg-transparent">
          <textarea
            rows={noOfRows}
            {...props}
            className={`block w-full
            } rounded-lg border-0 py-1.5 px-3 text-shark-50 ring-1 ring-inset ${
              props.error === 1 ? "ring-red-500" : "ring-shark-500"
            } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-apple-500 text-base sm:leading-6 bg-shark-800`}
          />
          {props.error === 1 ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
        {props.error ? (
          <p className="mt-2 text-sm text-red-400" id="email-error">
            This field is required!
          </p>
        ) : null}
      </div>
    </div>
  );
}
