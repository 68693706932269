import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Loading from "../components/loading";
import { Link } from "react-router-dom";
import Button from "../components/button";
import Badge from "../components/badge";
import useAllAdminUsers from "../hooks/useAllAdminUsers";
import { useMemo, useState } from "react";
import { User } from "../types";

export default function AdminUsers() {
  const { allUsers, loading } = useAllAdminUsers();
  const [search, setSearch] = useState("");

  const sortByName = (a: User, b: User) => {
    const lastNameSort = a.lastName.localeCompare(b.lastName);
    if (lastNameSort === 0) {
      return a.firstName.localeCompare(b.firstName);
    }
    return lastNameSort;
  };

  const filteredUsers = useMemo(() => {
    if (!allUsers) {
      return [];
    }
    return allUsers
      .filter((user) => {
        return (
          user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          user.lastName.toLowerCase().includes(search.toLowerCase()) ||
          user.username.toLowerCase().includes(search.toLowerCase())
        );
      })
      .sort(sortByName);
  }, [allUsers, search]);

  return (
    <div className="flex w-full flex-col overflow-y-hidden">
      <header className="flex flex-row justify-between items-center shadow-lg p-4">
        <h1 className="text-2xl font-semibold">Admin Users</h1>
        <div className="flex flex-row space-x-2">
          <Link to={"/users/new?from=admin-users"}>
            <Button>Create User</Button>
          </Link>
        </div>
      </header>
      <main className="flex flex-col flex-1 px-8  bg-[#202125] h-full overflow-y-hidden">
        <div className="flex flex-col w-full">
          <div className="relative mt-2 rounded-full shadow-sm w-full bg-transparent">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0  h-full w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full bg-transparent rounded-full border-0 py-1.5 pl-10 text-gray-100 ring-1 ring-inset ring-[#5f6368] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-apple-600 text-base sm:leading-6"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col mt-4 w-full">
          {loading || !allUsers ? (
            <Loading />
          ) : (
            <div className="flex flex-col ">
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user, i) => (
                  <div
                    // to={``}
                    key={i}
                    className="border-b border-shark-600 py-3"
                  >
                    <div className="flex flex-row justify-between items-center gap-x-4 ">
                      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-apple-200">
                        <span className="text-xs font-medium leading-none text-apple-800">
                          {user?.firstName.charAt(0).toUpperCase()}
                        </span>
                      </span>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-white">
                          {user?.firstName} {user?.lastName}
                        </p>
                        <p className="text-xs leading-6 text-white">
                          {user?.username}
                        </p>
                        <div className="flex">
                          <Badge variant="disabled">
                            {user?.role?.toUpperCase()}
                          </Badge>
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2">
                        <Link to={`/users/${user.id}/edit`}>
                          <Button>Edit</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No User</p>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
