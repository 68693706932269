import Badge from "../../components/badge";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import { KYBConsent } from "../../types";

interface ConsentProps {
  data?: KYBConsent;
}

export default function ConsentView({ data }: ConsentProps) {
  return (
    <div className="flex flex-col w-full p-4">
      <p className="text-2xl font-semibold text-shark-100 pb-3 ">
        Grower Consent
      </p>
      {!data ? (
        <p>No Consent Data</p>
      ) : (
        <div className="flex flex-col">
          <DescriptionContainer>
            <DescriptionItem title="Business Name" variant="alternate">
              {data.businessName}
            </DescriptionItem>
            <DescriptionItem title="Agree to Consent">
              <div className="flex">
                {data.agreeConsent ? (
                  <Badge variant="success">Yes</Badge>
                ) : (
                  <Badge>No</Badge>
                )}
              </div>
            </DescriptionItem>
            <DescriptionItem title="Signed By" variant="alternate">
              <p>Name: {data.signedBy?.legalName ?? ""}</p>
              <p>Position: {data.signedBy?.position ?? ""}</p>
            </DescriptionItem>
            <DescriptionItem title="Signed On">{data.signedOn}</DescriptionItem>
            <DescriptionItem title="Signature">
              <img
                src={data.consentSig ?? ""}
                alt="signature"
                className="w-1/2 p-2 rounded-lg items-center bg-white"
              />
            </DescriptionItem>
          </DescriptionContainer>
        </div>
      )}
    </div>
  );
}
