import { useEffect, useState } from "react";
import { EntityPerson } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";

export default function useAllKybPeople(kybId?: number) {
  const [allKybPeople, setallKybPeople] = useState<EntityPerson[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function getProject() {
      setLoading(true);
      if (!kybId) {
        setLoading(false);
        return;
      }
      const response = await api.getallKybPeopleByKYB(+kybId);
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setallKybPeople(response.entityPeople);
      setLoading(false);
    }

    getProject(); // remove the return statement
  }, [kybId]);

  return { allKybPeople, loading, error };
}
