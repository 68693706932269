import Badge from "../../components/badge";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import { GrowerSanctionScreening } from "../../types";

interface SanctionViewProps {
  data?: GrowerSanctionScreening;
}

export default function SanctionDeclarationView({ data }: SanctionViewProps) {
  return (
    <div className="w-full p-4">
      <p className="text-2xl font-semibold text-shark-100 pb-3 ">
        Sanction Declaration
      </p>
      <p className="text-base text-apple-400 font-semibold">
        Sanction Declaration Details
      </p>
      {!data ? (
        <p>No Sanction Declaration Data</p>
      ) : (
        <div className=" bg-shark-700 my-2 p-4 rounded-lg">
          <DescriptionContainer>
            <DescriptionItem title="Indirect Revenue" variant="alternate">
              {data.indirectRevenue ? (
                <div className="flex">
                  <Badge>Yes</Badge>
                </div>
              ) : (
                <div className="flex">
                  <Badge>No</Badge>
                </div>
              )}
            </DescriptionItem>
            <DescriptionItem title="Planned Business Activities">
              {data.plannedBusinessActivities ? (
                <div className="flex">
                  <Badge>Yes</Badge>
                </div>
              ) : (
                <div className="flex">
                  <Badge>No</Badge>
                </div>
              )}
            </DescriptionItem>
            <DescriptionItem title="Sanction Details">
              {data.sanctionDetails ?? ""}
            </DescriptionItem>
            <DescriptionItem title="Agree Sanction">
              {data.agreeSanction ? (
                <div className="flex">
                  <Badge>Yes</Badge>
                </div>
              ) : (
                <div className="flex">
                  <Badge>No</Badge>
                </div>
              )}
            </DescriptionItem>
            <DescriptionItem title="Agreed By">
              <p>Name: {data.agreedBy?.legalName ?? ""}</p>
              <p>Position: {data.agreedBy?.position ?? ""}</p>
            </DescriptionItem>
            <DescriptionItem title="Agreed On">
              {data.agreedOn ?? ""}
            </DescriptionItem>
          </DescriptionContainer>
        </div>
      )}
    </div>
  );
}
