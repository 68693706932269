import Badge from "./badge";

interface Props {
  title: string;
  variant?: "primary" | "alternate";
  children: React.ReactNode;
}

export default function DescriptionItemYesNo({
  title,
  children,
  variant,
}: Props) {
  return (
    <div
      className={`${
        variant === "alternate" ? "bg-shark-800" : "bg-shark-900"
      } px-4 py-1.5 xl:grid xl:grid-cols-2 xl:gap-2 xl:px-3 items-center`}
    >
      <dt className="text-xxs font-medium leading-6 text-shark-100">{title}</dt>
      <dd className="mt-1 text-sm leading-6 text-shark-300 xl:col-span-1 xl:mt-0 break-words">
        <div className="flex">
          {children === true ? (
            <Badge variant="success">Yes</Badge>
          ) : children === false ? (
            <Badge variant="danger">No</Badge>
          ) : (
            <Badge variant="disabled">Unknown</Badge>
          )}
        </div>
      </dd>
    </div>
  );
}
