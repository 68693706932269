import {
  FolderIcon,
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

interface MenuItemsProps {
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

export default function MenuItems({ setSidebarOpen }: MenuItemsProps) {
  const navigation = [
    { name: "Home", href: "/", icon: HomeIcon },
    { name: "Projects", href: "/projects", icon: FolderIcon },
    { name: "Growers", href: "/growers", icon: UsersIcon },
    { name: "Grower Users", href: "/users", icon: UserGroupIcon },
    { name: "Admin Users", href: "/admin-users", icon: WrenchIcon },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const location = useLocation().pathname.split("/")[1];

  return (
    <nav className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col py-2">
        {navigation.map((item, i) => (
          <Link
            key={i}
            to={item.href}
            onClick={() => setSidebarOpen(false)}
            className={classNames(
              item.href.split("/")[1].toLowerCase() === location
                ? "bg-apple-500 text-white"
                : "text-gray-400 hover:text-white hover:bg-apple-400",
              `group flex flex-col items-center py-5 text-xxs font-semibold ${
                i < navigation.length - 1 ? "border-b border-shark-600" : ""
              }`
            )}
          >
            <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />
            <span className="break-words text-[9px] text-clip">
              {item.name}
            </span>
          </Link>
        ))}
      </div>
    </nav>
  );
}
