import { Address } from "../types";

interface Props {
  data?: Address;
}

export default function AddressView({ data }: Props) {
  return (
    <div className="w-full ">
      {!data ? (
        <p>No Address</p>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div
              className={`px-2 pb-2 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-1`}
            >
              <p className="text-sm font-medium leading-6 text-shark-200">
                Street Address
              </p>
              <p className="mt-1 text-sm leading-6 text-shark-300 sm:col-span-2 sm:mt-0">
                {data.streetAddress ?? ""}
              </p>
            </div>
            <div
              className={`px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-1`}
            >
              <p className="text-sm font-medium leading-6 text-shark-200">
                City
              </p>
              <p className="mt-1 text-sm leading-6 text-shark-300 sm:col-span-2 sm:mt-0">
                {data.city ?? ""}
              </p>
            </div>
            <div
              className={`px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-1`}
            >
              <p className="text-sm font-medium leading-6 text-shark-200">
                State/Province
              </p>
              <p className="mt-1 text-sm leading-6 text-shark-300 sm:col-span-2 sm:mt-0">
                {data.stateProvince ?? ""}
              </p>
            </div>
            <div
              className={`px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-1`}
            >
              <p className="text-sm font-medium leading-6 text-shark-200">
                Post/Zip/Postal Code
              </p>
              <p className="mt-1 text-sm leading-6 text-shark-300 sm:col-span-2 sm:mt-0">
                {data.postalCode ?? ""}
              </p>
            </div>
            <div
              className={`px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-1`}
            >
              <p className="text-sm font-medium leading-6 text-shark-200">
                Country
              </p>
              <p className="mt-1 text-sm leading-6 text-shark-300 sm:col-span-2 sm:mt-0">
                {data.country ?? ""}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
