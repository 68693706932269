import { useState } from "react";
import useAuth from "./hooks/useAuth";
import { AuthContext } from "./AuthContext";
import Loading from "./components/loading";
import Login from "./views/login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SideBar from "./components/sidebar";
import Header from "./components/header";
import Home from "./views/home";
import Growers from "./views/growers";
import Projects from "./views/projects";
import Project from "./views/project/project";
import Grower from "./views/grower/grower";
import NewProject from "./views/project/new-project";
import NewGrower from "./views/grower/new-grower";
import Users from "./views/users";
import NewUser from "./views/user/new-user";
import Logout from "./views/logout";
import KYBView from "./views/kyb/kyb";
import EditUser from "./views/user/edit-user";
import AdminUsers from "./views/admin-users";
import ProjectEmissionActivity from "./views/project/emission/emission";
import { UserRole } from "./constants";
import PageNotFound from "./components/403-error";

function App() {
  const { user, loading } = useAuth();
  const [open, setOpen] = useState(false);
  return (
    <div className="flex min-h-full flex-1 w-full">
      <AuthContext.Provider
        value={{
          user: null,
          setUser(user) {
            this.setUser(user);
          },
        }}
      >
        <BrowserRouter>
          {loading ? (
            <Loading />
          ) : user ? (
            <div className="flex flex-1 w-full flex-row">
              <div className="lg:w-24 w-0 border-r border-[#5f6368] bg-shark-800">
                <SideBar
                  sidebarOpen={open}
                  setSidebarOpen={setOpen}
                  user={user}
                />
              </div>
              <div className="flex flex-col flex-1 bg-shark-950 text-gray-50">
                <div className="lg:hidden">
                  <Header user={user} setSidebarOpen={setOpen} />
                </div>
                <main className="flex flex-1 overflow-y-hidden w-full">
                  <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/:projectId" element={<Project />} />
                    <Route
                      path="/projects/:projectId/emission-activity/:emissionActivityId"
                      element={<ProjectEmissionActivity />}
                    />
                    <Route path="/projects/new" element={<NewProject />} />

                    <Route path="/growers" element={<Growers />} />
                    <Route path="/growers/:growerId" element={<Grower />} />
                    {
                      <Route
                        path="/growers/:growerId/kyb/:kybId"
                        element={
                          user.role === UserRole.SUPER_ADMIN ? (
                            <KYBView />
                          ) : (
                            <PageNotFound />
                          )
                        }
                      />
                    }
                    <Route path="/growers/new" element={<NewGrower />} />

                    <Route path="/users" element={<Users />} />
                    <Route path="/admin-users" element={<AdminUsers />} />
                    <Route path="/users/:userId/edit" element={<EditUser />} />
                    <Route path="/users/new" element={<NewUser />} />
                    <Route path="/logout" element={<Logout />} />
                    {/* <Route path="*" element={<Navigate to="/" />} /> */}
                  </Routes>
                </main>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          )}
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
