import { useEffect, useState } from "react";
import { User } from "../../types";
import { FetchError } from "../../lib/fetcher";
import { api } from "../../api";

import TextInput from "../../components/text-input";
import Loading from "../../components/loading";
import SelectInput from "../../components/select-input";
import { UserRole } from "../../constants";
import useMe from "../../hooks/useMe";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";

export default function EditUser() {
  const { userId } = useParams();
  const [userInfo, setUserInfo] = useState<Partial<User>>();
  const [loading, setLoading] = useState(false);
  const me = useMe();

  const { user, loading: userLoading } = useUser(+userId!);

  useEffect(() => {
    if (!user) {
      return;
    }

    setUserInfo({
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
    });
  }, [user]);

  async function handleUpdateUser() {
    if (!userInfo || !userId) {
      return;
    }

    if (!userInfo.firstName || !userInfo.lastName || !userInfo.role) {
      return;
    }
    setLoading(true);

    const response = await api.updateUser(userInfo, +userId);
    if (response instanceof FetchError) {
      setLoading(false);
      console.log("Error creating project" + response.message);
      return;
    }

    setLoading(false);
    window.location.href = "/users";
  }

  return (
    <div className="flex w-full flex-col px-4 sm:px-8">
      <header className="flex flex-row justify-between items-center p-4">
        <h1 className="text-2xl font-semibold">Update User</h1>
      </header>
      <main className="flex flex-col flex-1 mb-8 px-2 sm:px-4">
        {loading || userLoading || !me ? (
          <Loading />
        ) : (
          <>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
              <div className="sm:col-span-6">
                <TextInput
                  label="First Name"
                  type="text"
                  value={userInfo?.firstName ?? ""}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      firstName: e.target.value,
                    })
                  }
                  error={!userInfo?.firstName ? 1 : 0}
                />
              </div>
              <div className="sm:col-span-6">
                <TextInput
                  label="Last Name"
                  type="text"
                  value={userInfo?.lastName ?? ""}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      lastName: e.target.value,
                    })
                  }
                  error={!userInfo?.lastName ? 1 : 0}
                />
              </div>

              {me.role === "admin" || me.role === "super_admin" ? (
                <div className="sm:col-span-6">
                  <SelectInput
                    label="Role"
                    value={userInfo?.role ?? ""}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        role: e.target.value as UserRole,
                      });
                    }}
                  >
                    <option></option>
                    {Object.entries(UserRole).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              ) : null}
            </div>

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-xl bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                onClick={handleUpdateUser}
              >
                Update User
              </button>
            </div>
          </>
        )}
      </main>
    </div>
  );
}
