import { useMemo, useState } from "react";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { EmissionAttachment } from "../../../types";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import Button from "../../../components/button";
import { EmissionVariant } from "../../../constants";
import { useParams } from "react-router-dom";
import { FetchError } from "../../../lib/fetcher";
import { api } from "../../../api";

interface BaselineEmissionAttachmentsProps {
  year: number;
  title: string;
  attachments?: EmissionAttachment[];
  variant?: EmissionVariant;
  loading: boolean;
}

export default function BaselineEmissionAttachments({
  year,
  title,
  attachments,
  variant,
  loading,
}: BaselineEmissionAttachmentsProps) {
  const [filteredAttachments, setFilteredAttachments] =
    useState<EmissionAttachment[]>();
  const { projectId, emissionActivityId } = useParams();

  useMemo(() => {
    if (!attachments || !year) {
      return;
    }
    setFilteredAttachments(
      attachments.filter(
        (attachment) =>
          attachment.activityYear && attachment.activityYear === year
      )
    );
  }, [attachments, year]);

  async function getAttachmentUrl(id?: number) {
    if (!projectId || !emissionActivityId || !id) return;
    const response = await api.getEmissionAttachmentUrlById(
      +projectId,
      +emissionActivityId,
      id
    );

    if (response instanceof FetchError) {
      alert(response.message);
      console.log(response);
      return;
    }

    window.open(response.url, "_blank");
  }

  return (
    <div className="mt-1">
      <div className="flex-col w-full">
        <div className="flex flex-row justify-between items-center w-full">
          <h2 className="text-base font-semibold text-apple-400">
            {variant !== EmissionVariant.OnGoing ? year : ""} {title}{" "}
            Attachments
          </h2>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
        <>
          {loading ? (
            <div className="sm:col-span-6">
              <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-4 py-1">
                  <div className="h-4 bg-shark-700 rounded w-1/4"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-shark-700 rounded"></div>
                    <div className="h-4 bg-shark-700 rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : filteredAttachments && filteredAttachments.length ? (
            filteredAttachments.map((attachment, index) => (
              <div
                key={index}
                className="sm:col-span-6 rounded-xl my-2 border border-shark-600 bg-shark-800"
              >
                <div className="flex items-center justify-between p-2 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-shark-300"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex flex-col">
                      <div className="flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {attachment.attachmentType}
                        </span>
                      </div>
                      <p className="text-xs text-shark-200">
                        {attachment.attachmentName}
                      </p>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => getAttachmentUrl(attachment.id)}
                    >
                      View
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full">
              <EmptyPlaceholder>
                Grower has not submitted any {title} attachments
                {variant !== EmissionVariant.OnGoing ? ` for ${year}.` : "."}
              </EmptyPlaceholder>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
