import { Link, useParams } from "react-router-dom";
import Button from "../../components/button";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Grower } from "../../types";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import Loading from "../../components/loading";
import useAllGrowers from "../../hooks/useAllGrowers";
import { Transition, Dialog, Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function ProjectGrowers() {
  const [loading, setLoading] = useState(false);
  const [reloadProjectGrowers, setReloadProjectGrowers] = useState(false);
  const [showGrowerModal, setShowGrowerModal] = useState(false);
  const [projectGrowers, setProjectGrowers] = useState<Grower[]>([]);
  const [query, setQuery] = useState("");

  const { projectId } = useParams();
  const { allGrowers, loading: growersLoading } = useAllGrowers();

  useEffect(() => {
    async function getProjectGrowers() {
      if (!projectId) {
        return;
      }
      setLoading(true);
      const response = await api.getProjectGrowers(+projectId);
      if (response instanceof FetchError) {
        setLoading(false);
        return;
      }
      setProjectGrowers(response.growers);
      setLoading(false);
    }

    getProjectGrowers();

    if (reloadProjectGrowers) {
      setReloadProjectGrowers(false);
    }
  }, [projectId, reloadProjectGrowers]);

  const unselectedGrowers = useMemo(() => {
    if (!allGrowers || !projectGrowers) {
      return [];
    }
    return allGrowers.filter((grower) => {
      return !projectGrowers.some(
        (projectGrower) => projectGrower.id === grower.id
      );
    });
  }, [allGrowers, projectGrowers]);

  function attachProjectGrower(grower: Grower) {
    if (!grower || !projectId) {
      return;
    }
    const response = api.postProjectGrower(+projectId, grower);
    if (response instanceof FetchError) {
      return;
    }
    setReloadProjectGrowers(true);
    setShowGrowerModal(false);
  }

  function AttachGrowerView() {
    const filteredGrower =
      query === ""
        ? unselectedGrowers.filter((grower) => {
            return !projectGrowers.some(
              (projectGrower) => projectGrower.id === grower.id
            );
          })
        : unselectedGrowers.filter((grower) => {
            return grower.businessInfo.legalName
              .toLowerCase()
              .includes(query.toLowerCase());
          });

    return (
      <Transition.Root
        show={showGrowerModal}
        as={Fragment}
        afterLeave={() => setQuery("")}
        appear
      >
        <Dialog as="div" className="relative z-50" onClose={setShowGrowerModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-shark-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-shark-300 overflow-hidden rounded-xl bg-shark-500 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-shark-200"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-shark-50 placeholder:text-shark-200 focus:ring-0 sm:text-md"
                      placeholder="Search..."
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>

                  {growersLoading ? (
                    <Loading />
                  ) : (
                    filteredGrower.length > 0 && (
                      <Combobox.Options
                        static
                        className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-shark-200 "
                      >
                        {filteredGrower.map((grower, i) => (
                          <Combobox.Option
                            key={grower.id}
                            value={grower}
                            onClick={() => {
                              attachProjectGrower(grower);
                              setShowGrowerModal(false);
                            }}
                            className={({ active }) =>
                              `cursor-pointer select-none px-4 py-2 ${
                                active && "bg-apple-400 text-white"
                              } ${
                                i < filteredGrower.length - 1
                                  ? "border-b border-shark-400"
                                  : ""
                              }`
                            }
                          >
                            {grower.businessInfo.legalName}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )
                  )}

                  {query !== "" && filteredGrower.length === 0 && (
                    <p className="p-4 text-sm text-shark-100">
                      No allGrowers found.
                    </p>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return (
    <div className="flex flex-col px-2">
      {AttachGrowerView()}
      <header className="flex flex-row justify-between items-center">
        <h1 className="text-lg font-semibold text-shark-100">Growers</h1>
        <div className="flex flex-row space-x-2">
          {/* <Link to={"/projects/new"}> */}
          <Button onClick={() => setShowGrowerModal(true)}>
            Attach Grower
          </Button>
          {/* </Link> */}
        </div>
      </header>

      <main className="flex flex-col flex-1 px-8  bg-[#202125] h-full overflow-y-hidden">
        <div className="flex flex-col mt-4 w-full">
          {loading ? (
            <Loading />
          ) : (
            <div className="flex flex-col ">
              {projectGrowers.length > 0 ? (
                projectGrowers.map((grower, i) => (
                  <Link
                    to={`/growers/${grower.id}?tab=1`}
                    key={i}
                    className="border-b border-shark-600 py-3"
                  >
                    <div className="flex flex-row justify-between items-center gap-x-4 ">
                      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-apple-200">
                        <span className="text-xs font-medium leading-none text-apple-800">
                          {grower?.businessInfo.legalName
                            .charAt(0)
                            .toUpperCase()}
                        </span>
                      </span>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-white">
                          {grower?.businessInfo.legalName}
                        </p>
                        <p className="text-xs leading-6 text-white">
                          {grower?.businessInfo.abnAcnNumber}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p>No Growers</p>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
