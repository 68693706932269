import { useEffect, useState } from "react";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import { EmissionData } from "../../types";

export default function useProjectEmissionData(
  projectId?: string,
  emissionActivityId?: string
) {
  const [projectEmissionData, setProjectEmissionData] = useState<
    EmissionData | undefined
  >({} as EmissionData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getProjectBaselineData() {
      if (!projectId || !emissionActivityId) {
        setLoading(false);
        return;
      }
      const response = await api.getProjectEmissionData(
        +projectId,
        +emissionActivityId
      );
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        setProjectEmissionData(undefined);
        return;
      }
      setProjectEmissionData(response.projectEmissionData);
      setLoading(false);
    }

    getProjectBaselineData();
  }, [emissionActivityId, projectId]);

  return { projectEmissionData, loading, error };
}
