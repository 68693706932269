import { useEffect, useState } from "react";
import { KYBConsent } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";

export default function useConsent(kybId?: number) {
  const [consent, setConsent] = useState<KYBConsent>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function getConsent() {
      setLoading(true);
      if (!kybId) {
        setLoading(false);
        return;
      }
      const response = await api.getKYBConsent(+kybId);
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setConsent(response.consent);
      setLoading(false);
    }

    getConsent();
  }, [kybId]);

  return { consent, loading, error };
}
