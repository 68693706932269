import { useMemo, useState } from "react";
import Loading from "../../components/loading";
import {
  EntityPerson,
  KybNode,
  PartnershipData,
  SoleTraderData,
  TrustData,
  UnlistedCompanyData,
} from "../../types";
import PeopleListView from "./people-list";
import BusinessInfoView from "./business-information";
import PartnershipDetailsView from "./partnership-details";
import TrustDetailsView from "./trust-details";
import SoleTraderDetailsView from "./sole-trader";
import UnlistedCompanyDetailsView from "./unlisted-company-details";
import Badge from "../../components/badge";
import PEPView from "./pep";
import SanctionDeclarationView from "./sanction-declaration";
import usePEP from "../../hooks/usePEP";
import useSanctionDeclaration from "../../hooks/useSanctionDeclaration";
import useConsent from "../../hooks/useConsent";
import ConsentView from "./consent";

interface KYBDetailsProps {
  loading: boolean;
  kybId?: number;
  data?: KybNode[];
  kybPeople?: EntityPerson[];
}
export default function KYBDetailsView({
  loading,
  data,
  kybPeople,
  kybId,
}: KYBDetailsProps) {
  const [selectedNodeId, setSelectedNodeId] = useState<number | undefined>(
    undefined
  );
  const [selectedView, setSelectedview] = useState<
    "pep" | "sanction" | "consent" | undefined
  >("consent");
  const [selectedTab, setSelectedTab] = useState<
    "business-info" | "entity-data"
  >("business-info");

  const { pep } = usePEP(kybId);
  const { sanctionDeclaration } = useSanctionDeclaration(kybId);
  const { consent } = useConsent(kybId);

  const sortedNodes = useMemo(() => {
    if (!data) return [];
    return data.sort((a, b) => a.id - b.id);
  }, [data]);

  const entityData = useMemo(() => {
    if (!sortedNodes) return;
    if (!selectedNodeId) return sortedNodes[0];
    return sortedNodes[selectedNodeId];
  }, [selectedNodeId, sortedNodes]);

  const entityCompnent = useMemo(() => {
    if (!entityData) return null;
    switch (entityData.entityType) {
      case "Sole Trader/Individual":
        return (
          <SoleTraderDetailsView
            data={(entityData.entityData as SoleTraderData).entityPerson}
            attachments={entityData.attachments}
          />
        );
      case "Partnership":
        return (
          <PartnershipDetailsView
            data={entityData.entityData as PartnershipData}
            attachments={entityData.attachments}
          />
        );
      case "Unlisted Company":
        return (
          <UnlistedCompanyDetailsView
            data={entityData.entityData as UnlistedCompanyData}
            attachments={entityData.attachments}
          />
        );
      case "Trust":
        return (
          <TrustDetailsView
            data={entityData.entityData as TrustData}
            attachments={entityData.attachments}
          />
        );
      case "Other":
        return (
          <UnlistedCompanyDetailsView
            data={entityData.entityData as UnlistedCompanyData}
            attachments={entityData.attachments}
          />
        );
      default:
        return null;
    }
  }, [entityData]);

  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex flex-row flex-1 w-full gap-x-4">
        <div className="flex flex-col w-2/12 border-r border-shark-600">
          <p className="text-base  text-apple-400 font-semibold border-b pb-3 border-shark-600">
            Entities
          </p>
          <div className="flex flex-col flex-1 overflow-y-auto">
            <div
              className={`flex flex-row w-full border-b border-shark-600 py-3 px-3 cursor-pointer hover:bg-shark-600 hover:text-white ${
                selectedView === "consent" ? "bg-shark-700 " : " text-shark-400"
              }`}
              onClick={() => {
                setSelectedview("consent");
                setSelectedNodeId(undefined);
              }}
            >
              <div
                className={`flex flex-col justify-between items-start gap-x-4  w-full `}
              >
                <p className="text-sm font-semibold">Grower Consent</p>
              </div>
            </div>
            {!data ? (
              <Loading />
            ) : (
              sortedNodes.map((kybNode, i) => (
                <div
                  className={`flex flex-row w-full border-b border-shark-600 py-3 px-3 cursor-pointer hover:bg-shark-600 hover:text-white ${
                    i === selectedNodeId ? "bg-shark-700 " : " text-shark-400"
                  }`}
                  key={i}
                  onClick={() => {
                    setSelectedNodeId(i);
                    setSelectedTab("business-info");
                    setSelectedview(undefined);
                  }}
                >
                  <div
                    className={`flex flex-col justify-between items-start gap-x-4 w-full `}
                  >
                    <p className="text-sm font-semibold">
                      {i === 0 ? "Growers Entity" : "Entity " + i}
                    </p>
                    <Badge variant="disabled">
                      {kybNode.entityPerson?.entityType ?? kybNode.entityType}
                    </Badge>
                  </div>
                </div>
              ))
            )}
            {pep ? (
              <div
                className={`flex flex-row w-full border-b border-shark-600 py-3 px-3 cursor-pointer hover:bg-shark-600 hover:text-white ${
                  selectedView === "pep" ? "bg-shark-700 " : " text-shark-400"
                }`}
                onClick={() => {
                  setSelectedview("pep");
                  setSelectedNodeId(undefined);
                }}
              >
                <div
                  className={`flex flex-col justify-between items-start gap-x-4  w-full `}
                >
                  <p className="text-sm font-semibold">
                    Politically Exposed Person (PEP)
                  </p>
                </div>
              </div>
            ) : null}
            {sanctionDeclaration ? (
              <div
                className={`flex flex-row w-full border-b border-shark-600 py-3 px-3 cursor-pointer hover:bg-shark-600 hover:text-white ${
                  selectedView === "sanction"
                    ? "bg-shark-700 "
                    : " text-shark-400"
                }`}
                onClick={() => {
                  setSelectedview("sanction");
                  setSelectedNodeId(undefined);
                }}
              >
                <div
                  className={`flex flex-col justify-between items-start gap-x-4  w-full `}
                >
                  <p className="text-sm font-semibold">Sanction Declaration</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col justify-between gap-x-2 w-7/12">
          <div className="flex flex-col flex-1  bg-shark-900 rounded-xl p-4">
            {selectedView === undefined ? (
              <div className="flex flex-1 flex-col">
                <p className="text-2xl font-semibold text-shark-100 pb-3 px-2">
                  {selectedNodeId === 0
                    ? "Growers Entity"
                    : "Entity " + selectedNodeId}
                </p>
                <nav className="flex flex-row w-full justify-between  border-b border-shark-500">
                  <p
                    className={`w-1/2 text-center cursor-pointer
                    ${
                      selectedTab === "business-info"
                        ? "border-apple-400 text-apple-400 font-semibold"
                        : "border-transparent text-shark-200 hover:border-shark-400 hover:text-shark-400"
                    }
                     whitespace-nowrap border-b-2 px-1 pb-2 text-sm `}
                    onClick={() => setSelectedTab("business-info")}
                  >
                    Business Information
                  </p>
                  <p
                    className={`w-1/2 text-center cursor-pointer
                    ${
                      selectedTab === "entity-data"
                        ? "border-apple-400 text-apple-400 font-semibold"
                        : "border-transparent text-shark-200 hover:border-shark-400 hover:text-shark-400"
                    }
                     whitespace-nowrap border-b-2 px-1 pb-2 text-sm `}
                    onClick={() => setSelectedTab("entity-data")}
                  >
                    {entityData?.entityPerson?.entityType ??
                      entityData?.entityType}{" "}
                    Data
                  </p>
                </nav>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="flex flex-col">
                    {selectedTab === "business-info" ? (
                      <div className="flex flex-col w-full">
                        <BusinessInfoView data={entityData?.businessInfo} />
                      </div>
                    ) : selectedTab === "entity-data" ? (
                      <div className="flex flex-col w-full">
                        {entityCompnent}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ) : selectedView === "pep" ? (
              <PEPView data={pep} />
            ) : selectedView === "sanction" ? (
              <SanctionDeclarationView data={sanctionDeclaration} />
            ) : selectedView === "consent" ? (
              <ConsentView data={consent} />
            ) : null}
          </div>
        </div>
        <div className="flex flex-col w-3/12">
          <PeopleListView allKybPeople={kybPeople} kybId={kybId} />
        </div>
      </div>
    </div>
  );
}
