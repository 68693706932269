import { useMemo } from "react";
import { CarbonBuilderApplication } from "../../../types";
import {
  BaselineEmissionAttachmentType,
  CarbonBuilderProduct,
  EmissionVariant,
} from "../../../constants";
import Divider from "../../../components/divider";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import BaselineEmissionAttachments from "./emission-attachment";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import { useParams } from "react-router-dom";
import DescriptionContainer from "../../../components/description-container";
import DescriptionItem from "../../../components/description-item";
import Badge from "../../../components/badge";

interface CarbonBuilderProps {
  carbonBuilderApplicationData?: CarbonBuilderApplication;
  year?: number;
}

export default function CarbonBuilder({
  carbonBuilderApplicationData,
  year,
}: CarbonBuilderProps) {
  const { projectId, emissionActivityId } = useParams();
  const { projectAttachment, loading: attachmentLoading } =
    useProjectAttachment(
      projectId,
      emissionActivityId,
      BaselineEmissionAttachmentType.CarbonBuilder
    );

  const { barleyCarbonBuilder, canolaCarbonBuilder } = useMemo(() => {
    if (
      !carbonBuilderApplicationData ||
      !carbonBuilderApplicationData.carbonBuilderData
    )
      return {
        barleyCarbonBuilder: undefined,
        canolaCarbonBuilder: undefined,
      };
    return {
      barleyCarbonBuilder: carbonBuilderApplicationData.carbonBuilderData.find(
        (product) =>
          product.carbonBuilderType === CarbonBuilderProduct.BarleyCarbonBuilder
      ),
      canolaCarbonBuilder: carbonBuilderApplicationData.carbonBuilderData.find(
        (product) =>
          product.carbonBuilderType === CarbonBuilderProduct.CanolaCarbonBuilder
      ),
    };
  }, [carbonBuilderApplicationData]);

  const { barleyCarbonBuilderAttachments, canolaCarbonBuilderAttachments } =
    useMemo(() => {
      if (!projectAttachment)
        return {
          barleyCarbonBuilderAttachments: undefined,
          canolaCarbonBuilderAttachments: undefined,
        };
      return {
        barleyCarbonBuilderAttachments: projectAttachment.filter(
          (attachment) =>
            attachment.attachmentType ===
            CarbonBuilderProduct.BarleyCarbonBuilder
        ),
        canolaCarbonBuilderAttachments: projectAttachment.filter(
          (attachment) =>
            attachment.attachmentType ===
            CarbonBuilderProduct.CanolaCarbonBuilder
        ),
      };
    }, [projectAttachment]);

  return (
    <>
      <p className="text-base font-semibold text-apple-400 mb-4">
        Carbon Builder Application
      </p>
      <div className=" p-4 bg-shark-800 rounded-md border border-shark-700">
        {carbonBuilderApplicationData &&
        carbonBuilderApplicationData.carbonBuilderData &&
        carbonBuilderApplicationData.carbonBuilderData.length ? (
          <>
            <DescriptionContainer>
              <DescriptionItem title="Carbon Builder Products">
                {barleyCarbonBuilder && (
                  <Badge variant="success">
                    {CarbonBuilderProduct.BarleyCarbonBuilder}
                  </Badge>
                )}
                {canolaCarbonBuilder && (
                  <Badge variant="success">
                    {CarbonBuilderProduct.CanolaCarbonBuilder}
                  </Badge>
                )}
              </DescriptionItem>
            </DescriptionContainer>
            <Divider />
            {barleyCarbonBuilder && (
              <>
                <p className="text-sm font-semibold text-apple-400 mb-4">
                  Barley Carbon Builder
                </p>
                {barleyCarbonBuilder.carbonBuilderTypeData &&
                barleyCarbonBuilder.carbonBuilderTypeData.length > 0 ? (
                  <>
                    <div className="mt-2 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full">
                            <thead className="divide-y-reverse divide-shark-600">
                              <tr className="divide-x  divide-shark-600">
                                <th
                                  scope="col"
                                  className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-shark-200 text-center"
                                >
                                  Paddock Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-shark-200 text-center"
                                >
                                  Date Applied
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                                >
                                  Volume Applied
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-shark-600 ">
                              <tr className="divide-x divide-shark-600">
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 pl-4 pr-4  text-xs font-normal text-shark-200 sm:pr-0 text-center bg-apple-500/30"
                                >
                                  grams (g)
                                </th>
                              </tr>
                              {barleyCarbonBuilder.carbonBuilderTypeData.map(
                                (data, i) => (
                                  <tr
                                    key={i}
                                    className="divide-x divide-shark-600"
                                  >
                                    <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                      Paddock {i + 1}
                                    </td>
                                    <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                      {data.paddockName}
                                    </td>
                                    <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                      {data.dateApplied}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 sm:pr-0 font-semibold text-center">
                                      {data.volumeApplied}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <BaselineEmissionAttachments
                      year={
                        year ??
                        barleyCarbonBuilderAttachments?.[0].activityYear ??
                        1
                      }
                      title="Barley CarbonBuilder"
                      attachments={barleyCarbonBuilderAttachments}
                      variant={EmissionVariant.OnGoing}
                      loading={attachmentLoading}
                    />
                  </>
                ) : (
                  <EmptyPlaceholder>
                    No Barley CarbonBuilder data available
                  </EmptyPlaceholder>
                )}
              </>
            )}
            {canolaCarbonBuilder && (
              <>
                <Divider />

                <p className="text-sm font-semibold text-apple-400 mb-4">
                  Canola Carbon Builder
                </p>
                {canolaCarbonBuilder.carbonBuilderTypeData &&
                canolaCarbonBuilder.carbonBuilderTypeData.length > 0 ? (
                  <>
                    <div className="mt-2 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full">
                            <thead className="divide-y-reverse divide-shark-600">
                              <tr className="divide-x  divide-shark-600">
                                <th
                                  scope="col"
                                  className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-shark-200 text-center"
                                >
                                  Paddock Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-shark-200 text-center"
                                >
                                  Date Applied
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                                >
                                  Volume Applied
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-shark-600 ">
                              <tr className="divide-x divide-shark-600">
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 pl-4 pr-4  text-xs font-normal text-shark-200 sm:pr-0 text-center bg-apple-500/30"
                                >
                                  grams (g)
                                </th>
                              </tr>
                              {canolaCarbonBuilder.carbonBuilderTypeData.map(
                                (data, i) => (
                                  <tr
                                    key={i}
                                    className="divide-x divide-shark-600"
                                  >
                                    <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                      Paddock {i}
                                    </td>
                                    <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                      {data.paddockName}
                                    </td>
                                    <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                      {data.dateApplied}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 sm:pr-0 font-semibold text-center">
                                      {data.volumeApplied}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <BaselineEmissionAttachments
                      year={
                        year ??
                        canolaCarbonBuilderAttachments?.[0].activityYear ??
                        1
                      }
                      title="Canola CarbonBuilder"
                      attachments={canolaCarbonBuilderAttachments}
                      variant={EmissionVariant.OnGoing}
                      loading={attachmentLoading}
                    />
                  </>
                ) : (
                  <EmptyPlaceholder>
                    No Canola CarbonBuilder data available
                  </EmptyPlaceholder>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyPlaceholder>
            Carbon Builder Application data is not available because the grower
            has not submitted their Project Emission Data.
          </EmptyPlaceholder>
        )}
      </div>
    </>
  );
}
