import { Transition, Dialog } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";
import logo from "../assets/logo-loambio.png";
import MenuItems from "./menu-items";
import { Link } from "react-router-dom";
import { VersionNumber } from "../constants";
import { User } from "../types";

export interface SideBarProps {
  sidebarOpen: boolean;
  user?: User;
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

export default function SideBar({
  sidebarOpen,
  user,
  setSidebarOpen,
}: SideBarProps) {
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600/50" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#202125] px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-40 shrink-0 items-center">
                    <img src={logo} alt="Logo" />
                  </div>
                  <MenuItems setSidebarOpen={setSidebarOpen} />
                  <div className="flex flex-end flex-col mb-4">
                    <Link
                      to={"/logout"}
                      onClick={() => setSidebarOpen(false)}
                      className="text-gray-400 hover:text-white hover:bg-apple-400 group flex gap-x-3 rounded-xl py-2 px-4 text-sm leading-6 font-semibold"
                    >
                      <ArrowLeftOnRectangleIcon
                        className="h-6 w-6 shrink-0 mr-2"
                        aria-hidden="true"
                      />
                      Logout
                    </Link>
                    <p className="text-gray-400 text-xs text-center px-6 pt-8">
                      {VersionNumber}
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col lg:w-24 lg:items-center overflow-hidden">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-2 overflow-y-hidden">
          <div className="flex mx-2 my-4 shrink-0 items-center">
            <img src={logo} alt="Logo" className="w-auto item-center" />
          </div>
          <MenuItems setSidebarOpen={setSidebarOpen} />
          <div className="flex flex-end items-center flex-col mb-4">
            <div className="pb-4 flex flex-col items-center">
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-apple-200">
                <span className="text-xs font-medium leading-none text-apple-800">
                  {user?.username.charAt(0).toUpperCase()}
                </span>
              </span>
              <span className="hidden lg:flex lg:items-center">
                <span
                  className="text-[9px] font-semibold leading-6 text-gray-100"
                  aria-hidden="true"
                >
                  {user?.firstName}
                </span>
              </span>
            </div>
            <Link
              to={"/logout"}
              onClick={() => setSidebarOpen(false)}
              className="text-gray-400 hover:text-white hover:bg-apple-400 group flex flex-col items-center gap-x-3 rounded-xl py-2 px-4 text-xxs leading-6 font-semibold"
            >
              <ArrowLeftOnRectangleIcon
                className="h-5 w-5 shrink-0 "
                aria-hidden="true"
              />
              Logout
            </Link>

            <p className="text-gray-400 text-xxs text-center px-6 pt-8">
              {VersionNumber}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
