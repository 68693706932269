import { useEffect, useState } from "react";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import { EmissionActivityInfo } from "../../types";

export default function useProjectEmissionActivity(projectId?: string) {
  const [allEmissionActivity, setAllEmissionActivity] =
    useState<EmissionActivityInfo[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  function mutate(emissionActivity: EmissionActivityInfo) {
    setAllEmissionActivity((prev) => [...(prev ?? []), emissionActivity]);
  }

  useEffect(() => {
    async function getProjectEmissionActivityInfo() {
      if (!projectId) {
        setLoading(false);
        return;
      }
      const response = await api.getEmissionActivityInstances(+projectId);
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setAllEmissionActivity(response.emissionActivity);
      setLoading(false);
    }

    getProjectEmissionActivityInfo();
  }, [projectId]);

  return { allEmissionActivity, loading, error, mutate };
}
