import { useState, useEffect } from "react";
import { api } from "../api";
import { EntityPersonEmailChange } from "../types";
import { FetchError } from "../lib/fetcher";

export default function useAllEntityPersonEmailChanges(
  entityPersonId?: number
) {
  const [allEntityPersonEmailChanges, setAllEntityPersonEmailChanges] =
    useState<EntityPersonEmailChange[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      if (!entityPersonId) {
        setLoading(false);
        return;
      }
      const response = await api.getAllEmailChanges(entityPersonId);
      if (response instanceof FetchError) {
        setLoading(false);
        alert("Error fetching email changes");
        return;
      }
      setAllEntityPersonEmailChanges(response.emailChanges);
      setLoading(false);
    }

    getData(); // remove the return statement
  }, [entityPersonId]);

  return { allEntityPersonEmailChanges, loading };
}
