import DashboardDataView from "./dashboard-data-view";
import { EmissionVariant } from "../../../constants";
import { EmissionActivityInfo, SummaryEmissionData } from "../../../types";

interface DashboardDataProps {
  projectCalculatedEmissionData: SummaryEmissionData | undefined;
  emissionActivityInfo: EmissionActivityInfo | undefined;
}

export default function DashboardData({
  projectCalculatedEmissionData,
  emissionActivityInfo,
}: DashboardDataProps) {
  return (
    <div className="w-full">
      <div className="grid grid-cols-5 gap-4 pb-4 border-b border-b-shark-600 overflow-hidden">
        <DashboardDataView
          title="Average Irrigation Emission"
          value={
            projectCalculatedEmissionData?.irrigationEmission
              ? (projectCalculatedEmissionData?.irrigationEmission?.reduce(
                  (acc, data) =>
                    acc +
                    (data.fuelEmission || 0) +
                    (data.electricityEmission || 0),
                  0
                ) ?? 0) /
                (emissionActivityInfo?.activityType === EmissionVariant.OnGoing
                  ? 1
                  : 5)
              : undefined
          }
        />
        <DashboardDataView
          title="Average Liming Emission"
          value={
            projectCalculatedEmissionData?.limingEmission
              ? (projectCalculatedEmissionData?.limingEmission?.reduce(
                  (acc, data) =>
                    acc +
                    (data.dolomiteEmission || 0) +
                    (data.limestoneEmission || 0),
                  0
                ) ?? 0) /
                (emissionActivityInfo?.activityType === EmissionVariant.OnGoing
                  ? 1
                  : 5)
              : undefined
          }
        />
        <DashboardDataView
          title="Average Synthetic Fertiliser Emission"
          value={
            projectCalculatedEmissionData?.syntheticFertilizerEmission
              ? (projectCalculatedEmissionData?.syntheticFertilizerEmission.reduce(
                  (acc, data) => acc + (data.emission || 0),
                  0
                ) ?? 0) /
                (emissionActivityInfo?.activityType === EmissionVariant.OnGoing
                  ? 1
                  : 5)
              : undefined
          }
        />
        <DashboardDataView
          title="Average Residue, and Soil Modification Emission"
          value={
            projectCalculatedEmissionData?.residueTillageAndLandscapeModificationEmission
              ? (projectCalculatedEmissionData?.residueTillageAndLandscapeModificationEmission?.reduce(
                  (acc, data) =>
                    acc + (data.tillageAndSoilLandscapingEmission || 0),
                  0
                ) +
                  projectCalculatedEmissionData?.residueTillageAndLandscapeModificationEmission?.reduce(
                    (acc, data) => acc + (data.cropResidueEmission || 0),
                    0
                  ) +
                  projectCalculatedEmissionData?.residueTillageAndLandscapeModificationEmission?.reduce(
                    (acc, data) => acc + (data.pastureTillageEmission || 0),
                    0
                  ) +
                  projectCalculatedEmissionData?.residueTillageAndLandscapeModificationEmission?.reduce(
                    (acc, data) => acc + (data.coverCropEmission || 0),
                    0
                  )) /
                (emissionActivityInfo?.activityType === EmissionVariant.OnGoing
                  ? 1
                  : 5)
              : undefined
          }
        />
        <DashboardDataView
          title="Average Livestock Emission"
          value={
            projectCalculatedEmissionData?.livestockEmission
              ? projectCalculatedEmissionData?.livestockEmission.reduce(
                  (acc, data) =>
                    acc +
                    (data.animalEmisions?.reduce(
                      (acc, data) => acc + (data.emission ?? 0),
                      0
                    ) || 0),
                  0
                ) /
                (emissionActivityInfo?.activityType === EmissionVariant.OnGoing
                  ? 1
                  : 5)
              : undefined
          }
        />
      </div>
    </div>
  );
}
