import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { EntityPerson, EntityPersonEmailChange } from "../../types";
import TextInput from "../../components/text-input";
import Checkbox from "../../components/checkbox-input";
import TextArea from "../../components/text-area";
import Badge from "../../components/badge";
import Button from "../../components/button";
import emailChecker from "../../helpers/email-checker";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import useAllEntityPersonEmailChanges from "../../hooks/useAllEntityPersonEmailChanges";
import Loading from "../../components/loading";
import formatDate from "../../helpers/format-date";

interface EditEntityPersonProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  person?: EntityPerson;
}

export default function EditEntityPerson({
  open,
  setOpen,
  person,
}: EditEntityPersonProps) {
  const [newEmailChange, setNewEmailChange] = useState<
    Partial<EntityPersonEmailChange>
  >({});
  const { allEntityPersonEmailChanges, loading: allChangesLoading } =
    useAllEntityPersonEmailChanges(person?.id);

  async function saveChanges() {
    if (
      !person ||
      !person.id ||
      !newEmailChange?.newEmail ||
      !emailChecker(newEmailChange?.newEmail) ||
      !newEmailChange?.confirmedWithGrower ||
      !newEmailChange?.reasonForChange
    )
      return;

    const response = await api.patchEntityPersonEmail(
      person.id,
      newEmailChange
    );
    if (response instanceof FetchError) {
      alert(response.message);
      return;
    } else {
      setOpen(false);
      setNewEmailChange({});
      alert("Email successfully changed!");
      window.location.reload();
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          return;
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-shark-600 bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 ">
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl">
                    <div className="flex h-full flex-col overflow-y-auto bg-shark-900 text-shark-100 shadow-xl">
                      <div className="p-6 sticky z-50">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="font-semibold leading-6 text-shark-200">
                            Change Person Email
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md text-shark-100 hover:text-shark-300 focus:outline-none focus:ring-2 focus:ring-apple-500 focus:ring-offset-2"
                              onClick={() => {
                                setOpen(false);
                                setNewEmailChange({});
                                // window.location.reload();
                              }}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-shark-600" />
                      <div className="flex flex-row px-8 py-4 justify-between w-full">
                        <div className="flex flex-col w-1/2">
                          {person && (
                            <>
                              <p className="text-2xl font-bold text-shark-50">
                                {person?.legalName}
                              </p>
                              <p className="text-xs text-shark-300">
                                {person.email}
                              </p>
                              {person.personType ? (
                                <div className="flex flex-col mt-4">
                                  <p className="text-[0.6rem] m-0 p-0 text-apple-400">
                                    PERSON TYPE
                                  </p>
                                  <div className="flex">
                                    <Badge>{person.personType}</Badge>
                                  </div>
                                </div>
                              ) : null}
                              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
                                <div className="sm:col-span-full">
                                  <TextInput
                                    label="New Email"
                                    type="email"
                                    value={newEmailChange?.newEmail ?? ""}
                                    onChange={(e) =>
                                      setNewEmailChange({
                                        ...newEmailChange,
                                        newEmail: e.target.value,
                                      })
                                    }
                                    errormesage="Please enter a valid email!"
                                    error={
                                      !emailChecker(newEmailChange?.newEmail) ||
                                      !newEmailChange?.newEmail
                                        ? 1
                                        : 0
                                    }
                                  />
                                </div>
                                <div className="sm:col-span-full">
                                  <Checkbox
                                    label="Confirmed with Grower"
                                    checked={
                                      newEmailChange?.confirmedWithGrower ??
                                      false
                                    }
                                    onChange={(e) =>
                                      setNewEmailChange({
                                        ...newEmailChange,
                                        confirmedWithGrower: e.target.checked,
                                      })
                                    }
                                  />
                                </div>

                                <div className="sm:col-span-full">
                                  <TextArea
                                    label="Reason for change?"
                                    value={
                                      newEmailChange?.reasonForChange ?? ""
                                    }
                                    onChange={(e) =>
                                      setNewEmailChange({
                                        ...newEmailChange,
                                        reasonForChange: e.target.value,
                                      })
                                    }
                                    error={
                                      !newEmailChange?.reasonForChange ? 1 : 0
                                    }
                                  />
                                </div>
                              </div>
                              <div className="mt-4 flex justify-center ">
                                <Button
                                  disabled={
                                    !newEmailChange?.newEmail ||
                                    !emailChecker(newEmailChange?.newEmail) ||
                                    !newEmailChange?.confirmedWithGrower ||
                                    !newEmailChange?.reasonForChange
                                  }
                                  onClick={() => {
                                    saveChanges();
                                  }}
                                >
                                  Save Changes
                                </Button>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="flex flex-col w-1/2 px-8 ">
                          {allChangesLoading && <Loading />}
                          {allEntityPersonEmailChanges.length > 0 ? (
                            <>
                              <p className="text-2xl font-bold text-shark-50">
                                Change History
                              </p>
                              <div className="px-4 mt-4">
                                {allEntityPersonEmailChanges.map(
                                  (change, i) => (
                                    <div key={i} className="flex flex-col ">
                                      <div className="flex-auto bg-shark-800 border border-shark-700 rounded-xl p-4">
                                        <div className="flex flex-row justify-between gap-x-4">
                                          <div className="text-sm text-shark-200">
                                            <span className="font-medium text-apple-400">
                                              {change.changedBy?.firstName}{" "}
                                              {change.changedBy?.lastName}
                                            </span>{" "}
                                            changed email from{" "}
                                            <span className="font-medium text-shark-50">
                                              {change.oldEmail}
                                            </span>{" "}
                                            to{" "}
                                            <span className="font-medium text-shark-50">
                                              {change.newEmail}
                                            </span>{" "}
                                          </div>
                                          <div className="shrink-0 text-xs text-shark-200">
                                            {formatDate(change.changedOn)}
                                          </div>
                                        </div>
                                        <div className="flex flex-col pt-2">
                                          <p className="text-xs pt-2  text-shark-300">
                                            Reason for Change
                                          </p>
                                          <p className="text-sm text-shark-100">
                                            {change.reasonForChange}
                                          </p>
                                        </div>
                                      </div>
                                      {i !==
                                        allEntityPersonEmailChanges.length -
                                          1 && (
                                        <div className="w-px ml-4 bg-shark-600 h-6" />
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="flex flex-col items-center justify-center h-full">
                              <p className="text-2xl font-bold text-shark-50">
                                No Previous Changes
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
