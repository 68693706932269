import { useMemo, useState } from "react";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import RenderAverageEmission from "../../../components/render-average-emission";
import { formatMonthYear } from "../../../helpers/format-month-year";
import {
  CoverCrop,
  CropInformation,
  CropTillage,
  FarmInfo,
  PastureCrop,
  SoilLandscape,
  SummaryEmissionData,
} from "../../../types";
import DescriptionContainer from "../../../components/description-container";
import DescriptionItem from "../../../components/description-item";
import Badge from "../../../components/badge";
import { useParams } from "react-router-dom";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import {
  BaselineEmissionAttachmentType,
  EmissionVariant,
} from "../../../constants";
import BaselineEmissionAttachments from "./emission-attachment";

interface ResidueLandscapeProps {
  cropTillageData?: CropTillage[];
  soilLandscapeData?: SoilLandscape[];
  cropInformationData?: CropInformation[];
  coverCropData?: CoverCrop[];
  pastureData?: PastureCrop[];
  emissionData: SummaryEmissionData;
  farmInfo: FarmInfo;
  activityYears?: number[];
  variant?: EmissionVariant;
  period?: string;
}

export default function ResidueLandscapeView({
  cropTillageData,
  soilLandscapeData,
  cropInformationData,
  coverCropData,
  pastureData,
  emissionData,
  farmInfo,
  activityYears,
  variant,
  period,
}: ResidueLandscapeProps) {
  const { projectId, emissionActivityId } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const {
    projectAttachment: soilLandscapeAttachment,
    loading: soilLandscapeAttachmentLoading,
  } = useProjectAttachment(
    projectId,
    emissionActivityId,
    BaselineEmissionAttachmentType.SoilLandscape
  );
  const {
    projectAttachment: cropTillageAttachment,
    loading: cropTillageAttachmentLoading,
  } = useProjectAttachment(
    projectId,
    emissionActivityId,
    BaselineEmissionAttachmentType.CropTillage
  );
  const {
    projectAttachment: cropInformationAttachment,
    loading: cropInformationAttachmentLoading,
  } = useProjectAttachment(
    projectId,
    emissionActivityId,
    BaselineEmissionAttachmentType.CropInformation
  );
  const {
    projectAttachment: coverCropAttachment,
    loading: coverCropAttachmentLoading,
  } = useProjectAttachment(
    projectId,
    emissionActivityId,
    BaselineEmissionAttachmentType.CoverCrop
  );

  const {
    projectAttachment: pastureAttachment,
    loading: pastureAttachmentLoading,
  } = useProjectAttachment(
    projectId,
    emissionActivityId,
    BaselineEmissionAttachmentType.Pasture
  );
  // console.log(selectedTab);

  const RenderComponent = useMemo(() => {
    if (
      variant === EmissionVariant.OnGoing ||
      (activityYears && activityYears.length > 0)
    ) {
      if (selectedTab > (activityYears?.length ?? 1)) {
        return (
          <>
            {emissionData.residueTillageAndLandscapeModificationEmission ? (
              <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-shark-600">
                      <thead>
                        <tr className="divide-x divide-shark-600">
                          <th
                            scope="col"
                            className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                          >
                            Years
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-semibold text-shark-200 text-center"
                          >
                            Tillage and Soil landscape modification Emmissions
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-semibold text-shark-200 text-center"
                          >
                            Crop Residue
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-semibold text-shark-200 text-center"
                          >
                            Pasture
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                          >
                            Cover Crop Residue
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-shark-600 ">
                        {emissionData.residueTillageAndLandscapeModificationEmission.map(
                          (data, i) => (
                            <tr key={i} className="divide-x divide-shark-600">
                              <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center px-8 ">
                                {variant === EmissionVariant.OnGoing
                                  ? period
                                  : formatMonthYear(
                                      farmInfo.carbonRegMonth ?? 1,
                                      data.year
                                    )}
                              </td>

                              <td className="whitespace-nowrap p-4 text-sm text-shark-50 text-center">
                                {Number(
                                  data.tillageAndSoilLandscapingEmission || 0
                                ).toFixed(3)}
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-shark-50 text-center">
                                {Number(data.cropResidueEmission || 0).toFixed(
                                  3
                                )}
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-shark-50 text-center">
                                {Number(
                                  data.pastureTillageEmission || 0
                                ).toFixed(3)}
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 sm:pr-0 text-center">
                                {Number(data.coverCropEmission || 0).toFixed(3)}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                      {RenderAverageEmission(
                        (emissionData.residueTillageAndLandscapeModificationEmission.reduce(
                          (acc, data) =>
                            acc + (data.tillageAndSoilLandscapingEmission || 0),
                          0
                        ) +
                          emissionData.residueTillageAndLandscapeModificationEmission.reduce(
                            (acc, data) =>
                              acc + (data.cropResidueEmission || 0),
                            0
                          ) +
                          emissionData.residueTillageAndLandscapeModificationEmission.reduce(
                            (acc, data) =>
                              acc + (data.pastureTillageEmission || 0),
                            0
                          ) +
                          emissionData.residueTillageAndLandscapeModificationEmission.reduce(
                            (acc, data) => acc + (data.coverCropEmission || 0),
                            0
                          )) /
                          (variant === EmissionVariant.OnGoing ? 1 : 5),
                        variant
                      )}
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <EmptyPlaceholder>
                Residue, Tillage, and Soil Landscape Modification Emissions is
                not available because the grower has not submitted their
                Emissions Data.
              </EmptyPlaceholder>
            )}
          </>
        );
      } else {
        const year = activityYears ? activityYears[selectedTab] : 0;
        const cropTillage =
          variant === EmissionVariant.OnGoing
            ? cropTillageData?.[0]
            : cropTillageData?.find((data) => data.activityYear === year);
        const soilLandscape =
          variant === EmissionVariant.OnGoing
            ? soilLandscapeData?.[0]
            : soilLandscapeData?.find((data) => data.activityYear === year);
        const cropInfo =
          variant === EmissionVariant.OnGoing
            ? cropInformationData?.[0]
            : cropInformationData?.find((data) => data.activityYear === year);
        const coverCrop =
          variant === EmissionVariant.OnGoing
            ? coverCropData?.[0]
            : coverCropData?.find((data) => data.activityYear === year);
        const pasture =
          variant === EmissionVariant.OnGoing
            ? pastureData?.[0]
            : pastureData?.find((data) => data.activityYear === year);
        return (
          <>
            <p className="text-base font-semibold text-apple-400 mb-4">
              {variant !== EmissionVariant.OnGoing ? year : ""} Soil Landscape
            </p>
            {soilLandscape ? (
              <div className="border border-shark-600 rounded-xl px-2 py-4 bg-shark-950/70">
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full ">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                            ></th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                            >
                              Quantity of diesel fuel
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600 ">
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                            ></th>
                            <th
                              scope="col"
                              className="py-0.5 pl-4 pr-4  text-xs font-normal text-shark-50 text-center bg-apple-500/30"
                            >
                              Kilolitres (kL)
                            </th>
                          </tr>

                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center ">
                              {variant === EmissionVariant.OnGoing
                                ? period
                                : formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    year
                                  )}
                            </td>
                            <td className="whitespace-wrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center sm:pr-0">
                              {Number(
                                soilLandscape.dieselQuantity || 0
                              ).toFixed(3)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <BaselineEmissionAttachments
                  title="Soil Landscape"
                  attachments={soilLandscapeAttachment}
                  year={year}
                  variant={variant}
                  loading={soilLandscapeAttachmentLoading}
                />
              </div>
            ) : (
              <EmptyPlaceholder>
                Soil Landscape Data was not submitted for this year.
              </EmptyPlaceholder>
            )}

            <div className="mt-4 mb-2 border-t border-shark-600" />

            <p className="text-base font-semibold text-apple-400 mb-4">
              {variant !== EmissionVariant.OnGoing ? year : ""} Crop Information
            </p>
            {cropInfo && cropInfo.cropInformationData?.length ? (
              <div className="border border-shark-600 rounded-xl px-2 py-4 bg-shark-950/70">
                <DescriptionContainer>
                  <DescriptionItem title="Crops Planted">
                    {cropInfo.cropInformationData?.map((data, i) => (
                      <Badge key={i}>{data.crop}</Badge>
                    ))}
                  </DescriptionItem>
                </DescriptionContainer>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full ">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                            ></th>

                            <th
                              scope="col"
                              className="px-4 py-3.5  text-sm font-semibold text-shark-200 text-center"
                            >
                              How many tonnes did you harvest?
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                            >
                              Did you burn the residue?
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600">
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                            ></th>
                            <th
                              scope="col"
                              className="px-4 py-0.5  text-xs font-normal text-shark-200 text-center bg-apple-500/30"
                            >
                              Tonnes of harvest
                            </th>

                            <th
                              scope="col"
                              className="py-0.5 pl-4 pr-4  text-sm font-normal text-shark-200 sm:pr-0 "
                            ></th>
                          </tr>
                          {cropInfo.cropInformationData &&
                            cropInfo.cropInformationData.map(
                              (cropInfoData, i) => (
                                <tr
                                  key={i}
                                  className="divide-x divide-shark-600"
                                >
                                  <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                    {cropInfoData.crop}
                                  </td>
                                  <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                    {Number(
                                      cropInfoData.harvestSize || 0
                                    ).toFixed(3)}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center sm:pr-0">
                                    <div className="flex justify-center">
                                      {cropInfoData.burnResidue === true ? (
                                        <Badge variant="success">Yes</Badge>
                                      ) : cropInfoData.burnResidue === false ? (
                                        <Badge variant="danger">No</Badge>
                                      ) : (
                                        <Badge variant="disabled">
                                          Unknown
                                        </Badge>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <BaselineEmissionAttachments
                  title="Crop Information"
                  attachments={cropInformationAttachment}
                  year={year}
                  variant={variant}
                  loading={cropInformationAttachmentLoading}
                />
              </div>
            ) : (
              <EmptyPlaceholder>
                Crop Information Data was not submitted for this year.
              </EmptyPlaceholder>
            )}

            <div className="mt-4 mb-2 border-t border-shark-600" />

            <p className="text-base font-semibold text-apple-400 mb-4">
              {variant !== EmissionVariant.OnGoing ? year : ""} Crop Tillage
            </p>
            {cropTillage ? (
              <div className="border border-shark-600 rounded-xl px-2 py-4 bg-shark-950/70">
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full ">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                            ></th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 text-center "
                            >
                              Area tilled
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600 ">
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                            ></th>
                            <th
                              scope="col"
                              className="py-0.5 pl-4 pr-4  text-xs font-normal text-shark-50 text-center bg-apple-500/30"
                            >
                              Hectares
                            </th>
                          </tr>

                          <tr className="divide-x divide-shark-600">
                            <td className="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center ">
                              {variant === EmissionVariant.OnGoing
                                ? period
                                : formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    year
                                  )}
                            </td>
                            <td className="whitespace-wrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center +">
                              {Number(cropTillage.areaTilled || 0).toFixed(3)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <BaselineEmissionAttachments
                  title="Crop Tillage"
                  attachments={cropTillageAttachment}
                  year={year}
                  variant={variant}
                  loading={cropTillageAttachmentLoading}
                />
              </div>
            ) : (
              <EmptyPlaceholder>
                Crop Tillage Data was not submitted for this year.
              </EmptyPlaceholder>
            )}

            <div className="mt-4 mb-2 border-t border-shark-600" />

            <p className="text-base font-semibold text-apple-400 mb-4">
              {variant !== EmissionVariant.OnGoing ? year : ""} Cover Crop
            </p>
            {coverCrop ? (
              <div className="border border-shark-600 rounded-xl px-2 py-4 bg-shark-950/70">
                <DescriptionContainer>
                  <DescriptionItem title="Crops Planted">
                    {coverCrop.coverCropData?.map((data, i) => (
                      <Badge key={i}>{data.pastureCrop}</Badge>
                    ))}
                  </DescriptionItem>
                </DescriptionContainer>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full ">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                            ></th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                            >
                              Area Sown
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600">
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                            ></th>

                            <th
                              scope="col"
                              className="py-0.5 pl-4 pr-4  text-sm font-normal text-shark-200 text-center bg-apple-500/30"
                            >
                              Hectares Sown
                            </th>
                          </tr>
                          {coverCrop.coverCropData &&
                            coverCrop.coverCropData.map((cropInfoData, i) => (
                              <tr key={i} className="divide-x divide-shark-600">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                  {cropInfoData.pastureCrop}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center sm:pr-0">
                                  {Number(cropInfoData.areaSown || 0).toFixed(
                                    3
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <BaselineEmissionAttachments
                  title="Cover Crop"
                  attachments={coverCropAttachment}
                  year={year}
                  variant={variant}
                  loading={coverCropAttachmentLoading}
                />
              </div>
            ) : (
              <EmptyPlaceholder>
                Cover Crop Data was not submitted for this year.
              </EmptyPlaceholder>
            )}

            <div className="mt-4 mb-2 border-t border-shark-600" />

            <p className="text-base font-semibold text-apple-400 mb-4">
              {variant !== EmissionVariant.OnGoing ? year : ""} Pasture
            </p>
            {pasture ? (
              <div className="border border-shark-600 rounded-xl px-2 py-4 bg-shark-950/70">
                <DescriptionContainer>
                  <DescriptionItem title="Crops Planted">
                    {pasture.pastureCropData?.map((data, i) => (
                      <Badge key={i}>{data.pastureCrop}</Badge>
                    ))}
                  </DescriptionItem>
                </DescriptionContainer>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full ">
                        <thead>
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                            ></th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                            >
                              Area Tilled
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-shark-600">
                          <tr className="divide-x divide-shark-600">
                            <th
                              scope="col"
                              className="py-0.5 text-center px-5 text-sm font-normal text-shark-200 "
                            ></th>

                            <th
                              scope="col"
                              className="py-0.5 pl-4 pr-4  text-sm font-normal text-shark-200 text-center bg-apple-500/30"
                            >
                              Hectares Tilled
                            </th>
                          </tr>
                          {pasture.pastureCropData &&
                            pasture.pastureCropData.map((cropInfoData, i) => (
                              <tr key={i} className="divide-x divide-shark-600">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                  {cropInfoData.pastureCrop}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center sm:pr-0">
                                  {Number(cropInfoData.areaTilled || 0).toFixed(
                                    3
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-2 border-t border-shark-700" />
                <BaselineEmissionAttachments
                  title="Pasture"
                  attachments={pastureAttachment}
                  year={year}
                  variant={variant}
                  loading={pastureAttachmentLoading}
                />
              </div>
            ) : (
              <EmptyPlaceholder>
                Pasture Data was not submitted for this year.
              </EmptyPlaceholder>
            )}
          </>
        );
      }
    } else {
      return (
        <EmptyPlaceholder>
          Residue, Tillage, and Soil Landscape Modification Emissions is not
          available because the grower has not submitted their Baseline
          Emissions Data.
        </EmptyPlaceholder>
      );
    }
  }, [
    variant,
    activityYears,
    selectedTab,
    emissionData.residueTillageAndLandscapeModificationEmission,
    period,
    farmInfo.carbonRegMonth,
    cropTillageData,
    soilLandscapeData,
    cropInformationData,
    coverCropData,
    pastureData,
    soilLandscapeAttachment,
    soilLandscapeAttachmentLoading,
    cropInformationAttachment,
    cropInformationAttachmentLoading,
    cropTillageAttachment,
    cropTillageAttachmentLoading,
    coverCropAttachment,
    coverCropAttachmentLoading,
    pastureAttachment,
    pastureAttachmentLoading,
  ]);
  return (
    <>
      <p className="text-base font-semibold text-apple-400 mb-4">
        Residue, Tillage, and Soil Landscape Modification Emissions
      </p>
      <nav className="flex" aria-label="Tabs">
        {[
          variant === EmissionVariant.OnGoing
            ? "Period Data"
            : activityYears
                ?.sort((a, b) => b - a)
                .map((year) => `${year} Data`),
          "Summary",
        ]
          .flat()
          .map((tab, i) => (
            <button
              key={i}
              onClick={() => setSelectedTab(i)}
              className={`${
                selectedTab === i
                  ? "bg-shark-900 text-apple-400 font-semibold border-x border-t border-shark-600"
                  : "text-shark-200 hover:text-shark-50"
              } px-4 py-2 font-medium text-sm rounded-t-md focus:outline-none`}
            >
              {tab}
            </button>
          ))}
      </nav>

      <div
        className={`bg-shark-900 ${
          selectedTab === 0 ? "rounded-tr-md rounded-b-md" : "rounded-md"
        } p-4 border border-shark-600`}
      >
        {RenderComponent}
      </div>
    </>
  );
}
