interface BadgeProps {
  children: React.ReactNode | string;
  variant?: "success" | "warning" | "danger" | "pending" | "disabled";
}

export default function Badge({ children, variant }: BadgeProps) {
  return variant === "success" ? (
    <p className="text-lime-300 bg-green-500/20 ring-green-600/20 rounded-lg whitespace-wrap  px-1.5 py-0.5 text-[10px] leading-3 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : variant === "danger" ? (
    <p className="text-red-300 bg-red-500/20 ring-red-600/20 rounded-lg whitespace-wrap  px-1.5 py-0.5 text-[10px] leading-3 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : variant === "pending" ? (
    <p className="text-blue-300 bg-blue-500/20 ring-blue-600/20 rounded-lg whitespace-wrap  px-1.5 py-0.5 text-[10px] leading-3 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : variant === "disabled" ? (
    <p className="text-shark-300 bg-shark-300/20 ring-shark-200/20 rounded-lg whitespace-wrap  px-1.5 py-0.5 text-[10px] leading-3 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : (
    <p className="text-yellow-300 bg-yellow-500/20 ring-yellow-600/20 rounded-lg whitespace-wrap  px-1.5 py-0.5 text-[10px] leading-3 font-semibold ring-1 ring-inset">
      {children}
    </p>
  );
}
