interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: number;
}
export default function SelectInput({ label, ...props }: Props) {
  return (
    <div className="my-2">
      <label className="block text-sm font-medium leading-6 text-shark-200">
        {label}
      </label>
      <div className="mt-1">
        <select
          {...props}
          className={`block w-full rounded-xl border-0 py-1 px-2  text-shark-50 ring-1 ring-inset ${
            props.error ? "ring-red-500" : "ring-shark-500"
          } focus:ring-1 focus:ring-apple-500 text-sm sm:text-base sm:leading-6 ${
            props.disabled ? "bg-transparent" : "bg-shark-800"
          }`}
        />
        {props.error ? (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            This field is required!
          </p>
        ) : null}
      </div>
    </div>
  );
}
