import { useEffect, useState } from "react";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import { ActivityInfo } from "../../types";

export default function useProjectBaselineActivityInfo(
  projectId?: string,
  emissionActivityId?: string
) {
  const [emissionActivityInfo, setEmissionActivityInfo] = useState<
    ActivityInfo | undefined
  >({} as ActivityInfo);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getProjectBaselineActivityInfo() {
      if (!projectId || !emissionActivityId) {
        setLoading(false);
        return;
      }
      const response = await api.getActivityInfo(
        +projectId,
        +emissionActivityId
      );
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        setEmissionActivityInfo(undefined);
        return;
      }
      setEmissionActivityInfo(response.emissionActivityInfo);
      setLoading(false);
    }

    getProjectBaselineActivityInfo();
  }, [emissionActivityId, projectId]);

  return { emissionActivityInfo, loading, error };
}
