import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import Button from "./button";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  onPressAdd?: () => void;
  actionButtonName?: string;
  actionButtonType?: "danger" | "primary" | "secondary" | "disabled";
  setActionButtonType?: React.Dispatch<React.SetStateAction<string>>;
  dontShowHeader?: boolean;
  onPressCancel?: () => void;
}
export default function Modal({
  children,
  open,
  setOpen,
  title,
  onPressAdd,
  onPressCancel,
  actionButtonType,
  dontShowHeader,
  actionButtonName,
}: ModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 overflow-y-auto"
        onClose={() => {
          return;
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-shark-600 bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-auto rounded-xl bg-shark-900 text-shark-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl sm:p-6 z-0">
                <>
                  <div
                    className={
                      dontShowHeader
                        ? "flex flex-row-reverse"
                        : "flex flex-row justify-between items-center w-full"
                    }
                  >
                    {dontShowHeader ? null : (
                      <h1 className="text-xl font-bold text-apple-600 sm:mr-4">
                        {title}
                      </h1>
                    )}

                    <button
                      type="button"
                      className="rounded-md flex flex-row text-shark-400 hover:text-shark-300 focus:outline-none focus:ring-2 focus:ring-apple-500 focus:ring-offset-0"
                      onClick={() =>
                        onPressCancel !== undefined
                          ? onPressCancel()
                          : setOpen(false)
                      }
                    >
                      {/* <span>Close</span> */}
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-5 sm:mt-4 flex-col px-2">{children}</div>
                  <div
                    key={actionButtonType}
                    className="mt-5 sm:mt-8 flex flex-row-reverse justify-center sm:justify-start"
                  >
                    <div>
                      {actionButtonType === "disabled" ? (
                        <Button
                          disabled={true}
                          variant="disabled"
                          onClick={onPressAdd}
                        >
                          {actionButtonName ?? "Add"}
                        </Button>
                      ) : actionButtonType === "danger" ? (
                        <Button variant="danger" onClick={onPressAdd}>
                          {actionButtonName ?? "Add"}
                        </Button>
                      ) : (
                        <Button onClick={onPressAdd}>
                          {actionButtonName ?? "Add"}
                        </Button>
                      )}

                      {/* </div> */}
                      {/* <div> */}
                      {/* <button
                      type="button"
                      className=" w-full inline-flex justify-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={onPressCancel}
                    >
                      Cancel
                    </button> */}
                    </div>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
