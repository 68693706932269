const authLoginUrl = process.env.REACT_APP_AUTH_LOGIN_URL;
if (authLoginUrl == null || authLoginUrl === "") {
  throw new Error("REACT_APP_AUTH_LOGIN_URL not set");
}

const authLogoutUrl = process.env.REACT_APP_AUTH_LOGOUT_URL;
if (authLogoutUrl == null || authLogoutUrl === "") {
  throw new Error("REACT_APP_AUTH_LOGOUT_URL not set");
}

export const config = {
  api: process.env.REACT_APP_API_URL,
  attachmentUrlPrefix:
    process.env.NODE_ENV === "development"
      ? "https://storage.googleapis.com/cpms_dev/"
      : process.env.REACT_APP_ASSET_URL,
  authLoginUrl,
  authLogoutUrl
};
