interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
  variant?:
    | "primary"
    | "outline"
    | "disabled"
    | "danger"
    | "secondary"
    | "black-outline";
}
export default function Button({
  children,
  size = "sm",
  variant,
  ...props
}: ButtonProps) {
  return variant === "black-outline" ? (
    <div className="flex">
      <button
        {...props}
        type="button"
        className={`flex w-full justify-center rounded-xl hover:bg-black bg-transparent border-2 border-black ${
          size === "sm" ? "py-1 px-3 text-xxs" : "py-1.5 px-5 text-xs"
        } hover:text-white hover:border-black font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black`}
      >
        {children}
      </button>
    </div>
  ) : variant === "outline" ? (
    <div className="flex">
      <button
        {...props}
        type="button"
        className={`flex w-full justify-center rounded-xl hover:bg-apple-500 bg-transparent border-[1.5px] border-apple-500 ${
          size === "sm" ? "py-1 px-3 text-xxs" : "py-1.5 px-5 text-xs"
        } hover:text-white hover:border-apple-600 font-semibold text-apple-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-500`}
      >
        {children}
      </button>
    </div>
  ) : props.disabled || variant === "disabled" ? (
    <div className="flex ">
      <button
        {...props}
        type="button"
        disabled
        className="flex w-full justify-center rounded-xl bg-gray-200  py-1 px-3 text-xs font-semibold text-gray-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
      >
        {children}
      </button>
    </div>
  ) : (
    <div>
      <button
        {...props}
        type="button"
        className="flex w-full justify-center rounded-xl hover:bg-apple-400 bg-apple-500 py-1 px-3  text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
      >
        {children}
      </button>
    </div>
  );
}
