import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Button from "../components/button";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { api } from "../api";
import Loading from "../components/loading";
import { FetchError } from "../lib/fetcher";
import { Grower } from "../types";

export default function Growers() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [growers, setGrowers] = useState<Grower[]>([]);

  const sortByLegalName = (a: Grower, b: Grower) => {
    return a.businessInfo.legalName.localeCompare(b.businessInfo.legalName);
  };

  useEffect(() => {
    async function getGrowers() {
      setLoading(true);
      const response = await api.getAllGrowers();
      if (response instanceof FetchError) {
        setLoading(false);
      } else {
        const growersSorted = response.growers.sort(sortByLegalName);
        setGrowers(growersSorted);
        setLoading(false);
      }
    }

    getGrowers(); // remove the return statement
  }, []);

  const filteredGrowers = useMemo(() => {
    if (!search) return growers;
    return growers.filter((grower) => {
      return (
        grower?.businessInfo?.legalName
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        grower?.businessInfo?.abnAcnNumber
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
    });
  }, [growers, search]);

  return (
    <div className="flex w-full flex-col overflow-y-hidden">
      <header className="flex flex-row justify-between items-center shadow-lg p-4">
        <h1 className="text-2xl font-semibold">Growers</h1>
        <div className="flex flex-row space-x-2">
          <Link to="/growers/new">
            <Button>Create Grower</Button>
          </Link>
        </div>
      </header>
      <main className="flex flex-col flex-1 px-8  bg-[#202125] h-full overflow-y-hidden">
        <div className="flex flex-col w-full">
          <div className="relative mt-2 rounded-full shadow-sm w-full bg-transparent">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0  h-full w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full bg-transparent rounded-full border-0 py-1.5 pl-10 text-gray-100 ring-1 ring-inset ring-[#5f6368] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-apple-600 text-base sm:leading-6"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col mt-4 w-full">
          {loading || !growers ? (
            <Loading />
          ) : (
            <div className="flex flex-col ">
              {filteredGrowers.length > 0 ? (
                filteredGrowers.map((grower, i) => (
                  <Link
                    to={`/growers/${grower.id}?tab=1`}
                    key={i}
                    className="border-b border-shark-600 py-3"
                  >
                    <div className="flex flex-row justify-between items-center gap-x-4 ">
                      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-apple-200">
                        <span className="text-xs font-medium leading-none text-apple-800">
                          {grower?.businessInfo.legalName
                            .charAt(0)
                            .toUpperCase()}
                        </span>
                      </span>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-white">
                          {grower?.businessInfo.legalName}
                        </p>
                        <p className="text-xs leading-6 text-white">
                          {grower?.businessInfo.abnAcnNumber}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p>No Growers</p>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
