import { useEffect, useState } from "react";
import { KYB } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";

export default function useKYB(kybId?: number) {
  const [kyb, setKYB] = useState<KYB>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function getProject() {
      setLoading(true);
      if (!kybId) {
        setLoading(false);
        return;
      }
      const response = await api.getKYB(+kybId);
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setKYB(response.kyb);
      setLoading(false);
    }

    getProject(); // remove the return statement
  }, [kybId]);

  return { kyb, loading, error };
}
