interface Props {
  title: string;
  variant?: "primary" | "alternate";
  size?: "small" | "large";
  children: React.ReactNode;
}
export default function DescriptionItem({
  title,
  children,
  variant,
  size,
}: Props) {
  return size === "large" ? (
    <div
      className={`${
        variant === "alternate" ? "bg-shark-800" : "bg-shark-900"
      } px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3`}
    >
      <dt className="text-xs leading-6 text-shark-100">{title}</dt>
      <dd className=" mt-1 text-sm leading-6 text-shark-50 font-semibold xl:col-span-1 xl:mt-0 break-words">
        <div className="flex w-full flex-wrap gap-2">{children}</div>
      </dd>
    </div>
  ) : (
    <div
      className={`${
        variant === "alternate" ? "bg-shark-800" : "bg-shark-900"
      } px-4 py-1.5 xl:grid xl:grid-cols-2 xl:gap-4 xl:px-3 items-center`}
    >
      <dt className="text-xs leading-6 text-shark-100">{title}</dt>
      <dd className=" mt-1 text-sm leading-6 text-shark-50 font-semibold xl:col-span-1 xl:mt-0 break-words">
        <div className="flex w-full flex-wrap gap-2">{children}</div>
      </dd>
    </div>
  );
}
