import { useMemo } from "react";
import { Attachment, TrustData } from "../../types";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import Button from "../../components/button";
import { config } from "../../config";
import Badge from "../../components/badge";

interface TrustDetailsProps {
  data?: TrustData;
  attachments?: Attachment[];
}

export default function TrustDetailsView({
  data,
  attachments,
}: TrustDetailsProps) {
  const settlor = useMemo(() => {
    if (!data || !data.entityPeople) return [];
    return data.entityPeople.filter((p) => p.personType === "Settlor");
  }, [data]);

  const trustees = useMemo(() => {
    if (!data || !data.entityPeople) return [];
    return data.entityPeople.filter((p) => p.personType === "Trustee");
  }, [data]);

  const beneficiaries = useMemo(() => {
    if (!data || !data.entityPeople) return [];
    return data.entityPeople.filter((p) => p.personType === "Beneficiary");
  }, [data]);

  return (
    <div className="w-full p-4">
      {!data ? (
        <p>No Trust Data</p>
      ) : (
        <div className="flex flex-col">
          <p className="text-base text-apple-400 font-semibold">
            Trust Information
          </p>
          <div className="bg-shark-700 my-2 p-4 rounded-lg">
            <DescriptionContainer>
              <DescriptionItem title="Trust Type" variant="alternate">
                {data.trustType ?? ""}
              </DescriptionItem>
              {data.trustType === "Other" ? (
                <DescriptionItem title="Other Trust Type" variant="alternate">
                  {data.trustTypeOther ?? ""}
                </DescriptionItem>
              ) : null}
              <DescriptionItem title="Is the Trust settled sum less than $10,000 AUD?">
                {data.trustSettleSumOver10k === false ? (
                  "The settled sum was equal to or more than $10,000 AUD"
                ) : data.trustSettleSumOver10k === true ? (
                  "The settled sum was less than $10,000 AUD"
                ) : (
                  <Badge variant="disabled">Unknown</Badge>
                )}
              </DescriptionItem>
            </DescriptionContainer>
          </div>

          {data.trustSettleSumOver10k === false && (
            <>
              <div className="border-b border-shark-700 my-4" />

              <p className="text-base text-apple-400 font-semibold">
                Settlor Information
              </p>
              <div className="bg-shark-700 my-2 p-4 rounded-lg">
                <DescriptionContainer>
                  <DescriptionItem
                    title="Is the Trust settlor deceased?"
                    variant="alternate"
                  >
                    {data.trustSettlorDeceased === true ? (
                      "The trust settlor is deceased"
                    ) : data.trustSettlorDeceased === false ? (
                      "The trust settlor is alive"
                    ) : (
                      <Badge variant="disabled">Unknown</Badge>
                    )}
                  </DescriptionItem>
                  {data.trustSettlorDeceased === false && settlor.length ? (
                    <DescriptionItem title="Settlor" variant="alternate">
                      <div>
                        <DescriptionItem title="Legal Name" variant="alternate">
                          {settlor[0].legalName ?? ""}
                        </DescriptionItem>
                        <DescriptionItem title="Email">
                          {settlor[0].email ?? ""}
                        </DescriptionItem>
                      </div>
                    </DescriptionItem>
                  ) : null}
                </DescriptionContainer>
              </div>
            </>
          )}

          <div className="border-b border-shark-700 my-4" />
          <p className="text-base text-apple-400 font-semibold">
            Beneficiaries ({beneficiaries.length}){" "}
          </p>
          {beneficiaries.map((person, i) => (
            <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
              <DescriptionContainer key={i}>
                <DescriptionItem title="Legal Name" variant="alternate">
                  {person.legalName ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Email">
                  {person.email ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Ownership Percentage">
                  {person.ownershipPercentage ?? ""}
                  {person.ownershipPercentage ? "%" : ""}
                </DescriptionItem>
              </DescriptionContainer>
            </div>
          ))}

          <div className="border-b border-shark-700 my-4" />
          <p className="text-base text-apple-400 font-semibold">
            Trustees ({trustees.length}){" "}
          </p>
          {trustees.map((person, i) => (
            <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
              <DescriptionContainer key={i}>
                <DescriptionItem title="Legal Name" variant="alternate">
                  {person.legalName ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Email">
                  {person.email ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Entity Type" variant="alternate">
                  {person.entityType ?? ""}
                </DescriptionItem>
              </DescriptionContainer>
            </div>
          ))}
          <div className="border-b border-shark-700 my-4" />
          <p className="text-base text-apple-400 font-semibold">
            Attachments ({(attachments ?? []).length}){" "}
          </p>
          {!attachments || !attachments.length ? null : (
            <div className="flex flex-col">
              {attachments.map((attachment, i) => (
                <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
                  <li className="flex items-center justify-between pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-shark-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {attachment.attachmentType}
                        </span>
                      </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        target="_blank"
                        href={`${config.attachmentUrlPrefix ?? ""}${
                          attachment.attachmentUrl
                        }`}
                        rel="noreferrer"
                      >
                        <Button variant="outline">View</Button>
                      </a>
                    </div>
                  </li>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
