import { useMemo } from "react";
import Badge from "../../../components/badge";
import DescriptionContainer from "../../../components/description-container";
import DescriptionItem from "../../../components/description-item";
import Divider from "../../../components/divider";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { RestrictedActivities } from "../../../constants";
import { RestrictedActivity } from "../../../types";

interface RestrictedActivitiesProps {
  resrictedActivityData?: RestrictedActivity;
}

export default function RestrictedActivitiesView({
  resrictedActivityData,
}: RestrictedActivitiesProps) {
  const filteredActivities = useMemo(() => {
    return resrictedActivityData?.restrictedActivityData?.filter(
      (activity) =>
        activity.restrictedActivityType !==
        RestrictedActivities.IDidNotConductAnyRestrictedActivities
    );
  }, [resrictedActivityData]);

  return (
    <>
      <p className="text-base font-semibold text-apple-400 mb-4">
        Restricted Activities
      </p>
      <div className=" p-4 bg-shark-800 rounded-md border border-shark-700">
        {resrictedActivityData ? (
          <>
            <DescriptionContainer>
              <DescriptionItem
                title="Activities that were conducted in
                the project area"
              >
                {resrictedActivityData?.restrictedActivityData?.map(
                  (activity, index) => (
                    <div key={index} className="grid grid-cols-1">
                      <Badge
                        key={index}
                        variant={
                          activity.restrictedActivityType ===
                          RestrictedActivities.IDidNotConductAnyRestrictedActivities
                            ? "success"
                            : "warning"
                        }
                      >
                        {activity.restrictedActivityType}
                      </Badge>
                    </div>
                  )
                )}
              </DescriptionItem>
            </DescriptionContainer>

            <Divider />

            {filteredActivities?.map((activity, index) => (
              <div key={index}>
                <p className="text-sm font-semibold text-apple-400 mb-4">
                  {activity.restrictedActivityType}
                </p>
                {activity.restrictedActivityData ? (
                  <>
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full">
                            <thead className="divide-y-reverse divide-shark-600">
                              <tr className="divide-x  divide-shark-600">
                                <th
                                  scope="col"
                                  className="py-3.5 text-center px-5 text-sm font-semibold text-shark-200 "
                                ></th>

                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-shark-200 text-center"
                                >
                                  Date of Activity
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-4  text-sm font-semibold text-shark-200 sm:pr-0 text-center "
                                >
                                  Please describe the reason(s) for conducting
                                  the activity.
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-shark-600">
                              <tr className="divide-x divide-shark-600"></tr>
                              {activity.restrictedActivityData?.map(
                                (data, i) => (
                                  <tr
                                    key={i}
                                    className="divide-x divide-shark-600"
                                  >
                                    <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-shark-200 text-center">
                                      {i + 1}
                                    </td>

                                    <td className="whitespace-nowrap p-4 text-sm text-shark-50 font-semibold text-center">
                                      {data.activityDate}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-shark-50 font-semibold text-center sm:pr-0">
                                      {data.activityReason}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <EmptyPlaceholder>
                    No data available for this activity
                  </EmptyPlaceholder>
                )}
                {index <
                  (resrictedActivityData?.restrictedActivityData?.length || 1) -
                    1 && <Divider />}
              </div>
            ))}
          </>
        ) : (
          <EmptyPlaceholder>
            Restricted Activities data not available because the grower has not
            submitted their project data yet.
          </EmptyPlaceholder>
        )}
      </div>
    </>
  );
}
