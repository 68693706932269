interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  error?: number;
  inputWidth?: string;
  errormesage?: string;
}

export default function TextInput({ label, ...props }: Props) {
  return (
    <div className="my-2">
      <label className="block text-sm font-medium leading-6 text-shark-200">
        {label}
      </label>
      <div className="mt-1">
        <div className="relative rounded-lg shadow-sm bg-transparent">
          <input
            {...props}
            className={`block w-${
              props.inputWidth ? `[${props.inputWidth}]` : "full"
            } rounded-lg border-0 py-1 px-2 text-shark-50 ring-1 ring-inset ${
              props.error === 1 ? "ring-red-500" : "ring-shark-500"
            } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-apple-500 text-base sm:leading-6
            ${props.disabled ? "bg-transparent" : "bg-shark-800"}`}
          />
        </div>
        {props.error ? (
          <p className="mt-2 text-sm text-red-400" id="email-error">
            {props.errormesage ? props.errormesage : "This field is required!"}
          </p>
        ) : null}
      </div>
    </div>
  );
}
