import { useEffect, useState } from "react";
import { User } from "../types";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";

export default function useUser(userId?: number) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getGrowers() {
      if (!userId) return;
      setLoading(true);
      const response = await api.getUser(userId);
      if (response instanceof FetchError) {
        setLoading(false);
        return;
      }
      setUser(response.user);
      setLoading(false);
    }

    getGrowers(); // remove the return statement
  }, [userId]);

  return { user, loading };
}
