import Loading from "../components/loading";
import useProjectStats from "../hooks/useProjectStats";

export default function Home() {
  const stats = useProjectStats();
  return (
    <div className="flex w-full flex-col p-12">
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-4xl sm:tracking-tight">
            SecondCrop
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <a
            href="https://loambio.slack.com/archives/C06QWT0QEHJ"
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center rounded-md bg-apple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 hover:bg-apple-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-apple-500"
          >
            Platform Support
          </a>
        </div>
      </div>
      <div className="relative py-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
      {stats.error && (
        <div>
          <h3 className="text-base font-semibold leading-6 text-white">
            Error
          </h3>
          <p>{stats.error}</p>
        </div>
      )}
      {!stats.error && stats.loading && <Loading />}
      {!stats.error && !stats.loading && (
        <div>
          <h3 className="text-base font-semibold leading-6 text-white">
            SecondCrop statistics
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
            {stats.data.map((item) => (
              <div
                key={item.name}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {item.stat}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      )}
    </div>
  );
}
