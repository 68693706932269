import { Fragment, useMemo, useState } from "react";
import { EntityPerson } from "../../types";
import Checkbox from "../../components/checkbox-input";
import Badge from "../../components/badge";
import Button from "../../components/button";
import Loading from "../../components/loading";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import useKYB from "../../hooks/useKyb";
import Modal from "../../components/modal";
import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import EditEntityPerson from "./edit-entity-person";
import { Menu, Transition } from "@headlessui/react";

interface PeopleListViewProps {
  allKybPeople?: EntityPerson[];
  kybId?: number;
}
export default function PeopleListView({
  allKybPeople,
  kybId,
}: PeopleListViewProps) {
  const [selectedKycInvites, setSelectedKycInvites] = useState<EntityPerson[]>(
    []
  );
  const { kyb, loading: kybLoading } = useKYB(+kybId!);
  const [selectedTab, setSelectedTab] = useState<"all" | "kyc">("kyc");
  const [warning, setWarning] = useState(false);
  const [showEditPerson, setShowEditPerson] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<EntityPerson>();

  const kycPeople = useMemo(() => {
    return allKybPeople?.filter((person) => person.toBeKYC);
  }, [allKybPeople]);

  async function sendKYCInvite() {
    if (!kybId || !selectedKycInvites) {
      return;
    }

    const response = await api.postKYCInvite(kybId, selectedKycInvites);
    if (response instanceof FetchError) {
      alert(response);
      return;
    }

    window.location.reload();

    setSelectedKycInvites([]);
  }

  async function resendEmail(person: EntityPerson) {
    if (!person.kybId || !person.kycId) {
      alert("KYC invite doesnt exist for this person");
    }

    const response = await api.resendKYCInvite(person);
    if (response instanceof FetchError) {
      alert(response);
      return;
    }
    alert("Email sent successfully");
  }

  function WarningDialog() {
    return (
      <Modal
        open={warning}
        setOpen={setWarning}
        dontShowHeader
        actionButtonName="Send Invite"
        onPressCancel={() => {
          setWarning(false);
        }}
        onPressAdd={() => {
          setWarning(false);
          sendKYCInvite();
        }}
      >
        <div className="sm:flex sm:items-start ">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-5 w-5 text-yellow-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <div className="h3 text-lg font-semibold leading-6 text-yellow-700">
              Attention!
            </div>
            <div className="mt-2 text-shark-200">
              <p className="text-sm">
                Are you sure you want to send KYC invite to the selected people?
                <span className="font-bold text-apple-400">
                  {selectedKycInvites.map((p, i) => (
                    <li key={i}>{p.legalName}</li>
                  ))}
                </span>
              </p>
              <p className="text-sm">
                This action will also send an invite email to these selected
                people.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function EditMenu({ person }: { person: EntityPerson }) {
    return (
      <div className="flex items-center gap-x-4 lg:gap-x-6 z-50 overflow-visible">
        <Menu as="div" className="relative">
          <Menu.Button className=" flex items-center p-1">
            <span className="flex items-center">
              <EllipsisVerticalIcon
                className="ml-2 h-5 w-5 text-gray-100"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-1 w-32 origin-top-right rounded-xl bg-black py-1 shadow-lg ring-1 ring-gray-900/5 focus:outline-none overflow-visible">
              <Menu.Item>
                <button
                  className="text-shark-50 hover:text-apple-400 px-3 py-1 text-sm z-50"
                  onClick={() => {
                    setSelectedPerson(person);
                    setShowEditPerson(true);
                  }}
                >
                  Change Email
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  }

  return (
    <div>
      <WarningDialog />

      <EditEntityPerson
        setOpen={setShowEditPerson}
        open={showEditPerson}
        person={selectedPerson}
      />

      <div className="flex flex-col flex-1 z-10 w-full bg-shark-900 rounded-xl p-4">
        <nav className="flex flex-row w-full justify-between border-b border-shark-500">
          <p
            className={`w-1/2 text-center cursor-pointer
                    ${
                      selectedTab === "kyc"
                        ? "border-apple-400 text-apple-400 font-semibold"
                        : "border-transparent text-shark-200 hover:border-shark-400 hover:text-shark-400"
                    }
                     whitespace-nowrap border-b-2 px-1 pb-2 text-sm `}
            onClick={() => setSelectedTab("kyc")}
          >
            KYC People
          </p>
          <p
            className={`w-1/2 text-center cursor-pointer
                    ${
                      selectedTab === "all"
                        ? "border-apple-400 text-apple-400 font-semibold"
                        : "border-transparent text-shark-200 hover:border-shark-400 hover:text-shark-400"
                    }
                     whitespace-nowrap border-b-2 px-1 pb-2 text-sm `}
            onClick={() => setSelectedTab("all")}
          >
            All People
          </p>
        </nav>
        {!allKybPeople || kybLoading ? (
          <Loading />
        ) : (
          <div className="flex flex-col w-full overflow-visible z-10">
            {allKybPeople.length ? (
              <div className="flex flex-col w-full">
                {(selectedTab === "all" ? allKybPeople : kycPeople)?.map(
                  (person, i) => (
                    <div
                      className={`flex flex-col w-full ${
                        i < allKybPeople.length - 1
                          ? "border-b border-shark-700"
                          : ""
                      } py-3`}
                      key={i}
                    >
                      <div className="flex flex-row w-full px-1 items-center z-10">
                        <Checkbox
                          disabled={
                            person.kycStatus !== "not invited" ||
                            kyb?.kybStatus?.toLowerCase() !== "under review"
                          }
                          checked={
                            selectedKycInvites.some(
                              (p) => p.id === person.id
                            ) || person.kycStatus !== "not invited"
                          }
                          onChange={(e) => {
                            e.target.checked
                              ? setSelectedKycInvites([
                                  ...selectedKycInvites,
                                  person,
                                ])
                              : setSelectedKycInvites(
                                  selectedKycInvites.filter(
                                    (p) => p.id !== person.id
                                  )
                                );
                          }}
                        />

                        <div className="flex flex-col w-full ml-4 overflow-visible z-10">
                          <div className="flex flex-row justify-between items-center w-full">
                            <div className="flex flex-row items-start">
                              <div className="flex flex-col">
                                <p className="text-base font-semibold text-shark-50">
                                  {person.legalName}
                                </p>
                                <p className="text-xs text-shark-300">
                                  {person.email}
                                </p>
                              </div>
                            </div>

                            <EditMenu person={person} />
                          </div>

                          <div className="flex flex-row justify-between items-center mt-1 pt-1 w-full">
                            {person.personType ? (
                              <div className="flex flex-col">
                                <p className="text-[0.6rem] m-0 p-0 text-apple-400">
                                  PERSON TYPE
                                </p>
                                <div className="flex">
                                  <Badge>{person.personType}</Badge>
                                </div>
                              </div>
                            ) : null}
                            {person.kycStatus !== "not invited" &&
                            kyb?.kybStatus?.toLowerCase() === "under review" ? (
                              <div>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => resendEmail(person)}
                                >
                                  Resend Email
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div className="flex flex-row justify-center mt-4 gap-x-2">
                  <div className="">
                    <Button
                      onClick={() => setWarning(true)}
                      variant={
                        selectedKycInvites.length > 0 ? "primary" : "disabled"
                      }
                    >
                      Send KYC Invite ({selectedKycInvites.length} selected)
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-full p-3">
                <p className="text-base text-shark-100">No People</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
