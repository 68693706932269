import { useMemo } from "react";
import Badge from "../../components/badge";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import { GrowerPEP } from "../../types";

interface PEPViewProps {
  data?: GrowerPEP;
}

export default function PEPView({ data }: PEPViewProps) {
  const pepPeople = useMemo(() => {
    if (!data || !data.entityPeople) return [];
    return data.entityPeople;
  }, [data]);
  return (
    <div className="w-full p-4">
      <p className="text-2xl font-semibold text-shark-100 pb-3">
        Politically Exposed Person
      </p>
      <p className="text-base text-apple-400 font-semibold">PEP Information</p>
      {!data ? (
        <p>No PEP Data</p>
      ) : (
        <div className="flex flex-col">
          <div className="bg-shark-700 my-2 p-4 rounded-lg">
            <DescriptionContainer>
              <DescriptionItem title="Is PEP" variant="alternate">
                {data.isPep ? (
                  <div className="flex">
                    <Badge>Yes</Badge>
                  </div>
                ) : (
                  <div className="flex">
                    <Badge>No</Badge>
                  </div>
                )}
              </DescriptionItem>
            </DescriptionContainer>
          </div>
          <div className="border-b border-shark-700 my-4" />
          <p className="text-base text-apple-400 font-semibold">
            Politicallly Exposed People ({(pepPeople ?? []).length}){" "}
          </p>
          {pepPeople.map((person, i) => (
            <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
              <DescriptionContainer key={i}>
                <DescriptionItem title="Legal Name" variant="alternate">
                  {person.legalName ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Position">
                  {person.position ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Source of Funds" variant="alternate">
                  {person.sourceOfFunds ?? ""}
                </DescriptionItem>
                <DescriptionItem title="Source of Wealth">
                  {person.sourceOfWealth ?? ""}
                </DescriptionItem>
              </DescriptionContainer>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
