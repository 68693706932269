import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Button from "../components/button";
import { useEffect, useMemo, useState } from "react";
import { Project } from "../types";
import Loading from "../components/loading";
import { Link } from "react-router-dom";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";
import { Month } from "../constants";

export default function Projects() {
  const [projectLoading, setProjectLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    async function getProjects() {
      setProjectLoading(true);
      const response = await api.getAllProjects();
      if (response instanceof FetchError) {
        setProjectLoading(false);
        return;
      }
      response.projects.sort((a, b) => a.projectInfo.name!.localeCompare(b.projectInfo.name!))
      setProjects(response.projects);
      setProjectLoading(false);
    }

    getProjects();
  }, []);

  const filteredProjects = useMemo(() => {
    if (!search) return projects;
    return projects.filter((project) => {
      return project?.projectInfo?.name
        ?.toLowerCase()
        .includes(search.toLowerCase());
    });
  }, [projects, search]);

  return (
    <div className="flex w-full flex-col overflow-y-hidden">
      <header className="flex flex-row justify-between items-center shadow-lg p-4">
        <h1 className="text-2xl font-semibold">Projects</h1>
        <div className="flex flex-row space-x-2">
          <Link to={"/projects/new"}>
            <Button>Create Project</Button>
          </Link>
        </div>
      </header>
      <main className="flex flex-col flex-1 px-8  bg-[#202125] h-full overflow-y-hidden">
        <div className="flex flex-col w-full">
          <div className="relative mt-2 rounded-full shadow-sm w-full bg-transparent">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0  h-full w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="search"
              name="search"
              id="search"
              className="block w-full bg-transparent rounded-full border-0 py-1.5 pl-10 text-gray-100 ring-1 ring-inset ring-[#5f6368] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-apple-600 text-base sm:leading-6"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
        <div className="flex flex-col mt-4 w-full">
          {projectLoading || !filteredProjects ? (
            <Loading />
          ) : (
            <div className="flex flex-col ">
              {filteredProjects.length > 0 ? (
                filteredProjects
                  .filter(
                    (project) =>
                      project.projectInfo.carbonRegMonth !== null &&
                      project.projectInfo.carbonRegYear !== null
                  )
                  .map((project, i) => (
                    <Link
                      to={`/projects/${project.id}?tab=1`}
                      key={i}
                      className="border-b border-shark-600 py-3"
                    >
                      <div className="flex flex-row  items-center gap-x-4 ">
                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-apple-200">
                          <span className="text-xs font-medium leading-none text-apple-800">
                            {project?.projectInfo?.name
                              ? project?.projectInfo?.name
                                  .charAt(0)
                                  .toUpperCase()
                              : ""}
                          </span>
                        </span>
                        <div className="min-w-0 flex-auto">
                          <p className="text-base font-semibold leading-6 text-white">
                            {project?.projectInfo?.name}
                          </p>
                          <div className="flex flex-row gap-x-8 text-sm">
                            <div className="flex justify-between gap-x-2 py-1">
                              <dt className="text-shark-400 ">
                                Project Registration Date
                              </dt>
                              <dd className="text-shark-50">
                                {
                                  Month[
                                    (project.projectInfo.carbonRegMonth ?? 0) -
                                      1
                                  ]
                                }{" "}
                                {project.projectInfo.carbonRegYear}
                              </dd>
                            </div>
                            <div className="flex justify-between gap-x-2 py-1">
                              <dt className="text-shark-400">
                                Project Location
                              </dt>
                              <dd className="text-shark-50">
                                {project.projectInfo.address?.stateProvince},{" "}
                                {project.projectInfo.address?.country}
                              </dd>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
              ) : (
                <p>No Projects</p>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
