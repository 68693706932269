import Badge from "../../../components/badge";
import DescriptionContainer from "../../../components/description-container";
import DescriptionItem from "../../../components/description-item";
import DescriptionItemYesNo from "../../../components/description-item-yesno";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import Loading from "../../../components/loading";
import { AnimalType, EmissionVariant } from "../../../constants";
import { GetObjectValue } from "../../../helpers/getObjectValue";
import {
  EmissionActivityInfo,
  ActivityInfo as IActivityInfo,
} from "../../../types";

interface ActivityInfoProps {
  farmActivityInfo: IActivityInfo | undefined;
  activityInfoLoading: boolean;
  emissionActivityInfo: EmissionActivityInfo | undefined;
}

export default function ActivityInfo({
  farmActivityInfo,
  activityInfoLoading,
  emissionActivityInfo,
}: ActivityInfoProps) {
  return (
    <div className="bg-shark-900 border border-shark-700 rounded-lg p-4">
      {activityInfoLoading ? (
        <Loading />
      ) : (
        <>
          <p className="text-base font-semibold text-apple-400 mb-4">
            Activity Information
          </p>
          <>
            {farmActivityInfo ? (
              <DescriptionContainer>
                {emissionActivityInfo?.activityType !==
                  EmissionVariant.OnGoing && (
                  <DescriptionItem title="Activity Years">
                    {farmActivityInfo.activityYears.map((year, i) => (
                      <Badge key={i}>{year}</Badge>
                    ))}
                  </DescriptionItem>
                )}
                {emissionActivityInfo?.activityType ===
                  EmissionVariant.OnGoing && (
                  <DescriptionItemYesNo title="Carbon Builder Products">
                    {farmActivityInfo.carbonBuilderProduct}
                  </DescriptionItemYesNo>
                )}
                <DescriptionItemYesNo title="Irrigation" variant="alternate">
                  {farmActivityInfo.irrigation}
                </DescriptionItemYesNo>

                <DescriptionItemYesNo title="Grid Electricty">
                  {farmActivityInfo.gridElectricity}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo title="Liming" variant="alternate">
                  {farmActivityInfo.limeApplication}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo title="Synthetic Fertilizer">
                  {farmActivityInfo.syntheticFertilizer}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo
                  title="Soil Landscape"
                  variant="alternate"
                >
                  {farmActivityInfo.soilLandscape}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo title="Grow Crops">
                  {farmActivityInfo.growCrop}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo title="Crop Tillage" variant="alternate">
                  {farmActivityInfo.tillCrop}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo title="Cover Crops">
                  {farmActivityInfo.coverCrop}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo title="Pasture" variant="alternate">
                  {farmActivityInfo.tillPasture}
                </DescriptionItemYesNo>
                <DescriptionItemYesNo title="Livestock">
                  {farmActivityInfo.livestock}
                </DescriptionItemYesNo>
                <DescriptionItem title="Livestock Animals" variant="alternate">
                  {farmActivityInfo.animalType.map((animal, i) => (
                    <Badge key={i}>
                      {GetObjectValue(AnimalType, animal as AnimalType)}
                    </Badge>
                  ))}
                </DescriptionItem>
                <DescriptionItemYesNo title="Historical Stock Rate">
                  {farmActivityInfo.historicalStockRate}
                </DescriptionItemYesNo>
              </DescriptionContainer>
            ) : (
              <EmptyPlaceholder>
                Activity Information is not available because the grower has not
                submitted their Baseline Emissions Data.
              </EmptyPlaceholder>
            )}
          </>
        </>
      )}
    </div>
  );
}
