import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { getAuth, signOut } from "firebase/auth";
import firebaseApp from "../firebaseConfig";
import { User } from "../types";

interface HeaderProps {
  user: User;
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

export default function Header({ user, setSidebarOpen }: HeaderProps) {
  function onLogout() {
    const auth = getAuth(firebaseApp);
    signOut(auth).then(() => {
      localStorage.removeItem("token");
    });
  }

  return (
    <div className="w-full sticky top-0 z-50 flex h-16 shrink-0 items-center gap-x-4 border-b border-[#5f6368] bg-[#202125] px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 items-center justify-between lg:justify-end">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1">
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-apple-200">
                <span className="text-xs font-medium leading-none text-apple-800">
                  {user.username.charAt(0).toUpperCase()}
                </span>
              </span>
              <span className="hidden lg:flex lg:items-center">
                <span
                  className="ml-2 text-sm font-semibold leading-6 text-gray-100"
                  aria-hidden="true"
                >
                  {user.firstName}
                </span>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-100"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-xl bg-black py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  <a
                    href="/"
                    onClick={() => onLogout()}
                    className="block px-3 py-1 text-sm leading-6 text-white"
                  >
                    Log out
                  </a>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
