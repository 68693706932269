import { useEffect, useMemo, useState } from "react";
import Button from "../../components/button";
import Modal from "../../components/modal";
import TextInput from "../../components/text-input";
import SelectInput from "../../components/select-input";
import {
  EmissionActivityStatus,
  EmissionVariant,
  Month,
} from "../../constants";
import { EmissionActivityInfo } from "../../types";
import { Link, useParams } from "react-router-dom";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import Loading from "../../components/loading";
import useProjectEmissionActivity from "../../hooks/emission/useProjectEmissionActivity";
import Badge from "../../components/badge";
import Divider from "../../components/divider";
import EmptyPlaceholder from "../../components/empty-placeholder";
import useProject from "../../hooks/useProject";
import formatDate from "../../helpers/format-date";
import OverlayLoading from "../../components/overlay-loading";

export default function EmissionActivity() {
  const [loading, setLoading] = useState(false);
  const [emissionInstance, setEmissionInstance] =
    useState<EmissionActivityInfo>();
  const [showEmissionModal, setShowEmissionModal] = useState(false);
  const { projectId } = useParams();
  const {
    allEmissionActivity,
    loading: loadingEmissionActivityInfo,
    mutate,
  } = useProjectEmissionActivity(projectId);
  const { project } = useProject(projectId);

  const baselineEmissions = useMemo(() => {
    if (!allEmissionActivity) {
      return [];
    }
    return allEmissionActivity.filter(
      (emission) => emission.activityType === EmissionVariant.Baseline
    );
  }, [allEmissionActivity]);

  const ongoingEmissions = useMemo(() => {
    if (!allEmissionActivity) {
      return [];
    }
    return allEmissionActivity.filter(
      (emission) => emission.activityType === EmissionVariant.OnGoing
    );
  }, [allEmissionActivity]);

  const minStartDate = useMemo(() => {
    if (!project || !project.projectInfo) {
      return "";
    }
    if (!ongoingEmissions || ongoingEmissions.length === 0) {
      return `${project.projectInfo.carbonRegYear}-${
        !project.projectInfo.carbonRegMonth
          ? "01"
          : project.projectInfo.carbonRegMonth < 10
          ? `0${project.projectInfo.carbonRegMonth}`
          : project.projectInfo.carbonRegMonth
      }-01`;
    }
    const lastEmission = ongoingEmissions.sort((a, b) => {
      const dateA = new Date(a.activityStartDate ?? "");
      const dateB = new Date(b.activityStartDate ?? "");
      return dateB.getTime() - dateA.getTime();
    })[0];

    const startDate = new Date(
      lastEmission.activityEndDate ?? lastEmission.activityStartDate ?? ""
    );
    startDate.setDate(startDate.getDate() + 1); // Add one day
    return startDate.toISOString().split("T")[0];
  }, [ongoingEmissions, project]);

  useEffect(() => {
    if (baselineEmissions.length === 0) {
      return;
    }
    setEmissionInstance({
      activityStartDate: minStartDate,
    });
  }, [baselineEmissions, minStartDate]);

  async function CreateEmission() {
    setLoading(true);
    if (!projectId || !emissionInstance) {
      setLoading(false);
      return;
    }

    if (!emissionInstance.activityType) {
      alert("Please select an activity variant");
      setLoading(false);
      return;
    }

    if (
      emissionInstance.activityType === EmissionVariant.OnGoing &&
      (!emissionInstance.activityStartDate || !emissionInstance.activityEndDate)
    ) {
      alert("Please select an activity start date and end date");
      setLoading(false);
      return;
    }

    const response = await api.postEmissionActivityInstance(
      +projectId,
      emissionInstance
    );
    if (response instanceof FetchError) {
      alert(response);
      setShowEmissionModal(false);
      setLoading(false);
      return;
    }
    mutate(response.emissionActivity);
    setEmissionInstance({});
    setShowEmissionModal(false);
    setLoading(false);
  }

  function CreateEmissionModal() {
    return (
      <Modal
        open={showEmissionModal}
        setOpen={setShowEmissionModal}
        title="Create Emission Activity Instance"
        onPressAdd={CreateEmission}
        actionButtonName="Create"
        onPressCancel={() => {
          setShowEmissionModal(false);
          setEmissionInstance({});
        }}
      >
        <div className="flex flex-1 flex-col w-full">
          <SelectInput
            label="Activity Variant"
            value={emissionInstance?.activityType ?? ""}
            onChange={(e) => {
              setEmissionInstance({
                ...emissionInstance,
                activityType: e.target.value as EmissionVariant,
              });
            }}
          >
            <option value={""}></option>
            {baselineEmissions.length === 0 ? (
              <option value={EmissionVariant.Baseline}>
                {EmissionVariant.Baseline}
              </option>
            ) : (
              <option value={EmissionVariant.OnGoing}>
                {EmissionVariant.OnGoing}
              </option>
            )}
          </SelectInput>
          {emissionInstance?.activityType === EmissionVariant.OnGoing ? (
            <>
              <TextInput
                label="Activity Start Date"
                type="date"
                onChange={(e) =>
                  setEmissionInstance({
                    ...emissionInstance,
                    activityStartDate: e.target.value,
                  })
                }
              />
              <TextInput
                label="Activity End Date"
                type="date"
                onChange={(e) =>
                  setEmissionInstance({
                    ...emissionInstance,
                    activityEndDate: e.target.value,
                  })
                }
              />
              <p className="text-sm text-yellow-300 font-mono italic">
                Activity End Date must be after Activity Start Date
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    );
  }
  return (
    <div className="flex flex-col px-2">
      <OverlayLoading open={loading} />
      {CreateEmissionModal()}
      <header className="flex flex-row justify-between items-center">
        <h1 className="text-lg font-semibold text-shark-100">
          Emission Activity
        </h1>
        <div className="flex flex-row space-x-2">
          <Button onClick={() => setShowEmissionModal(true)}>
            Request Emission Activity
          </Button>
        </div>
      </header>
      <main className="flex flex-col flex-1 px-8 h-full overflow-y-hidden">
        <div className="flex flex-col mt-4 w-full">
          {loadingEmissionActivityInfo ? (
            <Loading />
          ) : (
            <div className="flex flex-col flex-1 w-full">
              <h1 className="text-base font-semibold text-shark-200">
                Baseline Emission
              </h1>
              <>
                {baselineEmissions.length > 0 ? (
                  <div className="mx-auto mt-4 px-4 grid max-w-2xl auto-rows-fr grid-cols-1 gap-4 lg:gap-8 lg:mx-0 lg:max-w-none sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6">
                    {baselineEmissions.map((emission, i) => (
                      <article
                        className="relative isolate flex flex-col justify-end overflow-hidden rounded-xl bg-shark-800 border border-shark-700 px-8 py-8 shadow-lg hover:bg-shark-400/40 hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11"
                        key={i}
                      >
                        <div className="flex flex-col items-start gap-y-2">
                          <p className="text-sm text-shark-200">
                            {
                              Month[
                                (project?.projectInfo?.carbonRegMonth ?? 0) - 1
                              ]
                            }{" "}
                            {project?.projectInfo?.carbonRegYear}
                          </p>
                          <p className="text-3xl pt-6 text-shark-100 font-semibold leading-6">
                            {emission.activityType}
                          </p>

                          <Badge
                            variant={
                              emission.activityStatus ===
                              EmissionActivityStatus.InProgress
                                ? "warning"
                                : "success"
                            }
                          >
                            {emission.activityStatus}
                          </Badge>
                          <Link
                            to={`/projects/${projectId}/emission-activity/${emission.id}`}
                          >
                            <Button>View</Button>
                          </Link>
                        </div>
                      </article>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col flex-1 w-full text-center p-12">
                    <EmptyPlaceholder>
                      No Baseline Emission Activity. Request a Baseline Emission
                      Activity to get started.
                    </EmptyPlaceholder>
                  </div>
                )}
              </>

              <Divider />
              <h1 className="text-base font-semibold text-shark-200">
                Ongoing Emission
              </h1>
              <>
                {ongoingEmissions.length > 0 ? (
                  <div className="mx-auto mt-4 px-4 grid max-w-2xl auto-rows-fr grid-cols-1 gap-4 lg:gap-8 lg:mx-0 lg:max-w-none sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6">
                    {ongoingEmissions.map((emission, i) => (
                      <article
                        className="relative isolate flex flex-col justify-end overflow-hidden rounded-xl bg-shark-800 border border-shark-700 px-8 py-8 shadow-lg hover:bg-shark-700 hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11"
                        key={i}
                      >
                        <div className="flex flex-col items-start gap-y-2">
                          <p className="text-sm text-apple-500">
                            {formatDate(emission.activityStartDate)}
                            <span className="text-shark-200"> - </span>
                            {formatDate(emission.activityEndDate)}
                          </p>
                          <p className="text-3xl pt-6 text-shark-100 font-semibold leading-6">
                            {emission.activityType}
                          </p>

                          <Badge
                            variant={
                              emission.activityStatus ===
                              EmissionActivityStatus.InProgress
                                ? "warning"
                                : "success"
                            }
                          >
                            {emission.activityStatus}
                          </Badge>

                          <Link
                            to={`/projects/${projectId}/emission-activity/${emission.id}`}
                          >
                            <Button>View</Button>
                          </Link>
                        </div>
                      </article>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col flex-1 w-full text-center p-12">
                    <EmptyPlaceholder>
                      No Ongoing Emission Activity. Request an On-Going Emission
                      Activity to get started.
                    </EmptyPlaceholder>
                  </div>
                )}
              </>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
