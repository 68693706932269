import { PaperClipIcon } from "@heroicons/react/24/outline";
import Button from "../../components/button";
import DescriptionContainer from "../../components/description-container";
import DescriptionItem from "../../components/description-item";
import { Attachment, EntityPerson } from "../../types";
import { config } from "../../config";

interface SoleTraderDetailsViewProps {
  data?: EntityPerson;
  attachments?: Attachment[];
}
export default function SoleTraderDetailsView({
  data,
  attachments,
}: SoleTraderDetailsViewProps) {
  return !data ? (
    <p>No Data</p>
  ) : (
    <div className="w-full p-4">
      <p className="text-base text-apple-400 font-semibold">
        Sole Trader Information
      </p>
      <div className="bg-shark-700 my-2 p-4 rounded-lg">
        <DescriptionContainer>
          <DescriptionItem title="Legal Name" variant="alternate">
            {data.legalName ?? ""}
          </DescriptionItem>
          <DescriptionItem title="Email">{data.email ?? ""}</DescriptionItem>
        </DescriptionContainer>
      </div>
      <p className="text-base text-apple-400 font-semibold">
        Attachments ({(attachments ?? []).length}){" "}
      </p>
      {!attachments || !attachments.length ? null : (
        <div className="flex flex-col">
          {attachments.map((attachment, i) => (
            <div className="bg-shark-700 my-2 p-4 rounded-lg" key={i}>
              <li className="flex items-center justify-between pl-4 pr-5 text-sm leading-6">
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon
                    className="h-5 w-5 flex-shrink-0 text-shark-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <span className="truncate font-medium">
                      {attachment.attachmentType}
                    </span>
                  </div>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a
                    target="_blank"
                    href={`${config.attachmentUrlPrefix ?? ""}${
                      attachment.attachmentUrl
                    }`}
                    rel="noreferrer"
                  >
                    <Button variant="outline">View</Button>
                  </a>
                </div>
              </li>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
