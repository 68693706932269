import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function WarningContainer({
  children,
}: {
  children: React.ReactNode | string;
}) {
  return (
    <div className="mb-4 flex flex-row w-full justify-center items-center bg-yellow-800/30 ring-yellow-400/20 rounded-md whitespace-wrap text-center p-2 ring-1 ring-inset lg:p-2 ">
      <div className=" flex items-center justify-center rounded-xl  sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationTriangleIcon
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <p className=" text-yellow-400 text-xs lg:text-sm font-medium pl-2">
        {children}
      </p>
    </div>
  );
}
