interface Props {
  children: React.ReactNode;
  variant?: "grid" | "list";
}

export default function DescriptionContainer({ children, variant }: Props) {
  return variant === "grid" ? (
    <div className="mt-2">
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 ">{children}</dl>
    </div>
  ) : (
    <div className="">
      <div className=" border-t border-shark-600 ">
        <dl className="divide-y divide-shark-600">{children}</dl>
      </div>
    </div>
  );
}
